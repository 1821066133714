import {Component} from '@angular/core';

import {AbstractSidebarComponent} from '../abstract-sidebar/abstract-sidebar.component';

@Component({
  selector: 'app-sidebar-admin',
  templateUrl: './sidebar-admin.component.html',
  styleUrls: ['./sidebar-admin.component.scss']
})
export class SidebarAdminComponent extends AbstractSidebarComponent {
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatusesService {
  private apiurl = '';

  constructor(private http: HttpClient, private router: Router) {
    this.apiurl = environment.api;
  }

  getStatusesForSelect() {
    const url = this.apiurl + 'statuses/select';
    return this.http.get(url);
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ToastsService} from '../../../../services/toasts.service';
import {CredentialsService} from '../../../../services/credentails/credentials.service';
import {
  ClientAdditionalParametersService
} from "../../../../services/client-additional-parameters/client-additional-parameters.service";

@Component({
  selector: 'app-client-additional-parameters',
  templateUrl: './client-additional-parameters.component.html',
  styleUrls: ['./client-additional-parameters.component.scss']
})

export class ClientAdditionalParametersComponent implements OnInit {
  @Input() clientId;
  id = null;
  parameters: [];

  constructor(
    private clientAdditionalParametersService: ClientAdditionalParametersService,
    private toastsService: ToastsService,
    private route: Router
  ) {
  }

  ngOnInit() {
    this.getParameters();
  }

  getParameters() {
    this.clientAdditionalParametersService.listByClientId(this.clientId).subscribe(
      (response: []) => {
        this.parameters = response;
      }
    );
  }

  open(id = null ) {
    this.route.navigateByUrl('/client/' + this.clientId + '/parameter/' + id);
  }
}

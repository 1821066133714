import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ToastsService} from '../../../services/toasts.service';
import {Subject} from 'rxjs';
import {DataTableDirective} from 'angular-datatables';
import {UserModel} from "../../../models/user.model";
import {ClientModel} from '../../../models/client.model';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  public clientModel: ClientModel = new ClientModel();
  @ViewChild(DataTableDirective, {static: true}) dtElement: DataTableDirective;
  public dtInit = false;
  clients: any = [];
  apiurl = '';
  dtTranslation = null;
  deletedClients = '';
  selectedList = 'all';

  criteria = {
    client_alias_or_emag_name: null,
    deleted: null,
    last_month: 0,
    shipment_count_volume: null,
    last_mont_shipment_count_by_volume: null,
    volume_growing: null,
    missing_price_list: null,
  };
  constructor(private http: HttpClient, private toastsService: ToastsService) {
    this.apiurl = environment.api;
    this.dtTranslation = toastsService.dtTranslation;
  }

  ngOnInit() {
    this.showClients();
  }


  showClients() {
    const that = this;
    if (!this.dtInit) {
      setTimeout(
          () => {
            this.dtTrigger.next();
          }, 100
      );
    } else {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtInit = false;
        setTimeout(
            () => {
              this.dtTrigger.next();
            }, 100
        );
      });
    }

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      processing: true,
      language: this.dtTranslation,
      ajax: (dataTablesParameters: any, callback) => {
        that.http
            .post(
                this.apiurl + 'app/v1/client/list',
              {...dataTablesParameters,
                criteria: this.criteria,
              }
            ).subscribe((resp: any) => {
          that.clients = resp.data;
          this.dtInit = true;

          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      },
      columns: [
        {data: 'id', name: 'c.id'},
        {data: 'name', name: 'c.name'},
        {data: 'country', name: 'c.country'},
        {data: 'city', name: 'c.city'},
        {data: 'post_code', name: 'c.post_code'},
        {data: 'street', name: 'c.street'},
        {data: 'address', name: 'c.address'},
        {data: 'phone_number_prefix', name: 'c.phone_number_prefix'},
        {data: 'phone_number', name: 'c.phone_number'},
        {data: 'email_verified_at', name: 'u.email_verified_at'},
      ]
    };
  }

  runFilters() {
    switch (this.selectedList) {
      case 'all':
        this.criteria.deleted = 0;
        this.criteria.last_month = 0;
        break;
      case 'deleted':
        this.criteria.deleted = 1;
        this.criteria.last_month = 0;
        break;
      case 'last_month':
        this.criteria.last_month = 1;
        this.criteria.deleted = 0;
        break;
      case 'volume_growing':
        this.criteria.deleted = 0;
        this.criteria.last_month = 0;
        this.criteria.volume_growing = true;
        break;
      case 'volume_shrinking':
        this.criteria.deleted = 0;
        this.criteria.last_month = 0;
        this.criteria.volume_growing = false;
        break;
      case 'missing_price_list':
        this.criteria.deleted = 0;
        this.criteria.last_month = 0;
        this.criteria.missing_price_list = true;
      default:
        this.criteria.deleted = 0;
        break;
    }

    const table: DataTables.Api = $('#client-list').DataTable();
    table.search('').draw();
    table.page(0);

    this.showClients();
  }

  refreshFilters() {
    this.criteria.deleted = 0;
    this.criteria.client_alias_or_emag_name = null;
    this.selectedList = 'all';
    this.runFilters();
  }
}

<div class="uk-grid">
    <div class="uk-width-1-1 navbar">
        <p class="nav-text">
            {{'invoices' | translate}}
            <button class="uk-button uk-button-primary padding-horizontal-small button-opt" (click)="showTriggerInvoicingModal()" [attr.uk-tooltip]="'Rozpocznij fakturowanie'"><span uk-icon="bolt"></span></button>
          <a class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right"
             [attr.uk-tooltip]="'Resetuj filtry'"
             (click)="refreshFilters()"
          >
            <span uk-icon="refresh"></span>
          </a>
        </p>
    </div>


  <div class="uk-width-1-1 inner-shadow">

    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
            <div class="mobile-overflow">
                <div class="uk-grid uk-margin-bottom">
                    <div class="uk-width-1-4@m">
                      <label>Wybierz status</label>
                        <select required class="uk-input" [(ngModel)]="criteria.status">
                          <option value="pending">Oczekuje</option>
                          <option value="waiting_for_report">Oczekiwanie na raport</option>
                          <option value="report_validated">Raport zweryfikowany</option>
                          <option value="report_validation_error">Błąd walidacji raportu</option>
                          <option value="issued">Wystawiona</option>
                          <option value="missing_client_id">Brakujaca konfiguracja w fakturowni</option>
                          <option value="send">Wysłana</option>
                        </select>
                    </div>
                  <div class="uk-width-1-4@m">
                    <label>&nbsp;</label>
                    <button class="uk-button uk-button-primary uk-width-1-1" (click)="runFilters()">{{ 'filter' | translate }}</button>
                  </div>
                </div>
                <table class="uk-table uk-width-1-1 uk-table-hover uk-table-divider" style="width: 100% !important;" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="invoice-list">
                    <thead>
                    <tr>
                        <th>{{'Klient' | translate}}</th>
                        <th>{{'Status' | translate}}</th>
                        <th>{{'Data wystawienia' | translate}}</th>
                        <th>{{'Okres rozliczeniowy' | translate}}</th>
                        <th>{{'Kwota brutto'}}</th>
                        <th>{{'Numer faktury' | translate}}</th>
                    </tr>
                    </thead>
                    <tbody *ngIf="invoices?.length > 0">
                    <tr *ngFor="let invoice of invoices" [routerLink]=" '/invoices/' + invoice.id ">
                        <td>{{ invoice.client_name }}</td>
                        <td>{{ 'invoice_status_' + invoice.status | translate }}</td>
                        <td>{{invoice.issue_date ?? '-'}}</td>
                        <td>{{invoice.period_from}} - {{invoice.period_to}}</td>
                        <td>{{invoice.total_anmount ?? '-'}}</td>
                        <td>{{invoice.invoice_number ?? '-'}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>

    </div>

</div>

<div id="triggerInvoicing" class="triggerInvoicing" uk-modal>
  <div class="uk-modal-dialog">
    <button class="uk-modal-close-default" type="button" uk-close></button>

    <div class="uk-modal-header">
      <h2 class="uk-modal-title">{{'Rozpocznij fakturowanie' | translate}}</h2>
    </div>

    <div class=" uk-modal-body">
      <div class="uk-grid">
        <div class="uk-width-1-3">
          <label title="Data ta jest punktem odniesienia, faktury zostaną wygenerowane dla poprzedzających okresów rozliczeniowych">Data fakturowania</label>
          <input type="date" [(ngModel)]="triggerInvoicingForm.trigger_date" class="uk-input">
        </div>

        <div class="uk-width-1-3">
        <button class="uk-button uk-button-primary uk-width-1-1 uk-margin-top" (click)="triggerInvoicing()">Generuj</button>
        </div>
      </div>
    </div>
  </div>
</div>

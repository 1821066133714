<div class="uk-grid">
  <div class="uk-width-1-1 navbar">
    <p class="nav-text">
      {{'problems' | translate}}
      <a class="uk-button uk-button-primary padding-horizontal-small button-opt" *ngIf="userModel?.role == 0" [attr.uk-tooltip]="'Zarządzaj rodzajami nieprawidłowości'" (click)="goToRules()"><span uk-icon="cog"></span></a>
      <a class="uk-button uk-button-primary padding-horizontal-small button-opt" [class.uk-margin-remove-right]="userModel?.role==0" [attr.uk-tooltip]="'Ustaw jako w trakcie'" (click)="setInProgress()"><span uk-icon="clock"></span></a>
      <a class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right" [attr.uk-tooltip]="'Ustaw jako rozwiązane'" (click)="setSolved()"><span uk-icon="bolt"></span></a>
      <a class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right"  *ngIf="userModel?.role < 2" (click)="selectAll()" [attr.uk-tooltip]="lastChecked ? 'Odznacz wszystkie' : 'Zaznacz wszystkie'"><span uk-icon="check"></span></a>
      <a class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right"  style="background-color: var(--warning-re) !important;" *ngIf="userModel?.role < 2 && showActionButton=='dpd_parcel_not_created'"  [attr.uk-tooltip]="'Wyślij ponownie'" (click)="resend()"><span uk-icon="cloud-upload"></span></a>

    </p>
  </div>

  <div class="uk-width-1-1 inner-shadow">

    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
      <div class="mobile-overflow">

        <div class="uk-grid uk-margin-bottom">

          <div class="uk-width-1-6@m">
            <label class="uk-text-center">Status</label>
            <select class="uk-select" [(ngModel)]="filters.status">
              <option value="all">Wszystkie</option>
              <option value="reported">Zgłoszone</option>
              <option value="noticed">Zauważone</option>
              <option value="in_progress">W trakcie</option>
              <option value="solved">Rozwiązane</option>
            </select>
          </div>

          <div class="uk-width-1-6@m">
            <label>{{'from' | translate}}</label>
            <input type="date" name="search-from" [(ngModel)]="filters.from" class="uk-input" (change)="showProblems()">
          </div>
          <div class="uk-width-1-6@m">
            <label>{{'to' | translate}}</label>
            <input type="date" name="search-to" [(ngModel)]="filters.to" class="uk-input" (change)="showProblems()">
          </div>
          <div class="uk-width-1-3@m">
            <label>{{'problem_rule' | translate}}</label>
            <select name="search-status-2" [(ngModel)]="filters.rule" class="uk-select" (change)="showProblems(); filterRuleChange();">
              <option value="0">{{"not_selected" | translate}}</option>
              <option value="{{rule.id}}" *ngFor="let rule of rules">{{rule.name}}</option>
            </select>
          </div>
          <div class="uk-width-1-6@m">
            <div class="uk-grid">
              <div class="uk-width-2-3@m">
                <button class="uk-button uk-button-primary padding-horizontal-small" (click)="clearFilters()" [attr.uk-tooltip]="'Wyczyść filtry'" style="margin-top: 23px;"><span uk-icon="refresh"></span></button>
              </div>
            </div>
          </div>
        </div>
        <table class="uk-table uk-table-hover uk-table-divider" style="width:100%" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="table">
          <thead>
          <tr>
            <th>{{'internal_number' | translate}}</th>
            <th>{{'problem_rule_code' | translate}}</th>
            <th>{{'problem_rule' | translate}}</th>
            <th>{{'status' | translate}}</th>
            <th>{{'detected_at' | translate}}</th>
            <th>{{'solved_at' | translate}}</th>
            <th></th>
          </tr>
          </thead>
          <tfoot  id="footer">
          <tr>
            <th><input type="text" name="search-internal_number" class="uk-input"></th>
            <th><input type="text" name="search-code" class="uk-input"></th>
            <th><input type="text" name="search-rule" class="uk-input"></th>
            <th><input type="text" name="search-status" class="uk-input"></th>
            <th><input type="text" name="search-detected_at" class="uk-input"></th>
            <th><input type="text" name="search-solved_at" class="uk-input"></th>
            <th></th>
          </tr>
          </tfoot>
          <tbody *ngIf="problems?.length > 0">
          <tr *ngFor="let problem of problems" (click)="goTo( '/problem/' + problem.id )">
            <td><kbd>{{ problem.internal_number }}</kbd></td>
            <td>{{ problem.code }}</td>
            <td>{{ problem.rule_name }}</td>
            <td>{{ problem.status| translate }}</td>
            <td><kbd>{{ problem.created_at }}</kbd></td>
            <td><kbd>{{ problem.solved_at  }}</kbd></td>
            <td *ngIf="userModel.role < 3" (mouseup)="setGoToLocker(true)">
              <input *ngIf="userModel.role < 3" class="uk-checkbox" type="checkbox" [(ngModel)]="problem.selected">
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>


import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ToastsService} from '../../../services/toasts.service';
import {environment} from '../../../../environments/environment';
import {ExternalInboundService} from "../../../services/external_inbound/external-inbound.service";

@Component({
  selector: 'app-scan-external-inbound',
  templateUrl: './scan-external-inbound.component.html',
  styleUrls: ['./scan-external-inbound.component.scss']
})
export class ScanExternalInboundComponent implements OnInit, OnDestroy {
  @ViewChild('barcodeInput') barcodeInput: ElementRef;
  internalNumber = null;

  apiurl = null;
  id = null;
  statuses = [];
  error = false;

  idClient = null;
  client = null;

  scanningLocked = false;

  public lastChars = '';
  public lastKeyCode = null;
  public clearLastCharsInterval = null;
  statusTypes = [];
  barcode = null;

  constructor(
      private toastsService: ToastsService,
      private externalInboundService: ExternalInboundService,
  ) {
    this.apiurl = environment.api;
  }

  ngOnInit() {
    this.keyReading();
    this.clearLastChars();
  }

  runScan() {
    this.externalInboundService.scan({barcode: this.barcode}).subscribe(
      (response: any) => {
        if (201 === response.status){
          this.toastsService.showBigSuccessMessage();
        }
        else {
          this.toastsService.showMessage("Przesyłka już istnieje w systemie", 's', 15000);
        }
      },
    );
  }

  ngOnDestroy() {
    document.removeEventListener('keyup', this.listenerAction, false);
  }

  keyReading() {
    document.addEventListener('keyup', this.listenerAction, false);
  }


  eanChecker(tempChars) {
    console.log(tempChars.length);
    if (tempChars.length === 8) {
      this.toastsService.showMessage('Zeskanowano nieprawidłowy kod - wykryto EAN8', 'w', 5000);
      return false;
    }
    if (tempChars.length > 28) {
      this.toastsService.showMessage('Zbyt szybko skanujesz przesyłki', 'w', 5000);
      return false;
    }

    return true;
  }

  listenerAction = (e) => {
    this.clearLastChars();
    if ((e.ctrlKey || e.metaKey)) {
      return;
    }

    this.lastKeyCode = e.keyCode;

    if ((e.keyCode >= 48 && e.keyCode <= 57 && this.lastKeyCode !== 16)
      || e.keyCode === 189 || (e.keyCode > 40 && e.keyCode < 91)) {
      if (e.keyCode === 189) {
        this.lastChars += '_';
      } else {
        this.lastChars += String.fromCharCode(e.keyCode) + '';
      }
    }

    this.lastKeyCode = e.keyCode;
  }

  checkNumbers() {
    if (this.lastChars.length === 0 || !this.eanChecker(this.lastChars)){
      return;
    }

    this.barcode = this.lastChars;
    this.runScan();
  }

  clearLastChars() {
    if (this.clearLastCharsInterval != null) {
      clearInterval(this.clearLastCharsInterval);
      this.clearLastCharsInterval = null;
    }
    this.clearLastCharsInterval = setInterval(
      () => {
        this.checkNumbers();
        this.lastChars = '';
      }, 400
    );
  }
}


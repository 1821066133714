<div class="uk-grid">
    <div class="uk-width-1-1 navbar">
      <p class="nav-text" *ngIf="id === null">
        Utwórz nowy status
      </p>
      <p class="nav-text" *ngIf="id !== null">
          {{'status' | translate}}: {{status.name}}
          <button class="uk-button uk-button-danger padding-horizontal-small button-opt" (click)="onStatus()" *ngIf="status.id_courier == '1'" [attr.uk-tooltip]="'Usuń status'"><span uk-icon="trash"></span></button>
      </p>
    </div>
    <div class="uk-width-1-1 inner-shadow">
      <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
              <div class="mobile-overflow">
                  <form #statusForm="ngForm" (ngSubmit)="save(statusForm)">
                      <input type="hidden" name="id" #id="ngModel" [(ngModel)]="status.id">
                      <div class="uk-grid">
                          <div class=" uk-width-1-2@m" >
                            <label>{{'name' | translate}}</label>
                            <input class="uk-input" type="text" [ngModel]="status.name" name="name" #name="ngModel" ngModel required>
                          </div>
                          <div class=" uk-width-1-2@m">
                              <label>{{'displayed_name' | translate}}</label>
                              <input class="uk-input" type="text" name="displayed_name" #displayed_name="ngModel" [(ngModel)]="status.displayed_name" required>
                          </div>
                          <div class=" uk-width-1-2@m">
                            <label>{{'displayed_name_ro' | translate}}</label>
                            <input class="uk-input" type="text" name="displayed_name_ro" #displayed_name_ro="ngModel" [(ngModel)]="status.displayed_name_ro" ngModel required>
                          </div>
                          <div class=" uk-width-1-2@m">
                              <label>{{'internal_status' | translate}}</label>
                              <select class="uk-select" name="internal" #internal="ngModel" [(ngModel)]="status.internal" required>
                                  <option value="0">{{'no' | translate}}</option>
                                  <option value="1">{{'yes' | translate}}</option>
                              </select>
                          </div>
                      </div>

                      <div class="uk-margin">
                          <button class="uk-button uk-button-primary" [disabled]="statusForm.form.invalid">{{'save' | translate}}</button>
                      </div>
                  </form>
              </div>
          </div>
    </div>
</div>


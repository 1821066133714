import { Component, OnInit } from '@angular/core';
import {ToastsService} from "../../../services/toasts.service";
import {ClientsService} from "../../../services/clients/clients.service";

@Component({
  selector: 'check-permissions-modal',
  templateUrl: './check-permissions-modal.component.html',
  styleUrls: ['./check-permissions-modal.component.scss']
})
export class CheckPermissionsModalComponent implements OnInit {
  model = {
    courier_class: 'DPDRomania',
    username: null,
    password: null
  }

  result=null;

  constructor(public toastService: ToastsService,
              public clientService: ClientsService) { }

  ngOnInit(): void {
  }

  check(){
  }

  ngOnDestroy() {
    this.result=null;
    this.model={
      courier_class: 'DPDRomania',
      username: null,
      password: null
    }
  }


}

<div>
  <form #clientForm="ngForm" (ngSubmit)="onEditClient()">
    <p class="uk-heading-divider">{{'client_data' | translate}}</p>
    <div class="uk-grid">
      <div class=" uk-width-1-3">
        <label class="required">{{'email' | translate}}</label>
        <input class="uk-input" type="text" name="email" [(ngModel)]="client.email" required email>
      </div>
      <div class=" uk-width-1-3">
        <label class="required">{{'nip' | translate}}</label>
        <input class="uk-input" type="text" name="nip" [(ngModel)]="client.nip" required>
      </div>
      <div class=" uk-width-1-3">
        <label class="required">{{'name' | translate}}</label>
        <input class="uk-input" type="text" name="name" [(ngModel)]="client.name" required>
      </div>
      <div class=" uk-width-1-3">
        <label>{{'contact_name' | translate}}</label>
        <input class="uk-input" type="text" name="contact_name" [(ngModel)]="client.contact_name" >
      </div>

      <div class=" uk-width-1-3">
        <label class="required">{{'street' | translate}}</label>
        <input class="uk-input" type="text" name="street" [(ngModel)]="client.street" required>
      </div>
      <div class=" uk-width-1-3">
        <label class="required">{{'city' | translate}}</label>
        <input class="uk-input" type="text" name="city" [(ngModel)]="client.city" required>
      </div>
      <div class=" uk-width-1-3">
        <label class="required">{{'post_code' | translate}}</label>
        <input class="uk-input" type="text" name="post_code" [(ngModel)]="client.post_code" required>
      </div>
      <div class=" uk-width-1-3">
        <label>{{'address_additional_info' | translate}}</label>
        <input class="uk-input" type="text" name="address"  [(ngModel)]="client.address">
      </div>
      <div class=" uk-width-1-3">
        <label>{{'bank_account_number' | translate}}</label>
        <input class="uk-input" type="text" name="bank_account_number" [(ngModel)]="client.bank_account_number">
      </div>
      <div class=" uk-width-2-5">
        <label>{{'phone_number_prefix' | translate}}</label>
        <input class="uk-input" type="text" pattern="[0]{2}+[0-9]{0,2}" name="phone_number_prefix" [class.uk-text-danger]="phone_number_prefix.invalid" #phone_number_prefix="ngModel" [(ngModel)]="client.phone_number_prefix">
      </div>
      <div class=" uk-width-3-5">
        <label>{{'phone_number' | translate}}</label>
        <input class="uk-input" type="text" pattern="[0-9]{9,10}" name="phone_number" [class.uk-text-danger]="phone_number.invalid" #phone_number="ngModel" [(ngModel)]="client.phone_number" required>
      </div>
    </div>
    <p class="uk-heading-divider">Ustawienia nadawania przesyłek</p>
    <div class="uk-grid uk-margin-remove-top">

      <div class="uk-width-1-2@m">
        <label class="required">{{'cod_enabled' | translate}}</label>
        <select class="uk-select" name="cod_enabled" [(ngModel)]="client.cod_enabled">
          <option value="0">Nie</option>
          <option value="1">Tak</option>
        </select>
      </div>

      <div class="uk-width-1-2@m">
        <label class="required">{{'show_price_parcel' | translate}}</label>
        <select class="uk-select" name="show_price_parcel" [(ngModel)]="client.show_price_parcel">
          <option value="0">Nie</option>
          <option value="1">Tak</option>
        </select>
      </div>
      <div class="uk-width-1-2@m">
        <label class="required">{{'ext_download' | translate}}</label>
        <select class="uk-input" name="download_ext" [(ngModel)]="client.download_ext">
          <option value="0">{{'no' | translate}}</option>
          <option value="1">{{'yes' | translate}}</option>
        </select>
      </div>
      <div class="uk-width-1-2@m">
        <label class="required">{{'re_client_api_enabled' | translate}}</label>
        <select class="uk-input" name="api_enabled" [(ngModel)]="client.api_enabled">
          <option value="0">{{'no' | translate}}</option>
          <option value="1">{{'yes' | translate}}</option>
        </select>
      </div>
      <div class="uk-width-1-1@m">
        <label class="required">{{'use_ext_option' | translate}}</label>
        <select class="uk-input" name="use_ext_option" [(ngModel)]="client.ext_number">
          <option value="0">{{'no' | translate}}</option>
          <option value="1">{{'yes' | translate}}</option>
        </select>
      </div>
      <div class="uk-width-1-1@m">
        <label>{{'sender_signature' | translate}}</label>
        <textarea class="uk-textarea" name="sender_signature" [(ngModel)]="client.sender_signature"></textarea>
      </div>
      <div class=" uk-width-1-1@m">
        <label class="required">{{'re_returning_address' | translate}}</label>
        <textarea class="uk-input" type="text" name="re_returning_address" [(ngModel)]="client.re_returning_address" required cols="3" style="min-height: 5em"></textarea>
      </div>
      <div class=" uk-width-1-1@m">
        <label class="required">{{'re_broadcasting_facility_address' | translate}}</label>
        <textarea class="uk-input" type="text" [(ngModel)]="client.re_broadcasting_facility_address" name="re_broadcasting_facility_address" required style="min-height: 5em"></textarea>
      </div>
      <div class="uk-width-1-1@m mt-10">
        <p class="uk-heading-divider">Kraje do których klient ma możliwość nadawania przesyłek</p>
        <div class="uk-grid">
          <div class="uk-width-1-1" *ngFor="let country of countries; let i = index">
            <input type="checkbox" [(ngModel)]="countries[i].checked" name="countries[{{i}}].checked" (click)="changeAllowedCountry(i)">{{country.name}}
          </div>
        </div>
      </div>
    </div>
    <div class="uk-margin">
      <button class="uk-button uk-button-primary" [disabled]="clientForm.form.invalid">{{'save' | translate}}</button>
    </div>
  </form>
</div>


<div class="sidebar computer-only">
    <div>
        <div class="mb-1">
            <div>
                <img src="/assets/img/re-logo.png" class="uk-align-center uk-padding-top logo sidebar-logo-size">
            </div>
        </div>
        <div class="uk-width-1-1 uk-m mb-1">
            <div>
                <h4 class="uk-text uk-text-muted uk-text-center">{{userModel.email}}</h4>
            </div>
        </div>
      <!--
        <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/dashboard">
          {{'parcels' | translate}}
        </a> -->
        <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/parcel-list">
          {{'parcels' | translate}}
        </a>
        <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/users">
          {{'manage_users' | translate}}
        </a>
        <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/clients">
          {{'manage_clients' | translate}}
        </a>
        <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/operational-price-list/income/list">
          {{'manage_prices' | translate}}
        </a>
        <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/statuses">
          {{'manage_statuses' | translate}}
        </a>
        <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/tracking-manager">
          {{'manage_parcel_statuses' | translate}}
        </a>
        <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/parcel-from-number">
          {{'create_parcel_from_number' | translate}}
        </a>
        <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/parcel-classification">
          {{'parcel_classification' | translate}}
        </a>
        <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/external_inbound/scan">
          Przyjmij od kurierów
        </a>
        <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/external_inbound/">
          Przyjęte od kurierów
        </a>
        <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/tags">
          Tagi
        </a>
        <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/problems">
          {{'problems' | translate}}
          <span uk-icon="warning"
                *ngIf="newProblemsCount > 0"
                [attr.uk-tooltip]="'Wykryto nowe nieprawidłowości!'">
          </span>
        </a>
        <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/calendar">
          {{'calendar' | translate}}
        </a>
        <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/stats">
          {{'stats' | translate}}
        </a>
        <!--
          <a class="uk-button uk-button-primary uk-width-1-1 mb-1" (click)="showCheckPermissionsModal()">
            {{'check_permissions' | translate}}
          </a>
        --->
        <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/parcel-search">
          {{'parcel_scan_search' | translate}}
        </a>
        <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/parcel-reported-resignations">
          {{'parcel_reported_resignations' | translate}}
        </a>
        <a *ngIf="exportsCount > 0" class="uk-button uk-button-primary uk-width-1-1 mb-1" (click)="openExportsModal()">
          Moje raporty
        </a>
        <a class="uk-button uk-button-primary uk-width-1-1 mb-1" (click)="openExportsModal()">
          Moje raporty
        </a>
        <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/invoices">
          Faktury
        </a>
        <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/unknown-client-aliases">
          Nierozpoznane aliasy klientów
        </a>
        <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/profile">
          {{'change_password' | translate}}
        </a>
        <a class="uk-button uk-button-danger uk-width-1-1" (click)="onLogout()">
          {{'logout' | translate}}
        </a>
    </div>
    <check-permissions-modal></check-permissions-modal>
    <export-list [type]="'list'" #exportList></export-list>
</div>


<div class="sidebar mobile-only">
  <div>
    <div class="mb-1 mt-10">
      <div>
        <img src="/assets/img/re-logo.png" class="uk-align-center uk-padding-top logo pt-10">
      </div>
    </div>
    <div class="uk-width-1-1 mb-1">
      <div>
        <h4 class="uk-text uk-text-muted uk-text-center">{{userModel.email}}</h4>
      </div>
    </div>
    <a class="uk-button uk-button-primary uk-width-1-1 mb-1" (click)="mobileMenuIsActive = !mobileMenuIsActive">
      {{'Menu' | translate}}
    </a>
    <div class="uk-width-1-1" [class.uk-hidden]="!mobileMenuIsActive">
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/dashboard">
        {{'parcels' | translate}}
        </a>
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/users">
        {{'manage_users' | translate}}
      </a>
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/clients">
        {{'manage_clients' | translate}}
      </a>
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/operational-price-list/income/list">
        {{'manage_prices' | translate}}
      </a>
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/statuses">
        {{'manage_statuses' | translate}}
      </a>
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/tracking-manager">
        {{'manage_parcel_statuses' | translate}}
      </a>
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/problems">
        {{'problems' | translate}}
        <span uk-icon="warning" [attr.uk-tooltip]="'Wykryto nowe nieprawidłowości!'">
        </span>
      </a>
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/calendar">
        {{'calendar' | translate}}
      </a>
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/stats">
        {{'stats' | translate}}
      </a>
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/profile">
        {{'change_password' | translate}}
      </a>
      <!--
        <a class="uk-button uk-button-primary uk-width-1-1 mb-1" (click)="showCheckPermissionsModal()">
          {{'check_permissions' | translate}}
        </a>
      -->
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/parcel-reported-resignations">
        {{'parcel_reported_resignations' | translate}}
      </a>
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/parcel-search">
        {{'parcel_scan_search' | translate}}
      </a>
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/unknown-client-aliases">
        Nierozpoznane aliasy klientów
      </a>
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" (click)="openExportsModal()">
        Moje raporty
      </a>
      <a class="uk-button uk-button-danger uk-width-1-1" (click)="onLogout()">{{'logout' | translate}}</a>
     </div>
  </div>
  <check-permissions-modal></check-permissions-modal>
  <export-list [type]="'list'" #exportList></export-list>
</div>

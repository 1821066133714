
<div class="uk-grid">
  <div class="uk-width-1-1 navbar">
    <p class="nav-text">
      {{'manage_api' | translate}}
   </p>
  </div>

  <div class="uk-width-1-1 inner-shadow">

    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
      <div class="uk-grid">

        <div class="uk-width-1-3@m">
          <form #apiIpForm="ngForm" (ngSubmit)="changeIP(apiIpForm)" >
            <div class="uk-grid">
              <div class=" uk-width-1-1">
                <label>{{'allowed_ip' | translate}}</label>
                <input class="uk-input" type="text" name="allowed_ip" #allowed_ip="ngModel" [ngModel]="client.allowed_ip" required>
              </div>
            </div>
            <div class="uk-margin">
              <button class="uk-button uk-button-primary">{{'save' | translate}}</button>
            </div>
          </form>
        </div>
        <div class="uk-width-1-3@m">
          <form #apiNewToken="ngForm" >
            <div class="uk-grid">
              <div class=" uk-width-1-1">
                <label>{{'token' | translate}}</label>
                <input class="uk-input" type="text" name="token" [ngModel]="client.token" disabled>
              </div>
            </div>
            <div class="uk-margin">
              <button class="uk-button uk-button-primary" (click)="generateNewToken()">{{'generate_token' | translate}}</button>
              <button class="uk-button uk-button-primary" style="margin-right: 0" (click)="copyToken()"><span uk-icon="copy"></span></button>


            </div>
          </form>
       </div>

      </div>
    </div>
  </div>
</div>

import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ParcelsService} from '../../../services/parcels/parcels.service';
import {ParcelModel} from '../../../models/parcel.model';
import {StatusModel} from '../../../models/status.model';
import {ToastsService} from '../../../services/toasts.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-tracking-manager',
  templateUrl: './tracking-manager.component.html',
  styleUrls: ['./tracking-manager.component.scss']
})
export class TrackingManagerComponent implements OnInit, OnDestroy {
  apiurl = null;
  id = null;
  statuses = [];
  error = false;
  public lastChars = '';
  public lastKeyCode = null;
  public clearLastCharsInterval = null;
  public keyboardListener = null;
  public eanCheckerTimeout = null;
  statusTypes = [
    {id: '', displayed_name: ''},
  ];
  statusModel = {
    parcelNumber: '',
    id_status_type: null,
    when: '',
    where: 'TYCHY',
  };

  constructor(private route: ActivatedRoute, private parcelsService: ParcelsService,
              private toastsService: ToastsService) {
    this.apiurl = environment.api;
  }

  ngOnInit() {
    this.keyReading();
    this.clearLastChars();
    this.getStatuses();
    this.setTime();
  }

  ngOnDestroy() {
    document.removeEventListener('keyup', this.listenerAction, false);
  }

  keyReading() {
    document.addEventListener('keyup', this.listenerAction, false);
  }

  eanChecker(tempChars) {
    if (tempChars.length === 8) {
      this.toastsService.showMessage('Zeskanowano nieprawidłowy kod - wykryto EAN8', 'w', 5000);
    }
    if (tempChars.length === 13) {
      this.toastsService.showMessage('Zeskanowano nieprawidłowy kod - wykryto EAN13', 'w', 5000);
    }
    if (tempChars.length === 14) {
      this.toastsService.showMessage('Zeskanowano nieprawidłowy kod - wykryto EAN14', 'w', 5000);
    }
    if (tempChars.length > 28) {
      this.toastsService.showMessage('Zbyt szybko skanujesz przesyłki', 'w', 5000);
    }
  }


  listenerAction = (e) => {
    this.clearLastChars();
    if ((e.keyCode >= 48 && e.keyCode <= 57 && this.lastKeyCode !== 16) || (e.keyCode>40 && e.keyCode<91)
      || e.keyCode === 82 || e.keyCode === 69 || e.keyCode === 189) {
      if (e.keyCode === 189) {
        this.lastChars += '_';
      } else {
        this.lastChars += String.fromCharCode(e.keyCode) + '';
      }
    }
    this.lastKeyCode = e.keyCode;
  }

  getParcelStatuses() {
    this.parcelsService.getParcelStatuses(this.statusModel.parcelNumber).subscribe(
      (response: any) => {
        response = response.sort((a, b) => {
          return (a.when < b.when) ? 1 : ((a.when > b.when) ? -1 : 0);
        });
        this.statuses = response;
      }
    );
  }

  setTime() {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());
    const hour = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    this.statusModel.when = `${year}-${month}-${day}T${hour}:${minutes}`;
  }

  updateStatus() {
    this.parcelsService.trackingManager(this.statusModel).subscribe(
      (response: any) => {
        if (response.original != null) {
          response = response.original;
        }
        response = response.sort((a, b) => {
          return (a.when < b.when) ? 1 : ((a.when > b.when) ? -1 : 0);
        });
        this.statuses = response;
        this.error = false;
        this.setTime();
        if (response.length === 0) {
          this.error = true;
        }
        this.toastsService.showMessage('Status zaaktualizowano pomyślnie!', 's', 500);
      }, e => {
        if (e.status == 420 || e.status == 419 || e.status == 422) {
          this.toastsService.showMessage(e.error[0], 'e', 5000);
        } else {
          this.error = true;
          this.toastsService.showMessage('Nie odnaleziono przesyłki o numerze ' + this.statusModel.parcelNumber + '!', 'e', 5000);
        }
      }
    );
  }

  clearLastChars() {
    if (this.clearLastCharsInterval != null) {
      clearInterval(this.clearLastCharsInterval);
      this.clearLastCharsInterval = null;
    }
    this.clearLastCharsInterval = setInterval(
      () => {
        this.checkNumbers();
        this.lastChars = '';
      }, 800
    );
  }

  checkNumbers() {
    const tempChars = JSON.parse(JSON.stringify(this.lastChars));

    if (this.lastChars.length === 28) {
      if (String(this.lastChars).slice(0, 4) === '1000' || String(this.lastChars).slice(0, 4) === '0000') {
        if (String(this.lastChars).slice(0, 4) === '0000') {
          this.toastsService.showMessage('Etykieta przesyłki o numerze ' + this.lastChars + ' wymaga przeklejenia!', 'w', 10000);
        }
        const newLastChars = this.lastChars.substr(4, 11);
        this.parcelsService.getPublicParcelOutNumber(newLastChars).subscribe(
          (response: any) => {
            if (response == null) {
              return;
            }
            if (String(tempChars).slice(0, 4) === '0000') {
              this.openLabelToPrint(response.internal_number);
            }
            this.statusModel.parcelNumber = response.internal_number;
            this.updateStatus();
          }
        );
      }
    } else if (this.lastChars.length === 9 || this.lastChars.length === 10 || (this.lastChars.toUpperCase()).includes('RE_')) {
      this.parcelsService.getPublicParcelOutNumber(tempChars.replace('RE_', '')).subscribe(
        (response: any) => {
          if (response == null) {
            return;
          }
          this.toastsService.showMessage('Etykieta przesyłki o numerze ' + (tempChars.replace('RE_', '')) + ' wymaga przeklejenia!', 'w', 10000);
          this.openLabelToPrint(response.internal_number);
          this.statusModel.parcelNumber = response.internal_number;
          this.updateStatus();
        });
    } else if (this.lastChars.match(/^[0-9][A-Z]{3,5}[0-9]{4,}/) !== null) {
      this.parcelsService.getPublicParcelOutNumber(this.lastChars).subscribe(
        (response: any) => {
          if (response == null) {
            return;
          }

          this.statusModel.parcelNumber = response.internal_number;
          this.updateStatus();
        }
      );
    }
    else {
      if (this.eanCheckerTimeout != null) {
        clearTimeout(this.eanCheckerTimeout);
      }
      this.eanCheckerTimeout = setTimeout(
        () => {
          this.eanChecker(tempChars);
        }, 150
      );
    }
  }

  async onStatus(idStatus) {
    const promised = await this.toastsService.showConfirmModal();
    if (!promised) {
      return;
    }
    this.parcelsService.deleteStatus(idStatus).subscribe(
      (s: StatusModel) => {
        this.toastsService.showMessage('status_removed', 's');
        this.getParcelStatuses();
      }
    );
  }

  getStatuses() {
    this.parcelsService.getStatusTypes(1).subscribe(
      (response: any) => {
        this.statusTypes = response;
        if (response.length > 0) {
          this.statusModel.id_status_type = response[0].id;
        }
      }
    );
  }

  openLabelToPrint(lastChars) {
    this.parcelsService.getPublicFileToken().subscribe(
      (response: any) => {
        window.open(this.apiurl + 'public/label/' + lastChars + '?_public_token=' + response.public_token);
      }
    );
  }
}

import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ToastsService} from '../../../services/toasts.service';
import {Subject} from 'rxjs';
import {DataTableDirective} from 'angular-datatables';
import {UserModel} from "../../../models/user.model";
import {ClientModel} from '../../../models/client.model';
import {ExternalInboundService} from "../../../services/external_inbound/external-inbound.service";

@Component({
  selector: 'app-external-inbound-list',
  templateUrl: './external-inbound-list.component.html',
  styleUrls: ['./external-inbound-list.component.scss']
})
export class ExternalInboundListComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  public clientModel: ClientModel = new ClientModel();
  @ViewChild(DataTableDirective, {static: true}) dtElement: DataTableDirective;
  public dtInit = false;
  couriers: [];
  externalInbounds: any = [];
  apiurl = '';
  dtTranslation = null;

  criteria = {
    inbound_courier_id: null,
    date: null
  };
  constructor(
    private http: HttpClient,
    private toastsService: ToastsService,
    private externalInboundService: ExternalInboundService
  ) {
    this.apiurl = environment.api;
    this.dtTranslation = toastsService.dtTranslation;
  }

  ngOnInit() {
    this.showExternalInbounds();
    this.externalInboundService.couriers().subscribe(
      (response: []) => {
        this.couriers = response;
      }
    );
  }


  showExternalInbounds() {
    const that = this;
    if (!this.dtInit) {
      setTimeout(
          () => {
            this.dtTrigger.next();
          }, 100
      );
    } else {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtInit = false;
        setTimeout(
            () => {
              this.dtTrigger.next();
            }, 100
        );
      });
    }

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      processing: true,
      language: this.dtTranslation,
      ajax: (dataTablesParameters: any, callback) => {
        that.http
            .post(
                this.apiurl + 'app/v1/external_inbound/list',
              {...dataTablesParameters,
                criteria: this.criteria,
              }
            ).subscribe((resp: any) => {
          that.externalInbounds = resp.data;
          this.dtInit = true;

          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      },
      columns: [
        {data: 'waybill', name: 'external_inbounds.waybill'},
        {data: 'scanned_at', name: 'external_inbounds.scanned_at'},
        {data: 'comment', name: 'external_inbounds.comment'},
      ]
    };
  }

  runFilters() {
    const table: DataTables.Api = $('#client-list').DataTable();
    table.search('').draw();
    table.page(0);

    this.showExternalInbounds();
  }

  refreshFilters() {
    this.criteria.date = null;
    this.criteria.inbound_courier_id = null;
    this.runFilters();
  }
}

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastsService} from "../../../../services/toasts.service";
import {StatusTypeService} from "../../../../services/status-type/status-type.service";
import {InvoicesService} from "../../../../services/invoices/invoices.service";
import {ClientsService} from "../../../../services/clients/clients.service";
import {ExportService} from "../../../../services/export/export.service";
import {UserRole} from "../../../../enums/user-role";
import {AuthService} from "../../../../services/auth/auth.service";
import {ParcelService} from "../../../../services/parcel/parcel.service";
import {ParcelsService} from "../../../../services/parcels/parcels.service";
import {environment} from "../../../../../environments/environment";

@Component({
    selector: 'app-invoice',
    templateUrl: './invoice.component.html',
    styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
    private apiurl = '';

    invoice = {
      external_id: null,
      status: null,
      id: null,
      total_amount: null,
      client_id: null,
      invoice_number: null,
      payment_to_date: null,
      issue_date: null,
      period_from: null,
      period_to: null,
      email_id: null,
    };
    client = {
      name: null,
    };
    exportedFile = {
      identifier: null,
    };
    email = {
      recipients: [],
    };
    validationErrors = [];
    id = null;

    constructor(
      private route: ActivatedRoute,
      private authService: AuthService,
      private toastsService: ToastsService,
      private clientService: ClientsService,
      private invoiceService: InvoicesService,
      private parcelService: ParcelsService,
      public exportedFileService: ExportService,
    ) {
      this.apiurl = environment.api;
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.invoiceService.getInvoice(this.id).subscribe(
          (invoiceResponse: any) => {
            this.invoice = invoiceResponse.invoice;
            this.clientService.getClient(this.invoice.client_id).subscribe(
              (clientResponse: any) => {
                this.client = clientResponse;
              }
            );
            this.exportedFile = invoiceResponse.exportedFile;
            if ('report_validation_error' === this.invoice.status) {
                this.invoiceService.findValidationErrors(
                  this.invoice.id
                ).subscribe(
                  (validationErrorsResponse: any) => {
                    this.validationErrors = validationErrorsResponse;
                  }
                );
            }
            this.email = invoiceResponse.email;
          }
        );
    }

  reviewValidationError(error) {
    this.invoiceService.reviewValidationError(this.id, error.validationErrorId, error.reviewed).subscribe(
      (response: any) => {
        this.toastsService.showMessage('Odznaczono poprawnie!', 's', 950);
      }
    );
  }

  openParcel(error) {
    if (1 > error.parcelInternalNumbers.length) {
      return;
    }

    window.open('parcel/' + error.parcelInternalNumbers[0], '_blank');
  }

  downloadInvoice() {
    this.parcelService.getPublicFileToken().subscribe(
      (tokenResponse: any) => {
        window.open(
          this.apiurl
          + 'app/v1/invoice/'
          + this.invoice.id
          + '/download?_public_token='
          + tokenResponse.public_token
        );
      }
    );
  }

  downloadExportedFile() {
    this.parcelService.getPublicFileToken().subscribe(
      (tokenResponse: any) => {
        window.open(
          this.apiurl
          + 'app/v1/invoice/'
          + this.invoice.id
          + '/exported_file_download?_public_token='
          + tokenResponse.public_token
        );
      }
    );
  }

  showInFakturownia() {
    window.open(
      'https://romaniaexpress.fakturownia.pl/invoices/' + this.invoice.external_id,
    );
  }

  reIssue(){
      this.invoiceService.reIssue(this.id).subscribe(
          (response: any) => {
              this.toastsService.showMessage('Faktura zostanie wystawiona ponownie!', 'i', 950);
              window.location.href = '/invoices';
          }
      );
  }

  protected readonly Object = Object;
}

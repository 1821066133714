import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {CredentialsService} from '../../../../services/credentails/credentials.service';
import {ClientsService} from '../../../../services/clients/clients.service';
import {ActivatedRoute} from "@angular/router";
import {Subject} from "rxjs";
import {environment} from "../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ToastsService} from "../../../../services/toasts.service";
import {DataTableDirective} from "angular-datatables";
import {OperationalPriceListService} from "../../../../services/operational-price-list/operational-price-list.service";
@Component({
  selector: 'app-client-operational-price-list',
  templateUrl: './client-operational-price-list.component.html',
  styleUrls: ['./client-operational-price-list.component.scss']
})
export class ClientOperationalPriceListComponent implements OnInit {
  @Input() clientId = 1;
  @Input() type = 'cost';

  currentActivePriceLists = [];
  dtTranslation = null;
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, {static: true}) dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();
  public dtInit = false;
  priceLists = [];
  apiurl = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private toastsService: ToastsService,
    private operationalPriceListService: OperationalPriceListService

  ) {
    this.dtTranslation = toastsService.dtTranslation;
  }
  ngOnInit(): void {
    this.apiurl = environment.api + 'app/v1/operational_price_list/' + this.type + '/list/' + this.clientId;
    this.getPriceLists();
    this.operationalPriceListService.currentActive(this.type).subscribe(
      (response: []) => {
        this.currentActivePriceLists = response;
      }
    );
  }

  getPriceLists() {
    const that = this;
    if (!this.dtInit) {
      setTimeout(
        () => {
          this.dtTrigger.next();
        }, 100
      );
    } else {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtInit = false;
        setTimeout(
          () => {
            this.dtTrigger.next();
          }, 100
        );
      })
    }
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      processing: true,
      order: [[1, 'DESC'], [2, 'ASC']],
      language: this.dtTranslation,
      dom: '<"top"il>frt<"bottom"lp><"clear">',
      ajax: (dataTablesParameters: any, callback) => {
        that.http
          .post(
            this.apiurl,
            dataTablesParameters
          ).subscribe((resp: any) => {
          that.priceLists = resp.data;
          this.dtInit = true;

          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      },
      columns: [
        {data: 'name', name: 'opl.name'},
        {data: 'begin_date', name: 'opl.begin_date'},
        {data: 'special_association', name: 'opl.special_association'},
        {data: 'courier', name: 'c.name'},
      ],
    }
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import {UserRole} from '../enums/user-role';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const expectedRole: UserRole | UserRole[] = route.data['expectedRole'];
    const currentRole: number = this.authService.getRole;

    if (this.authService.isLoggedIn && this.isRoleAllowed(currentRole, expectedRole)) {
      return true;
    } else {
      return this.router.createUrlTree(['/']);
    }
  }

  private isRoleAllowed(currentRole: UserRole | null, expectedRole: UserRole | UserRole[]): boolean {
    if (Array.isArray(expectedRole)) {
      return expectedRole.includes(currentRole);
    } else {
      return currentRole === expectedRole;
    }
  }
}

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class StatusTypeService {
  private apiurl = '';
  constructor(private http: HttpClient, private router: Router) {
    this.apiurl = environment.api + 'app/v1/status_type';
  }

  show(id) {
    return this.http.get(this.apiurl + '/' + id);
  }

  store(form) {
    return this.http.post(this.apiurl, form, {observe: 'response'});
  }

  update(form) {
    return this.http.put(this.apiurl, form, {observe: 'response'});
  }

  delete(id) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id
      },
    };

    return this.http.delete(this.apiurl, options);
  }
}

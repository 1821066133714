import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class StatsService {

  private apiurl = '';

  constructor(private http: HttpClient, private router: Router) {
    this.apiurl = environment.api + 'app/v1/statistics';
  }

  getStats(selectedStatus: string){
    const url = this.apiurl + '/client-shipments';

    const params = new HttpParams().set('status', selectedStatus);

    return this.http.get(url, { params });
  }

  download(selectedStatus: string) {
    const params = new HttpParams().set('status', selectedStatus);

    return this.http.get(`${this.apiurl}/client-shipments/download`, {
      params,
      responseType: 'blob'
    });
  }

}

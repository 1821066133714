<div class="page-bg" (click)="closeMenu()"></div>
<div class="uk-grid">
    <div class="uk-width-1-6@m" *ngIf="userModel != null">
        <app-sidebar-admin *ngIf="UserRole.admin === userModel.role"></app-sidebar-admin>
        <app-sidebar-warehouse-worker  *ngIf="UserRole.warehouse_worker === userModel.role"r></app-sidebar-warehouse-worker>
        <app-sidebar-client *ngIf="UserRole.client === userModel.role"></app-sidebar-client>
    </div>
    <div [ngClass]="userModel != null ? 'uk-width-5-6@m' : 'uk-width-1-1@s'">
        <div class="container-fluid" (click)="closeMenu()">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<div class="uk-grid">
    <div class="uk-width-1-1 navbar">
        <p class="nav-text">
            {{'parcel_reported_resignations' | translate}}
          <button class="uk-button uk-button-primary padding-horizontal-small button-opt" routerLink="/my-parcel-reported-resignation" [attr.uk-tooltip]="'Utwórz'"><span uk-icon="plus"></span></button>

        </p>
    </div>

    <div class="uk-width-1-1 inner-shadow">

        <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
            <div class="mobile-overflow">
              <table class="uk-table uk-table-hover uk-table-divider" style="width:100%" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="table">
                <thead>
                  <tr>
                    <th>Numer</th>
                    <th>Data utworzenia</th>
                    <th>Data przyjęcia</th>
                    <th>Data odesłania</th>
                  </tr>
                </thead>
                <tbody *ngIf="resignations.length > 0">
                  <tr *ngFor="let resignation of resignations" [routerLink]="'/my-parcel-reported-resignation/'+resignation.id">
                    <td>
                      {{resignation.waybill}}
                    </td>
                    <td>
                      {{resignation.created_at}}
                    </td>
                    <td>
                      {{resignation.scanned_at}}
                    </td>
                    <td>
                      {{resignation.resend_at}}
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>

        </div>

    </div>

</div>

<div class="uk-grid">
  <div class="uk-width-1-1 navbar">
    <p class="nav-text">
      Nierozpoznany alias klienta {{unknownClientAlias.alias}}
    </p>
  </div>

  <div class="uk-width-1-1 inner-shadow">
    <div class="uk-width-1-1@l">
      <div class="uk-card uk-card-default uk-card-body uk-margin-small-right uk-margin-small-top uk-padding-small">
        <div class="mobile-overflow">
          <div class="uk-grid">
            <div class="uk-width-1-3">
              <label>Alias</label>
              <input class="uk-input" [(ngModel)]="unknownClientAlias.alias" readonly>
            </div>
            <div class="uk-width-1-3">
              <label>Sugerowany klient</label>
              <input autocomplete="off" placeholder="Brak" [class.uk-form-danger]="clientInput.touched && id_client.value == ''" (keyup)="searchClient(clientInput)" type="text" #clientInput="ngModel" name="client" [(ngModel)]="unknownClientAlias.client_name" class="uk-input" required #clientField>
              <div style="position: absolute; max-height: 200px;" class="uk-overflow-auto">
                <ul class="uk-list uk-list-divider uk-position-relative uk-margin-remove-top" style="background-color: white; width: 100%;" *ngIf="clients.length > 0" [style.width]="clientField.clientWidth + 'px'">
                  <li *ngFor="let client of clients" (click)="setClient(client);" style="padding-left: 5px!important;">{{client.name}}</li>
                </ul>
              </div>
              <input type="hidden" #id_client="ngModel" autocomplete="none" [(ngModel)]="unknownClientAlias.client_id"  class="uk-input">
            </div>
            <div class="uk-width-1-6 ">
              <label>Alias emag</label>
              <select name="is_emag" required class="uk-input" [(ngModel)]="unknownClientAlias.is_emag">
                <option value="1">Tak</option>
                <option value="0">Nie</option>
              </select>
            </div>
            <div class="uk-width-1-6">
              <button class="uk-button uk-button-primary uk-margin-small-right mt-23 pl-6" (click)="resolve()">
                Utwórz alias
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

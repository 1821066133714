import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastsService } from '../../../../services/toasts.service';
import { ClientExternalApiCredentialsService } from 'src/app/services/client-external-api-credentials/client-external-api-credentials.service';
import { ExternalApiCredentialsModel } from 'src/app/models/external-api-credentials.model';
import { NgForm } from '@angular/forms';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { ClientModel } from 'src/app/models/client.model';
import { UsersService } from 'src/app/services/users/users.service';
import { UserModel } from 'src/app/models/user.model';

@Component({
  selector: 'app-client-external-api-credentials-detials',
  templateUrl: './client-external-api-credentials-detials.component.html',
  styleUrls: ['./client-external-api-credentials-detials.component.scss']
})
export class ClientExternalApiCredentialsDetialsComponent implements OnInit {

  @Input() clientId: number;
  @Input() id: number;
  credentials: any[] = [];
  client: any = new ClientModel();
  user: UserModel = new UserModel();
  creatorId: number = null;

  model: ExternalApiCredentialsModel = {
    client_id: null,
    id: null,
    username: null,
    password: null,
    type: null,
    token: null,
    ip: null,
  };

  constructor(
    private clientExternalApiCredentialsService: ClientExternalApiCredentialsService,
    private clientsService: ClientsService,
    private usersService: UsersService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private toastsService: ToastsService,
  ) {
  }

  ngOnInit() {
    this.activeRoute.params.subscribe(params => {
      this.clientId = params['clientId'];
      this.id = params['id'];
    });

    this.getCredential();
    this.getClient();
  }

  getCredential() {
    this.clientExternalApiCredentialsService.findByIdclient(this.clientId, this.id).subscribe(
      (response: []) => {
        this.credentials = response;
        this.creatorId = this.credentials[0].creator_id;
        this.setUserEmail(this.creatorId);
      }
    );
  }

  setUserEmail(creatorId: number) {
    this.usersService.getUser(creatorId).subscribe(
      (s: UserModel) => {
        this.user = s;
        this.credentials[0].creator_id = this.user.email;
      }
    );
  }

  getClient() {
    this.clientsService.getClient(this.clientId).subscribe(
      (s: ClientModel) => {
        this.client = s;
      }
    );
  }

  onSubmit(form: NgForm) {
    this.model.client_id = this.clientId;
    this.model.id = this.id;
    this.model.username = form.value.username;
    this.model.password = form.value.password;
    // this.model.token = form.value.token;
    this.model.type = form.value.type;
    // this.model.ip = form.value.ip;

    this.onUpdateCredentials(this.model);
  }

  onUpdateCredentials(model: ExternalApiCredentialsModel) {
    console.log('Dane z formularza:', this.model);
    this.clientExternalApiCredentialsService.update(this.clientId, this.model).subscribe(
      (response: any) => {
        this.router.navigateByUrl('/client/' + this.clientId + '/card/external_api_credentials').then(r => {
          this.toastsService.showMessage('Dane zostały zapisane', 's');
        });
      }
    );
  }

  async delete() {
    const promised = await this.toastsService.showConfirmModal();
    if (!promised) {
      return;
    }

    this.clientExternalApiCredentialsService.delete(this.clientId, this.id).subscribe(
      (response: any) => {
        this.router.navigateByUrl('/client/' + this.clientId + '/card/external_api_credentials').then(r => {
          this.toastsService.showMessage('Dane uwieżytelniające zostały pomyślie usunięte!', 's');
        });
      }
    );
  }

}


<div class="uk-grid">
    <div class="uk-width-1-1 navbar">
        <p class="nav-text">
            {{'price_edit' | translate}}
        </p>
    </div>


  <div class="uk-width-1-1 inner-shadow">

    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
            <div class="mobile-overflow">
                <form #priceForm="ngForm" (ngSubmit)="onEditPrice(priceForm)">
                    <input type="hidden" name="id" #id="ngModel" [(ngModel)]="price.id">
                    <div class="uk-grid">
                        <div class=" uk-width-1-2@m">
                            <label>{{'net_price' | translate}}</label>
                            <input class="uk-input" type="number" name="net_price" #net_price="ngModel" [(ngModel)]="price.net_price" required>
                        </div>
                        <div class=" uk-width-1-2@m">
                            <label>{{'vat_percentage' | translate}}</label>
                            <input class="uk-input" type="number" name="vat_percentage" #vat_percentage="ngModel" [(ngModel)]="price.vat_percentage" required>
                        </div>

                        <div class=" uk-width-1-2@m">
                            <label>{{'max_insurance_value' | translate}}</label>
                            <input class="uk-input" type="number" name="max_insurance_value" min="0" max="100" [ngModel]="price.max_insurance_value" required>
                        </div>
                        <div class=" uk-width-1-2@m">
                            <label>{{'min_weight_value' | translate}}</label>
                            <input class="uk-input" type="number" name="min_weight_value" min="0" max="100" [ngModel]="price.min_weight_value" required>
                        </div>
                        <div class=" uk-width-1-2@m">
                            <label>{{'max_weight_value' | translate}}</label>
                            <input class="uk-input" type="number" name="max_weight_value" min="0" max="100"  [ngModel]="price.max_weight_value" required>
                        </div>
                    </div>

                    <div class="uk-width-1-1@m uk-margin-top">
                        <label><input class="uk-checkbox" type="checkbox" value="1"  [ngModel]="price.this_side_up" autocomplete="none" name="this_side_up"> {{'this_side_up' | translate}}</label>
                    </div>
                    <div class="uk-width-1-1@m">
                        <label><input class="uk-checkbox" type="checkbox" value="1"  [ngModel]="price.liquid" autocomplete="none" name="liquid"> {{'liquid' | translate}}</label>
                    </div>
                    <div class="uk-width-1-1@m">
                        <label><input class="uk-checkbox" type="checkbox" value="1"  [ngModel]="price.not_expose_to_frost" autocomplete="none" name="not_expose_to_frost"> {{'not_expose_to_frost' | translate}}</label>
                    </div>
                    <div class="uk-width-1-1@m">
                        <label><input class="uk-checkbox" type="checkbox" value="1"  [ngModel]="price.cash_on_delivery" autocomplete="none" name="cash_on_delivery"> {{'cash_on_delivery' | translate}}</label>
                    </div>
                    <div class="uk-width-1-1@m">
                        <label><input class="uk-checkbox" type="checkbox" value="1" [ngModel]="price.caution" autocomplete="none" name="caution"> {{'caution' | translate}}</label>
                    </div>



                    <div class="uk-margin">
                        <button class="uk-button uk-button-primary" [disabled]="priceForm.form.invalid">{{'save' | translate}}</button>
                    </div>

                </form>
            </div>

        </div>

    </div>

</div>


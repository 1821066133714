import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {ToastsService} from '../../../../services/toasts.service';
import {Subject} from 'rxjs';
import {DataTableDirective} from 'angular-datatables';
import {UserModel} from "../../../../models/user.model";
import {ClientModel} from '../../../../models/client.model';
import {InvoicesService} from "../../../../services/invoices/invoices.service";

declare var UIkit: any;

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  public clientModel: ClientModel = new ClientModel();
  @ViewChild(DataTableDirective, {static: true}) dtElement: DataTableDirective;
  public dtInit = false;
  invoices: any = [];
  triggerInvoicingForm = {
    trigger_date: null,
  }
  apiurl = '';
  dtTranslation = null;

  criteria = {
    status: null,
  };

  constructor(
    private http: HttpClient,
    private toastsService: ToastsService,
    private invoiceService: InvoicesService,
  ) {
    this.apiurl = environment.api;
    this.dtTranslation = toastsService.dtTranslation;
  }

  ngOnInit(): void {
    this.showInvoices();
    const date = new Date();
    this.triggerInvoicingForm.trigger_date =  String(date.getFullYear())
      + '-' + (date.getMonth() + 1).toString().padStart(2, '0')
      + '-' + date.getDate().toString().padStart(2, '0');
  }


  showInvoices() {
    const that = this;
    if (!this.dtInit) {
      setTimeout(
          () => {
            this.dtTrigger.next();
          }, 100
      );
    } else {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtInit = false;
        setTimeout(
            () => {
              this.dtTrigger.next();
            }, 100
        );
      });
    }

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      processing: true,
      language: this.dtTranslation,
      order: [
        [2, 'desc'],
      ],
      ajax: (dataTablesParameters: any, callback) => {
        that.http
            .post(
                this.apiurl + 'app/v1/invoice/list',
              {...dataTablesParameters,
                criteria: this.criteria,
              }
            ).subscribe((resp: any) => {
          that.invoices = resp.data;
          this.dtInit = true;

          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      },
      columns: [
        {data: 'client_name', name: 'c.name'},
        {data: 'status', name: 'invoices.status'},
        {data: 'issued_data', name: 'invoices.issue_date'},
        {data: 'period', name: 'invoices.period_from'},
        {data: 'total_amount', name: 'invoices.total_amount'},
        {data: 'invoice_number', name: 'invoices.invoice_number'},
      ]
    };
  }

  runFilters() {
    const table: DataTables.Api = $('#invoice-list').DataTable();
    table.search('').draw();
    table.page(0);

    this.showInvoices();
  }

  refreshFilters() {
    this.criteria = {
      status: null,
    };
    this.runFilters();
  }

  showTriggerInvoicingModal() {
    UIkit.modal('#triggerInvoicing').show();
  }

  triggerInvoicing() {
    this.toastsService.showMessage('Przygotowanie do rozpcoczęcia generowania faktur', 's');

    this.invoiceService.triggerInvoicing(this.triggerInvoicingForm).subscribe(
      (response: any) => {
        UIkit.modal('#triggerInvoicing').hide();
        this.toastsService.showMessage('Rozpoczęto generowanie faktur', 's');
      },
      (error: any) => {
        UIkit.modal('#triggerInvoicing').hide();
        switch (error.status) {
          case 409:
            this.toastsService.showMessage('Przed rozpoczęciem generowania faktur należy przypisać przesyłki buforowe.', 'e', 10000);
            return;
        }
      }
    );
  }
}

import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../../services/auth/auth.service';
import {UserModel} from '../../../models/user.model';
import {ClientModel} from '../../../models/client.model';
import {ProblemsService} from '../../../services/problems/problems.service';
import {ExportService} from '../../../services/export/export.service';
import {ExportListComponent} from '../../modals/export-list/export-list.component';
import {UserRole} from '../../../enums/user-role';
declare var UIkit: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './abstract-sidebar.component.html',
  styleUrls: ['./abstract-sidebar.component.scss']
})

export class AbstractSidebarComponent implements OnInit{
  public userModel: UserModel = new UserModel();
  public apiEnabled: ClientModel = new ClientModel();
  public newProblemsCount = 0;
  public mobileMenuIsActive = false;
  public exportsCount = 0;
  @ViewChild('exportList') exportListModal: ExportListComponent;

  constructor(private authService: AuthService, private problemsService: ProblemsService, private exportService: ExportService) { }

  ngOnInit(): void {
    this.authService.isLogged.subscribe((userModel) => {
      this.userModel = userModel;
      if (UserRole.warehouse_worker === this.userModel.role) {
        return;
      }

      this.exportService.getMyLatestByType().subscribe(
        (exports: []) => {
          this.exportsCount = exports.length;
        }
      );

      if (UserRole.admin === this.userModel.role) {
        this.problemsService.getNewCount().subscribe(
          (response: any) => {
            this.newProblemsCount = response.count;
          }
        );
      }
    });
  }

  onLogout(): void {
    localStorage.removeItem(
      btoa('v2-parcels-list-criteria-'+this.userModel.id)
    )
    this.authService.logout();
  }

  showCheckPermissionsModal(){
    UIkit.modal('#check-permissions-modal').show();
  }

  openExportsModal() {
    this.exportListModal.showExportedFiles();
    UIkit.modal('#export-list-modal').show();
  }
}

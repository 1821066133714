import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {
  private apiurl = '';
  constructor(private http: HttpClient) {
    this.apiurl = environment.api + 'app/v1/invoice';
  }

  triggerInvoicing(form) {
    return this.http.post(this.apiurl + '/trigger', form, {observe: 'response'});
  }

  getInvoice(id) {
    return this.http.get(this.apiurl + '/' + id);
  }

  findValidationErrors(id) {
    return this.http.get(this.apiurl + '/' + id + '/validation_errors');
  }

  reviewValidationError(invoiceId, id, reviewed) {
    return this.http.patch(
      this.apiurl + '/' + invoiceId + '/validation_errors',
      {
        id,
        reviewed,
      }
    )
  }

  reIssue(id: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        'id': id,
      },
    };

    return this.http.delete(this.apiurl + '/', options);
  }
}

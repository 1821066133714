<script src="../parcel-from-number/parcel-from-number.component.ts"></script>
<script src="../../../services/problems/problems.service.ts"></script>
<div class="uk-grid">
  <div class="uk-width-1-1 navbar">
    <p class="nav-text">
      {{'parcel_scan_search' | translate}}
    </p>
  </div>
  <div class="uk-width-1-1 inner-shadow">
    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
      <div class="uk-width-1-1@l" (click)="scanningLocked = false">
        <hr>
        <form class="" #parcelForm="ngForm" (ngSubmit)="runSearch()">
          <div class="uk-alert uk-alert-danger" *ngIf="error">
            {{"parcel_search_error" | translate}}
          </div>

          <div class="uk-grid">
            <div class="uk-width-1-3">
              <div>
                <label>{{'parcel_number' | translate}}</label>
                <input name="number" class="uk-input" type="text" required [(ngModel)]="barcode" #barcodeInput>
              </div>
            </div>
            <div class="uk-width-1-3">
              <a class="uk-button uk-button-primary uk-margin-top" (click)="clear()" *ngIf="barcode.length > 0">Wyczyść</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class OperationalPriceListService {
  private apiurl = '';
  constructor(private http: HttpClient, private router: Router) {
    this.apiurl = environment.api + 'app/v1/operational_price_list';
  }

  specialAssociation(type) {
    const url = this.apiurl + '/' + type + '/special_associations';
    return this.http.get(url);
  }

  store(form) {
    return this.http.post(this.apiurl + '/' +form.type, form, {observe: 'response'});
  }

  update(form) {
    return this.http.put(this.apiurl + '/' +form.type, form, {observe: 'response'});
  }

  find(type, id) {
    const url = this.apiurl + '/' + type + '/one/' + id;
    return this.http.get(url);
  }

  checkIsUsed(type, id) {
    const url = this.apiurl + '/' + type + '/check/' +id;
    return this.http.get(url);
  }

  delete(type, id) {
    const url = this.apiurl + '/' + type;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id
      },
    };

    return this.http.delete(url, options);
  }

  deactivate(type, id) {
    return this.http.patch(this.apiurl + '/' + type, {id}, {observe: 'response'});
  }

  currentActive(type) {
    const url = this.apiurl + '/' + type + '/current_active';
    return this.http.get(url);
  }

  currentActiveDefault(type, specialAssociation, idCourier = null, idCountry = null) {
    let url = this.apiurl + '/' + type + '/current_active/' + specialAssociation;

    if (null !== idCourier && null !== idCountry) {
      var params: HttpParams = new HttpParams()
        .append('id_country', idCountry)
        .append('id_courier', idCourier);
    }
    else if (null === idCourier) {
      var params: HttpParams = new HttpParams().append('id_country', idCountry);
    }
    else if (null === idCountry) {
      var params: HttpParams = new HttpParams().append('id_courier', idCourier);
    }
    else {
      var params: HttpParams = new HttpParams()
    }

    return this.http.get(url, {
      params: params,
    });
  }
}

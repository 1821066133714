import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ToastsService} from '../toasts.service';
import {saveAs} from 'file-saver';
import {ParcelsService} from "../parcels/parcels.service";
@Injectable({
  providedIn: 'root'
})
export class ParcelService {
  private apiurl = '';
  constructor(private http: HttpClient, private toastsService: ToastsService, private parcelsService: ParcelsService) {
    this.apiurl = environment.api + 'app/v1/parcel';
  }

  adoptBufferParcelToClient(form) {
    return this.http.patch(this.apiurl + '/adopt/buffer', form, {observe: 'response'});
  }

  adoptUnprocessableParcelToClient(form) {
    return this.http.patch(this.apiurl + '/adopt/unprocessable', form, {observe: 'response'});
  }

  updateDimensions(form) {
    return this.http.patch(this.apiurl + '/dimensions', form, {observe: 'response'});
  }

  updateSelectiveDimensions(form) {
    return this.http.patch(this.apiurl + '/dimensions-selective', form, {observe: 'response'});
  }

  checkDimensions(form) {
    return this.http.post(this.apiurl + '/check-dimensions', form, {observe: 'response'});
  }

  delete(id, mode) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id,
        mode
      },
    };
    return this.http.delete(this.apiurl, options);
  }

  findByIdentifier(identifier, fields: string[] = [], identifierType = 'id') {
    const params = new HttpParams({
      fromObject: {'fields[]': fields}
    });
    return this.http.get(
      this.apiurl + '/find/' + identifierType + '/' + identifier,
      { params }
    )
  }

  findByHistoryIdentifier(identifier, identifierType = 'id') {
    return this.http.get(
      this.apiurl + '/find/' + identifierType + '/' + identifier + '/history'
    )
  }

  findLabelMetadata(identifier, paperSize) {
    return this.http.get(
        this.apiurl + '/' + identifier + '/label/' + paperSize + '/metadata'
    );
  }
}

<div class="sidebar computer-only">
  <div class="">
    <div class="" style=" margin-bottom: 1px">
      <div class=" ">
        <img src="/assets/img/re-logo.png" class="uk-align-center uk-padding-top logo sidebar-logo-size">
      </div>
    </div>
    <div class="uk-width-1-1 uk-m mb-1">
      <div>
        <h4 class="uk-text uk-text-muted uk-text-center uk-text-small">{{userModel.email}}</h4>
      </div>
    </div>
    <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/dashboard">
      {{'my_parcels' | translate}}
    </a>
    <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/api_settings" *ngIf="userModel.api_enabled">
      {{'manage_api' | translate}}
    </a>
    <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/iai_settings" *ngIf="userModel.iai_idosell_enabled">
      {{'manage_idosell' | translate}}
    </a>
    <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/company">
      {{'my_company' | translate}}
    </a>
    <a class="uk-button uk-button-primary uk-width-1-1 mb-1" (click)="openExportsModal()">
      Moje raporty
    </a>
    <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/my-parcel-reported-resignations">
      {{'parcel_reported_resignations' | translate}}
    </a>
    <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/profile">
      {{'change_password' | translate}}
    </a>
    <a class="uk-button uk-button-danger uk-width-1-1" (click)="onLogout()">{{'logout' | translate}}</a>
  </div>
  <check-permissions-modal></check-permissions-modal>
</div>

<div class="sidebar mobile-only">
  <div>
    <div class="mb-1 mt-100">
      <div class=" ">
        <img src="/assets/img/re-logo.png" class="uk-align-center uk-padding-top logo pt-10">
      </div>
    </div>
    <div class="uk-width-1-1 mb-1">
      <div>
        <h4 class="uk-text uk-text-muted uk-text-center">{{userModel.email}}</h4>
      </div>
    </div>
    <a class="uk-button uk-button-primary uk-width-1-1 mb-1" (click)="mobileMenuIsActive = !mobileMenuIsActive">{{'Menu' | translate}}</a>
    <div class="uk-width-1-1" [class.uk-hidden]="!mobileMenuIsActive">
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/dashboard" *ngIf="userModel.role >= 2">{{'my_parcels' | translate}}</a>
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/dashboard" *ngIf="userModel.role < 2">{{'parcels' | translate}}</a>
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/users" *ngIf="userModel.role == 0">{{'manage_users' | translate}}</a>
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/clients"  *ngIf="userModel.role == 0">{{'manage_clients' | translate}}</a>
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/operational-price-list/income/list" *ngIf="userModel.role == 0">{{'manage_prices' | translate}}</a>
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/statuses" *ngIf="userModel.role == 0">{{'manage_statuses' | translate}}</a>
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/tracking-manager" *ngIf="userModel.role < 2">{{'manage_parcel_statuses' | translate}}</a>
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/problems" *ngIf="userModel.role == 0">{{'problems' | translate}} <span uk-icon="warning" *ngIf="newProblemsCount >0" [attr.uk-tooltip]="'Wykryto nowe nieprawidłowości!'"></span></a>
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/calendar" *ngIf="userModel.role == 0">{{'calendar' | translate}}</a>
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/stats" *ngIf="userModel.role == 0">{{'stats' | translate}}</a>
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/api_settings" *ngIf="(userModel.role >= 2) && (userModel.api_enabled) ">{{'manage_api' | translate}}</a>
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/iai_settings" *ngIf="(userModel.role >= 2) && (userModel.iai_idosell_enabled) ">{{'manage_idosell' | translate}}</a>
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/company"  *ngIf="userModel.role >= 2">{{'my_company' | translate}}</a>
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/profile">{{'change_password' | translate}}</a>
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" (click)="showCheckPermissionsModal()" *ngIf="userModel.role == 0">{{'check_permissions' | translate}}</a>
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" routerLink="/parcel-reported-resignations" *ngIf="userModel.role == 0">{{'parcel_reported_resignations' | translate}}</a>
      <a class="uk-button uk-button-primary uk-width-1-1 mb-1" *ngIf="userModel.role < 2" routerLink="/parcel-search" >{{'parcel_scan_search' | translate}}</a>
      <a class="uk-button uk-button-danger uk-width-1-1" (click)="onLogout()">{{'logout' | translate}}</a>
      <div>
        <a class="uk-button uk-button-primary uk-width-1-1 mb-1" (click)="openExportsModal()">Moje raporty</a>
      </div>
    </div>
  </div>
  <check-permissions-modal></check-permissions-modal>
  <export-list [type]="'list'" #exportList></export-list>
</div>

<div class="uk-grid">
  <div class="uk-width-1-1 navbar">
    <p class="nav-text">
      {{'calendar' | translate}}
      <button class="uk-button uk-button-primary padding-horizontal-small button-opt" (click)="openModal()" [attr.uk-tooltip]="'Utwórz dzień wolny'"><span uk-icon="plus"></span></button>
    </p>
  </div>

  <div class="uk-width-1-1 inner-shadow">


    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
      <div class="mobile-overflow">
        <div class="uk-grid uk-margin-bottom">
          <div class="uk-width-1-6@m">
            <label>{{'year' | translate}}</label>
            <select class="uk-select" (change)="getDays()" [(ngModel)]="selectedYear">
              <option *ngFor="let year of years" [value]="year">{{year}}</option>
            </select>
          </div>
        </div>

        <table style="width: 100%" class="uk-table uk-width-1-1 uk-table-hover uk-table-divider" datatable [dtOptions]="dtOptions" >
          <thead>
          <tr>
            <th style="width: 15%">{{'date' | translate}}</th>
            <th style="width: 80%">{{'name' | translate}}</th>
            <th style="width: 5%"></th>
          </tr>
          </thead>
          <tbody *ngIf="days?.length > 0">
          <tr *ngFor="let day of days" >
            <td>{{ day.date }}</td>
            <td>{{ day.name}}</td>
            <td><span uk-icon="trash" title="Usuń" (click)="deleteDay(day.id)"></span></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div id="addDay" uk-modal class="addDay">
  <div class="uk-modal-dialog">
    <button class="uk-modal-close-default" type="button" uk-close></button>

    <div class="uk-modal-header">
      <h2 class="uk-modal-title">{{'add_day' | translate}}</h2>
    </div>

    <div class=" uk-modal-body">
      <div class="uk-grid">
        <div class="uk-width-1-3@m">
          <label>{{'date' | translate}}</label>
          <input type="date" autocomplete="none" name="when"  [(ngModel)]="dayModel.date" required data-ukpicker class="uk-input">
        </div>

        <div class="uk-width-2-3@m">
          <label>{{'name' | translate}}</label>
          <input type="text" autocomplete="none" name="where" [(ngModel)]="dayModel.name" required class="uk-input">
        </div>
      </div>
      <div class="uk-margin">
        <button class="uk-button uk-button-primary uk-width-1-1 uk-margin-top" (click)="onSubmit()">{{'add_day' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="uk-grid">
  <div class="uk-width-1-1 navbar">
    <p class="nav-text">{{'add_client' | translate}}</p>
  </div>


  <div class="uk-width-1-1 inner-shadow">

    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
      <div class="mobile-overflow">
        <form #clientForm="ngForm" (ngSubmit)="onCreateClient(clientForm)">
          <div class="uk-grid">
            <div class=" uk-width-1-2@m">
              <label class="required">{{'email' | translate}}</label>
              <input class="uk-input" type="text" name="email" #email="ngModel" ngModel required>
            </div>
            <div class=" uk-width-1-2@m">
              <label class="required">{{'nip' | translate}}</label>
              <input class="uk-input" type="text" name="nip" #nip="ngModel" ngModel required>
            </div>
            <div class=" uk-width-1-2@m">
              <label class="required">{{'name' | translate}}</label>
              <input class="uk-input" type="text" name="name" #name="ngModel" ngModel required>
            </div>
            <div class=" uk-width-1-2@m">
              <label>{{'contact_name' | translate}}</label>
              <input class="uk-input" [(ngModel)]="contactName" type="text" name="contact_name" #contact_name="ngModel" ngModel>
            </div>
            <div class=" uk-width-1-2@m">
              <label class="required">{{'street' | translate}}</label>
              <input class="uk-input" type="text" name="street" #street="ngModel" ngModel required>
            </div>
            <div class=" uk-width-1-2@m">
              <label class="required">{{'city' | translate}}</label>
              <input class="uk-input" type="text" name="city" #city="ngModel" ngModel required>
            </div>
            <div class=" uk-width-1-2@m">
              <label class="required">{{'post_code' | translate}}</label>
              <input class="uk-input" type="text" name="post_code" #post_code="ngModel" ngModel required>
            </div>
            <div class=" uk-width-1-2@m">
              <label>{{'address_additional_info' | translate}}</label>
              <input class="uk-input" type="text" [(ngModel)]="addressAdditionalInfo" name="address" #address="ngModel" ngModel>
            </div>
            <div class=" uk-width-1-2@m">
              <label>{{'bank_account_number' | translate}}</label>
              <input class="uk-input" type="text" [(ngModel)]="bankAccountNumber" name="bank_account_number" #bank_account_number="ngModel" ngModel>
            </div>

            <div class="uk-width-1-2@m">
              <label class="required">{{'cod_enabled' | translate}}</label>
              <select class="uk-select" [(ngModel)]="defaultNo" name="cod_enabled" #cod_enabled="ngModel" ngModel>
                <option value="0">Nie</option>
                <option value="1">Tak</option>
              </select>
            </div>
            <div class=" uk-width-1-2@m">
              <label>{{'re_client_api_enabled' | translate}}</label>
              <select class="uk-input" [(ngModel)]="defaultNo" name="api_enabled" #api_enabled="ngModel" ngModel>
                <option value="0">{{'no' | translate}}</option>
                <option value="1">{{'yes' | translate}}</option>
              </select>
            </div>

            <div class="uk-width-1-2@m">
              <label>{{'show_price_parcel' | translate}}</label>
              <select class="uk-select" [(ngModel)]="defaultNo" name="show_price_parcel" #show_price_parcel="ngModel" ngModel>
                <option value="0">Nie</option>
                <option value="1">Tak</option>
              </select>
            </div>

            <div class=" uk-width-1-2@m">
              <label class="required">{{'shipment_note' | translate}}</label>
              <input class="uk-input" type="text" [(ngModel)]="shipmentNote" name="shipment_note" #shipment_note="ngModel" ngModel required>
            </div>
          </div>

          <div class="uk-grid uk-margin-remove-top">
            <div class=" uk-width-1-2@m">
              <label>{{'phone_number_prefix' | translate}}</label>
              <input class="uk-input" type="text" name="phone_number_prefix" pattern="[0]{2}+[0-9]{0,2}"
                     [(ngModel)]="phoneNumberPrefix"
                     [class.uk-text-danger]="phone_number_prefix.invalid" #phone_number_prefix="ngModel" ngModel
                     >
            </div>
            <div class=" uk-width-1-2@m">
              <label class="required">{{'phone_number' | translate}}</label>
              <input class="uk-input" type="text" name="phone_number"
                     [class.uk-text-danger]="phone_number.invalid" #phone_number="ngModel" ngModel required>
            </div>

            <div class=" uk-width-1-2@m">
              <label class="required">{{'re_returning_address' | translate}}</label>
              <textarea class="uk-input" type="text" name="re_returning_address" #re_returning_address="ngModel" [(ngModel)]="defaultValueReReturningAddress" ngModel required cols="3" style="min-height: 5em"></textarea>
            </div>

            <div class=" uk-width-1-2@m">
              <label class="required">{{'re_broadcasting_facility_address' | translate}}</label>
              <textarea class="uk-input" type="text" name="re_broadcasting_facility_address" #re_broadcasting_facility_address="ngModel" [(ngModel)]="defaultReBroadcastingFacilityAddress" ngModel required style="min-height: 5em"></textarea>
            </div>

            <div class=" uk-width-1-2@m">
              <label class="required">{{'re_broadcasting_facility_address' | translate}}</label>
              <textarea class="uk-input" type="text" name="re_broadcasting_facility_address" #re_broadcasting_facility_address="ngModel" [(ngModel)]="defaultReBroadcastingFacilityAddress" ngModel required style="min-height: 5em"></textarea>
            </div>

            <div class="uk-width-1-1@m mt-10">
              <p class="uk-heading-divider">Wybierz kraje do których klient ma mieć możliwość nadawania przesyłek</p>
              <div class="uk-grid">
                <div class="uk-width-1-1" *ngFor="let country of countries; let i = index">
                  <input type="checkbox" [(ngModel)]="countries[i].checked" name="countries[{{i}}].checked" (click)="changeAllowedCountry(i)">{{country.name}}
                </div>
              </div>
            </div>
          </div>

          <div class="uk-margin">
            <button class="uk-button uk-button-primary"
                    [disabled]="clientForm.form.invalid">{{'add' | translate}}</button>
          </div>

        </form>
      </div>

    </div>

  </div>

</div>


<div class="uk-grid">
  <div class="uk-width-1-1 navbar">
    <p class="nav-text">
      {{((id === null ? 'create' : 'update') + '_' + (idClient === null ? 'default' : 'client' ) + '_' + type + '_operational_price_list') | translate}} {{clientName}}
      <button class="uk-button uk-button-primary padding-horizontal-small button-opt" [attr.uk-tooltip]="'back_to_'+type+'_operational_price_list' | translate" (click)="back()">
        <span uk-icon="sign-out"></span>
      </button>
      <button *ngIf="idClient!==null && id!==null && currentActiveIds.includes(id)" class="uk-button uk-button-primary uk-margin-remove-right padding-horizontal-small button-opt" (click)="deactivate()">
        <span uk-icon="history" [attr.uk-tooltip]="'Powróć do ustawień domyślnych'"></span>
      </button>
      <button *ngIf="idClient!==null && !changesDisabled && this.form.special_association !== undefined && isDefaultPriceListAvailable"
              class="uk-button uk-button-primary uk-margin-remove-right padding-horizontal-small button-opt"
              (click)="cloneFromDefault()">
        <span uk-icon="copy" [attr.uk-tooltip]="'Pobierz wartości z domyślnych!'"></span>
      </button>
      <button *ngIf="id!==null && !changesDisabled" class="uk-button uk-button-danger uk-margin-remove-right padding-horizontal-small button-opt" (click)="delete()">
        <span uk-icon="trash" [attr.uk-tooltip]="'Usuń'"></span>
      </button>
    </p>
 </div>
  <div class="uk-width-1-1 inner-shadow">
    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-top uk-margin-bottom">
        <form #x="ngForm" (ngSubmit)="save()">
          <p class="uk-heading-divider">{{'operational_price_list_basic_data' | translate}}</p>
            <div class="uk-grid">
              <div class="uk-width-1-5">
                <label class="required">{{'name' | translate}}</label>
                <input [disabled]="changesDisabled" name="name" type="text" required [(ngModel)]="form.name" class="uk-input">
              </div>
              <div class="uk-width-1-5">
                <label class="required">
                  {{'special_association' | translate}}
                  <span *ngIf="type === 'income'" uk-icon="warning" style="padding-top: 5px" [attr.uk-tooltip]="'EMAG Paczkomat - wybranie tej opcji spowoduje że cena te będzie obowiązywać przesyłek emag nadanych do paczkomatu<br>Standardowa dostawa - dotyczyć będzie wszystkich pozostałych przesyłek'"></span>
                  <span *ngIf="type === 'cost'" uk-icon="warning" style="padding-top: 5px" [attr.uk-tooltip]="'EMAG Paczkomat - wybranie tej opcji spowoduje że cena te będzie obowiązywać przesyłek emag nadanych do paczkomatu<br>EMAG zwroty - wybranie tej opcji spowoduje że cena te będzie obowiązywać przesyłek zwrotów emag<br> Dostawa paczkomatem - wybranie tej opcji spowoduje że cena te będzie obowiązywać przesyłekzamówionych do paczkomatu nie poprzez Emag<br>Dostawa do odbiorcy - wybranie tej opcji spowoduje że cena ta będzie obowiązywać pozostałe przesyłki<br>'"></span>
                </label>
                <select [disabled]="changesDisabled" name="special_association" [(ngModel)]="form.special_association" class="uk-select" (change)="checkIsDefaultAvailable()">
                  <option *ngFor="let specialAssociation of specialAssociations" value="{{specialAssociation}}">{{specialAssociation|translate}}</option>
                </select>
              </div>
              <div class="uk-width-3-5">
                <div class="uk-grid">
                  <div class="uk-width-1-4">
                    <label class="required">Data rozpoczęcia</label>
                    <input [disabled]="changesDisabled" name="begin_date" type="date" required [(ngModel)]="form.begin_date" class="uk-input">
                  </div>
                  <div class="uk-width-1-4" *ngIf="type === 'cost'">
                    <label class="required">{{'courier' | translate}}</label>
                    <select [disabled]="changesDisabled"
                            name="id_courier"
                            [(ngModel)]="form.id_courier"
                            class="uk-select"
                            (change)="checkIsDefaultAvailable()"
                    >
                      <option *ngFor="let courier of couriers" value="{{courier.id}}">{{courier.name}}</option>
                    </select>
                  </div>
                  <div class="uk-width-1-4" *ngIf="type === 'income'">
                    <label class="required">{{'country' | translate}}</label>
                    <select [disabled]="changesDisabled"
                            name="id_country"
                            [(ngModel)]="form.id_country"
                            class="uk-select"
                            (change)="checkIsDefaultAvailable()"
                    >
                      <option *ngFor="let country of countries" value="{{country.id}}">{{country.name}}</option>
                    </select>
                  </div>
                  <div class="uk-width-1-4">
                    <label class="required">
                      {{'price_list_currency' | translate}}
                      <span uk-icon="warning" style="padding-top: 5px" [attr.uk-tooltip]="'Określa walutę w jakiej prezentowane są kwoty (nie dotyczy rozliczania wartośdi przesyłki oraz wartość pobrania)'"></span>
                    </label>
                    <select [disabled]="changesDisabled" name="currency" [(ngModel)]="form.currency" class="uk-select">
                      <option *ngFor="let currency of currencies" value="{{currency}}">{{currency}}</option>
                    </select>
                  </div>
                  <div class="uk-width-1-4">
                    <label class="required">
                      {{'currency_for_specific_factors' | translate}}
                      <span uk-icon="warning" style="padding-top: 5px" [attr.uk-tooltip]="'Określa walutę w jakiej podawane są wartość przesyłki oraz wartość pobrania'"></span>
                    </label>
                    <select [disabled]="changesDisabled" name="specific_factors_currency" [(ngModel)]="form.specific_factors_currency" class="uk-select">
                      <option *ngFor="let currency of currencies" value="{{currency}}">{{currency}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          <!-- cash on delivery -->
            <div class="uk-grid background_green_re" style="padding-bottom: 10px; padding-top: 10px; margin-top:5px">
              <div class="uk-width-1-1">
                <u><b>{{'opl_cod' | translate}}</b></u>
                <span uk-icon="warning" style="padding-top: 5px" [attr.uk-tooltip]="'Określa opłatę za przesyłkę pobraniową, wybranie wartości procentowej spwoduje obliczenie należnego procentu wartości pobrania'"></span>
              </div>
              <div class="uk-width-1-4">
                <label>
                  {{'opl_factor_type' | translate}}
                </label>
                <select [disabled]="changesDisabled" name="cod_account_factor_type" [(ngModel)]="form.cod_account_factor_type" class="uk-select">
                  <option value="lump_sum">{{'lump_sum' | translate}}</option>
                  <option value="percent">{{'percent' | translate}}</option>
                </select>
              </div>
              <div class="uk-width-1-4">
                <label>{{'opl_factor_value_' +  form.cod_account_factor_type| translate}}</label>
                <input [disabled]="changesDisabled" name="cod_account_factor" class="uk-input" type="number" step="0.01" [(ngModel)]="form.cod_account_factor">
              </div>
              <div class="uk-width-1-4" *ngIf="form.cod_account_factor_type === 'percent'">
                <label>{{'opl_factor_minimal' | translate}}</label>
                <input [disabled]="changesDisabled" name="cod_account_factor_minimal" class="uk-input" type="number" step="0.01" [(ngModel)]="form.cod_account_factor_minimal">
              </div>
              <div class="uk-width-1-4" *ngIf="form.cod_account_factor_type === 'percent'">
                <label>{{'opl_factor_maximal' | translate}}</label>
                <input [disabled]="changesDisabled" name="cod_account_factor_maximal" class="uk-input" type="number" step="0.01" [(ngModel)]="form.cod_account_factor_maximal">
              </div>
            </div>
          <!-- insurance -->
            <div class="uk-grid" style="margin-top: 5px">
              <div class="uk-width-1-1">
                <u><b>{{'insurance_value' | translate}}</b></u>
                <span uk-icon="warning" style="padding-top: 5px" [attr.uk-tooltip]="'Określa opłatę za ubezpieczenie, wybranie wartości procentowej spwoduje obliczenie należnego procentu wartości przesyłki'"></span>
              </div>
              <div class="uk-width-1-4">
                <label>{{'opl_factor_type' | translate}}</label>
                <select [disabled]="changesDisabled" name="insurance_account_factor_type" [(ngModel)]="form.insurance_account_factor_type" class="uk-select">
                  <option value="lump_sum">{{'lump_sum' | translate}}</option>
                  <option value="percent">{{'percent' | translate}}</option>
                </select>
              </div>
              <div class="uk-width-1-4">
                <label>{{'opl_factor_value_' +  form.insurance_account_factor_type| translate}}</label>
                <input [disabled]="changesDisabled" name="insurance_account_factor" class="uk-input" type="number" step="0.01" [(ngModel)]="form.insurance_account_factor">
              </div>
              <div class="uk-width-1-4" *ngIf="form.insurance_account_factor_type === 'percent'">
                <label>{{'opl_factor_minimal' | translate}}</label>
                <input [disabled]="changesDisabled" name="insurance_account_factor_minimal" class="uk-input" type="number" step="0.01" [(ngModel)]="form.insurance_account_factor_minimal">
              </div>
              <div class="uk-width-1-4" *ngIf="form.insurance_account_factor_type === 'percent'">
                <label>{{'opl_factor_maximal' | translate}}</label>
                <input [disabled]="changesDisabled" name="insurance_account_factor_maximal" class="uk-input" type="number" step="0.01" [(ngModel)]="form.insurance_account_factor_maximal">
              </div>
            </div>
          <!-- oversize -->
            <div class="uk-grid background_green_re" style="padding-bottom: 10px; padding-top: 10px; margin-top: 5px">
              <div class="uk-width-1-1">
                <b><u>{{'oversize' | translate}}</u></b>
                <span uk-icon="warning" style="padding-top: 5px" [attr.uk-tooltip]="'Określa opłatę za ponadnormatywne wymiary lub/i przekroczoną wagę maksymalną, wybranie wartości procentowej spwoduje obliczenie należnego procentu kwoty bazowej'"></span>
              </div>
              <div class="uk-width-1-4">
                <label>{{'opl_factor_type' | translate}}</label>
                <select [disabled]="changesDisabled" name="oversize_account_factor_type" [(ngModel)]="form.oversize_account_factor_type" class="uk-select">
                  <option value="lump_sum">{{'lump_sum' | translate}}</option>
                  <option value="percent">{{'percent' | translate}}</option>
                </select>
              </div>
              <div class="uk-width-1-4">
                <label>{{'opl_factor_value_' +  form.oversize_account_factor_type| translate}}</label>
                <input [disabled]="changesDisabled" name="oversize_account_factor" class="uk-input" type="number" step="0.01" [(ngModel)]="form.oversize_account_factor">
              </div>
              <div class="uk-width-1-4" *ngIf="form.oversize_account_factor_type === 'percent'">
                <label>{{'opl_factor_minimal' | translate}}</label>
                <input [disabled]="changesDisabled" name="oversize_account_factor_minimal" class="uk-input" type="number" step="0.01" [(ngModel)]="form.oversize_account_factor_minimal">
              </div>
              <div class="uk-width-1-4" *ngIf="form.oversize_account_factor_type === 'percent'">
                <label>{{'opl_factor_maximal' | translate}}</label>
                <input [disabled]="changesDisabled" name="oversize_account_factor_maximal" class="uk-input" type="number" step="0.01" [(ngModel)]="form.oversize_account_factor_maximal">
              </div>
            </div>
          <!-- overweight -->
            <div class="uk-grid" style="margin-top: 5px">
              <div class="uk-width-1-1">
                <b><u>{{'overweight' | translate}}</u></b>
                <span uk-icon="warning" style="padding-top: 5px" [attr.uk-tooltip]="'Określa opłatę za wagę rozliczeniową przekraczającą góną granicę najwyższego progu, wybranie wartości procentowej spwoduje obliczenie należnego procentu kwoty bazowej'"></span>
              </div>
              <div class="uk-width-1-4">
                <label>
                  {{'opl_factor_type' | translate}}
                    </label>
                <select [disabled]="changesDisabled" name="overweight_account_factor_type" [(ngModel)]="form.overweight_account_factor_type" class="uk-select">
                  <option value="lump_sum">{{'lump_sum' | translate}}</option>
                  <option value="percent">{{'percent' | translate}}</option>
                </select>
              </div>
              <div class="uk-width-1-4">
                <label>{{'opl_factor_value_' +  form.overweight_account_factor_type| translate}}</label>
                <input [disabled]="changesDisabled" name="overweight_account_factor" class="uk-input" type="number" step="0.01" [(ngModel)]="form.overweight_account_factor">
              </div>
              <div class="uk-width-1-4" *ngIf="form.overweight_account_factor_type === 'percent'">
                <label>{{'opl_factor_minimal' | translate}}</label>
                <input [disabled]="changesDisabled" name="overweight_account_factor_minimal" class="uk-input" type="number" step="0.01" [(ngModel)]="form.overweight_account_factor_minimal">
              </div>
              <div class="uk-width-1-4" *ngIf="form.overweight_account_factor_type === 'percent'">
                <label>{{'opl_factor_maximal' | translate}}</label>
                <input [disabled]="changesDisabled" name="overweight_account_factor_maximal" class="uk-input" type="number" step="0.01" [(ngModel)]="form.overweight_account_factor_maximal">
              </div>
            </div>
          <!-- multi pack -->
            <div class="uk-grid background_green_re" style="padding-bottom: 10px; padding-top: 10px; margin-top: 5px">
              <div class="uk-width-1-1">
                <b><u>{{'multipack' | translate}}</u></b>
                <span uk-icon="warning" style="padding-top: 5px" [attr.uk-tooltip]="'Określa opłatę za każdą kolejną przesyłkę w wielosztuku, wybranie wartości procentowej spwoduje obliczenie należnego procentu kwoty bazowej'"></span>
              </div>
              <div class="uk-width-1-4">
                <label>
                  {{'opl_factor_type' | translate}}
                </label>
                <select [disabled]="changesDisabled" name="multi_pack_account_factor_type" [(ngModel)]="form.multi_pack_account_factor_type" class="uk-select">
                  <option value="lump_sum">{{'lump_sum' | translate}}</option>
                  <option value="percent">{{'percent' | translate}}</option>
                </select>
              </div>
              <div class="uk-width-1-4">
                <label>{{'opl_factor_value_' +  form.multi_pack_account_factor_type| translate}}</label>
                <input [disabled]="changesDisabled" name="multi_pack_account_factor" class="uk-input" type="number" step="0.01" [(ngModel)]="form.multi_pack_account_factor">
              </div>
              <div class="uk-width-1-4" *ngIf="form.multi_pack_account_factor_type === 'percent'">
                <label>{{'opl_factor_minimal' | translate}}</label>
                <input [disabled]="changesDisabled" name="multi_pack_account_factor_minimal" class="uk-input" type="number" step="0.01" [(ngModel)]="form.multi_pack_account_factor_minimal">
              </div>
              <div class="uk-width-1-4" *ngIf="form.multi_pack_account_factor_type === 'percent'">
                <label>{{'opl_factor_maximal' | translate}}</label>
                <input [disabled]="changesDisabled" name="multi_pack_account_factor_maximal" class="uk-input" type="number" step="0.01" [(ngModel)]="form.multi_pack_account_factor_maximal">
              </div>
            </div>
          <!-- cod card payment -->
            <div class="uk-grid" style="padding-bottom: 10px; padding-top: 10px; margin-top: 5px">
              <div class="uk-width-1-1">
                <u><b>{{'cod_card_payment' | translate}}</b></u>
                <span uk-icon="warning" style="padding-top: 5px" [attr.uk-tooltip]="'Obecnie nie naliczany, wybranie wartości procentowej spwoduje obliczenie należnego procentu kwoty bazowej'"></span>
              </div>
              <div class="uk-width-1-4">
                <label>{{'opl_factor_type' | translate}}
                </label>
                <select [disabled]="changesDisabled" name="cod_card_payment_account_factor_type" [(ngModel)]="form.cod_card_payment_account_factor_type" class="uk-select">
                  <option value="lump_sum">{{'lump_sum' | translate}}</option>
                  <option value="percent">{{'percent' | translate}}</option>
                </select>
              </div>
              <div class="uk-width-1-4">
                <label>{{'opl_factor_value_' +  form.cod_card_payment_account_factor_type| translate}}</label>
                <input [disabled]="changesDisabled" name="cod_card_payment_account_factor" class="uk-input" type="number" step="0.01" [(ngModel)]="form.cod_card_payment_account_factor">
              </div>
              <div class="uk-width-1-4" *ngIf="form.cod_card_payment_account_factor_type === 'percent'">
                <label>{{'opl_factor_minimal' | translate}}</label>
                <input [disabled]="changesDisabled" name="cod_card_payment_account_factor_minimal" class="uk-input" type="number" step="0.01" [(ngModel)]="form.cod_card_payment_account_factor_minimal">
              </div>
              <div class="uk-width-1-4" *ngIf="form.cod_card_payment_account_factor_type === 'percent'">
                <label>{{'opl_factor_maximal' | translate}}</label>
                <input [disabled]="changesDisabled" name="cod_card_payment_account_factor_maximal" class="uk-input" type="number" step="0.01" [(ngModel)]="form.cod_card_payment_account_factor_maximal">
              </div>
            </div>
          <!-- unclaimed -->
            <div class="uk-grid background_green_re" style="padding-bottom: 10px; padding-top: 10px; margin-top: 5px">
              <div class="uk-width-1-1">
                <b><u>{{'unclaimed' | translate}}</u></b>
                <span uk-icon="warning" style="padding-top: 5px" [attr.uk-tooltip]="'Obecnie nie naliczany, wybranie wartości procentowej spwoduje obliczenie należnego procentu kwoty bazowej'"></span>
              </div>
              <div class="uk-width-1-4">
                <label>
                  {{'opl_factor_type' | translate}}
                 </label>
                <select [disabled]="changesDisabled" name="unclaimed_account_factor_type" [(ngModel)]="form.unclaimed_account_factor_type" class="uk-select">
                  <option value="lump_sum">{{'lump_sum' | translate}}</option>
                  <option value="percent">{{'percent' | translate}}</option>
                </select>
              </div>
              <div class="uk-width-1-4">
                <label>{{'opl_factor_value_' +  form.unclaimed_account_factor_type| translate}}</label>
                <input [disabled]="changesDisabled" name="unclaimed_account_factor" class="uk-input" type="number" step="0.01" [(ngModel)]="form.unclaimed_account_factor">
              </div>
              <div class="uk-width-1-4" *ngIf="form.unclaimed_account_factor_type === 'percent'">
                <label>{{'opl_factor_minimal' | translate}}</label>
                <input [disabled]="changesDisabled" name="unclaimed_account_factor_minimal" class="uk-input" type="number" step="0.01" [(ngModel)]="form.unclaimed_account_factor_minimal">
              </div>
              <div class="uk-width-1-4" *ngIf="form.unclaimed_account_factor_type === 'percent'">
                <label>{{'opl_factor_maximal' | translate}}</label>
                <input [disabled]="changesDisabled" name="unclaimed_account_factor_maximal" class="uk-input" type="number" step="0.01" [(ngModel)]="form.unclaimed_account_factor_maximal">
              </div>
            </div>
          <!-- collect from client -->
            <div class="uk-grid" style="margin-top: 5px; padding-bottom: 10px">
              <div class="uk-width-1-1">
                <b><u>
                  {{'collect_from_client' | translate}}
                  <span uk-icon="warning" style="padding-top: 5px" [attr.uk-tooltip]="'Naliczany zawsze gdy ustawiono wartość powyżej 0, wybranie wartości procentowej spwoduje obliczenie należnego procentu kwoty bazowej'"></span>
                </u></b>
              </div>
              <div class="uk-width-1-4">
                <label>{{'opl_factor_type' | translate}}</label>
                <select [disabled]="changesDisabled" name="collect_from_client_account_factor_type" [(ngModel)]="form.collect_from_client_account_factor_type" class="uk-select">
                  <option value="lump_sum">{{'lump_sum' | translate}}</option>
                  <option value="percent">{{'percent' | translate}}</option>
                </select>
              </div>
              <div class="uk-width-1-4">
                <label>{{'opl_factor_value_' +  form.collect_from_client_account_factor_type| translate}}</label>
                <input [disabled]="changesDisabled" name="collect_from_client_account_factor" class="uk-input" type="number" step="0.01" [(ngModel)]="form.collect_from_client_account_factor">
              </div>
              <div class="uk-width-1-4" *ngIf="form.collect_from_client_account_factor_type === 'percent'">
                <label>{{'opl_factor_minimal' | translate}}</label>
                <input [disabled]="changesDisabled" name="collect_from_client_account_factor_minimal" class="uk-input" type="number" step="0.01" [(ngModel)]="form.collect_from_client_account_factor_minimal">
              </div>
              <div class="uk-width-1-4" *ngIf="form.collect_from_client_account_factor_type === 'percent'">
                <label>{{'opl_factor_maximal' | translate}}</label>
                <input [disabled]="changesDisabled" name="collect_from_client_account_factor_maximal" class="uk-input" type="number" step="0.01" [(ngModel)]="form.collect_from_client_account_factor_maximal">
              </div>
            </div>
          <!-- non conveyable-->
            <div class="uk-grid background_green_re" style="padding-bottom: 10px; padding-top: 10px; margin-top: 5px;">
              <div class="uk-width-1-1">
                <b><u>{{'non_conveyable' | translate}}</u></b>
                <span uk-icon="warning" style="padding-top: 5px" [attr.uk-tooltip]="'Obecnie nie naliczany, wybranie wartości procentowej spwoduje obliczenie należnego procentu kwoty bazowej'"></span>
              </div>
              <div class="uk-width-1-4">
                <label>
                  {{'opl_factor_type' | translate}}
                </label>
                <select [disabled]="changesDisabled" name="non_conveyable_account_factor_type" [(ngModel)]="form.non_conveyable_account_factor_type" class="uk-select">
                  <option value="lump_sum">{{'lump_sum' | translate}}</option>
                  <option value="percent">{{'percent' | translate}}</option>
                </select>
              </div>
              <div class="uk-width-1-4">
                <label>{{'opl_factor_value_' +  form.non_conveyable_account_factor_type| translate}}</label>
                <input [disabled]="changesDisabled" name="non_conveyable_account_factor" class="uk-input" type="number" step="0.01" [(ngModel)]="form.non_conveyable_account_factor">
              </div>
              <div class="uk-width-1-4" *ngIf="form.non_conveyable_account_factor_type === 'percent'">
                <label>{{'opl_factor_minimal' | translate}}</label>
                <input [disabled]="changesDisabled" name="non_conveyable_account_factor_minimal" class="uk-input" type="number" step="0.01" [(ngModel)]="form.non_conveyable_account_factor_minimal">
              </div>
              <div class="uk-width-1-4" *ngIf="form.non_conveyable_account_factor_type === 'percent'">
                <label>{{'opl_factor_maximal' | translate}}</label>
                <input [disabled]="changesDisabled" name="non_conveyable_account_factor_maximal" class="uk-input" type="number" step="0.01" [(ngModel)]="form.non_conveyable_account_factor_maximal">
              </div>
            </div>
          <p class="uk-heading-divider">{{'base_amounts' | translate}}</p>
            <div *ngFor="let weightTreshold of form.weight_thresholds; let i = index">
              <div class="uk-grid">
                <div class="uk-width-1-5">
                  <label>{{'limiting_threshold' | translate}}</label>
                  <input [disabled]="changesDisabled" class="uk-input" name="weight_thresholds[{{i}}].limiting_threshold" [(ngModel)]="form.weight_thresholds[i].limiting_threshold" type="number" step="0.01">
                </div>
                <div class="uk-width-1-5">
                  <label>Kwota bazowa</label>
                  <input [disabled]="changesDisabled" class="uk-input" name="weight_thresholds[{{i}}].base_amount"  [(ngModel)]="form.weight_thresholds[i].base_amount" type="number" step="0.01">
                </div>
                <p class="nav-text" style="padding-top: 14px!important; padding-left: 10px !important;" *ngIf="!changesDisabled">
                  <button class="uk-button uk-margin-remove-right  uk-button-danger padding-horizontal-small button-opt " (click)="deleteWeightThreshold(i)" [attr.uk-tooltip]="'Usuń przedział cenowy'">
                    <span uk-icon="ban"></span>
                  </button>
                </p>
             </div>
            </div>
          <p class="uk-heading-divider"></p>
            <div class="uk-grid" *ngIf="!changesDisabled">
              <div class="uk-width-1-3">
                <a (click)="addWeightThreshold()" title="Utwórz nowy przedział wagowy" class="uk-button uk-button-primary uk-width-1-1" style="margin-left: 0!important;">
                  Dodaj przedział wagowy
                </a>
              </div>
              <div class="uk-width-1-3 uk-align-left">
                <button class="uk-button uk-button-primary uk-width-1-1">Zapisz</button>
              </div>
            </div>
        </form>
    </div>
  </div>
</div>


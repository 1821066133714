import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastsService} from '../../../services/toasts.service';
import {StatusesService} from '../../../services/statuses/statuses.service';
import {StatusModel} from '../../../models/status.model';
import {StatusTypeService} from "../../../services/status-type/status-type.service";
declare var UIkit: any;
@Component({
    selector: 'app-status',
    templateUrl: './status.component.html',
    styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {
    status: StatusModel = new StatusModel();
    id = null;

    constructor(
      private statusService: StatusTypeService,
      private statusesService: StatusesService,
                private router: Router,
                private route: ActivatedRoute,
                private toastsService: ToastsService,
    ) {
    }

    ngOnInit() {
      try {
        this.id = this.route.snapshot.paramMap.get('id');
        this.statusService.show(this.id).subscribe(
          (s: StatusModel) => {
            this.status = s;
          }
        );
      }
      catch (e){

      }
    }

    save(form) {
        if (this.id === null) {
          this.statusService.store(form.value).subscribe(
            (response: any) => {
              this.toastsService.showMessage('status_created', 's');
              this.router.navigateByUrl('/statuses/status/' + response.body.id)
            }
          )
        }
        else {
          this.statusService.update(form.value).subscribe(
            (s: any) => {
              this.toastsService.showMessage('status_updated', 's');
            }
          );
        }
    }

    async onStatus() {
        const promised = await this.toastsService.showConfirmModal();
        if (!promised) {
            return;
        }
        this.statusService.delete(this.id).subscribe(
            (s: StatusModel) => {
                this.toastsService.showMessage('status_removed', 's');
                this.router.navigateByUrl('/statuses');
            }
        );
    }
}

import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExternalInboundService {
  private apiurl = '';
  constructor(private http: HttpClient, private router: Router) {
    this.apiurl = environment.api + 'app/v1/external_inbound';
  }

  scan(form) {
    const url = this.apiurl;
    return this.http.post(url, form,  {observe: 'response'});
  }

  couriers() {
    const url = this.apiurl + '/couriers';
    return this.http.get(url,);
  }

  show(id) {
    const url = this.apiurl + '/' + id;
    return this.http.get(url);
  }

  update(form) {
    const url = this.apiurl;
    return this.http.patch(url, form, {observe:'response'});
  }
}

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CredentialsService {

  private apiurl = '';

  constructor(private http: HttpClient, private router: Router) {
    this.apiurl = environment.api;
  }
  store(form){
    const url = this.apiurl + 'credential';
    return this.http.post(url, form, {observe: 'response'});
  }
  update(form) {
    const url = this.apiurl + 'credential';
    return this.http.put(url, form, {observe: 'response'});
  }
  delete(id){
    const url = this.apiurl + 'credentials';
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id
      },
    };
    return this.http.delete(url, options);
  }
  reuse(id){
    const url = this.apiurl + 'credential/reuse';
    return this.http.put(url, {id});
  }
  check(form) {
    const url = this.apiurl + 'credential/check';
    return this.http.put(url, form);
  }
  getForClient(id){
    const url = this.apiurl + 'client/' + id + '/credentials';
    return this.http.get(url);
  }
  getMains(){
    const url = this.apiurl + 'credentials';
    return this.http.get(url);
  }
  show(id){
    const url = this.apiurl + 'credential/' + id;
    return this.http.get(url);
  }
  getTypes(){
    const url = this.apiurl + 'credential/types';
    return this.http.get(url);
  }
  getProtectedAssigns(){
    const url = this.apiurl + 'credential/protected/assigns';

    return this.http.get(url);
  }
  getCouriers(){
    const url = this.apiurl + 'couriers';
    return this.http.get(url);
  }
  checkIsClientHasCredentailByAssign(idClient, assign){
    const url = this.apiurl + 'client/' + idClient + '/credentials/type/' + assign;
    return this.http.get(url);
  }
  pinMainCredentials(idClient){
    const url = this.apiurl + 'credential/main';
    return this.http.post(url, {
      id_client: idClient
    });
  }
  unpinMainCredentials(idClient){
    const url = this.apiurl + 'credential/main';
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id_client: idClient
      },
    };
    return this.http.delete(url, options);
  }

  hasDefaultCredentials(idClient) {
    const url = this.apiurl + 'app/v1/client/' + idClient + '/hasDefaultCredentialsId';

    return this.http.get(url);
  }
}

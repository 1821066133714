import {Component, OnDestroy, OnInit} from '@angular/core';
import {ParcelModel} from '../../../models/parcel.model';
import {ParcelsService} from '../../../services/parcels/parcels.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastsService} from '../../../services/toasts.service';
import {ClientsService} from '../../../services/clients/clients.service';
import {environment} from '../../../../environments/environment';
import {AuthService} from '../../../services/auth/auth.service';
import {UserModel} from '../../../models/user.model';
import {StatusModel} from '../../../models/status.model';
import {ParcelService} from "../../../services/parcel/parcel.service";
import {ShipmentService} from "../../../services/shipment/shipment.service";
import {CalendarDaysService} from "../../../services/calendar_days/calendar-days.service";

declare var UIkit: any;

@Component({
  selector: 'app-parcel',
  templateUrl: './parcel.component.html',
  styleUrls: ['./parcel.component.scss']
})
export class ParcelComponent implements OnInit, OnDestroy {
  parcel: ParcelModel = new ParcelModel();
  statuses = [];
  id = null;
  cities = [];
  clients = [];
  destinationCityCode = null;
  destinationCity = null;
  idClient = null;
  client = null;
  window = window;
  api = '';
  token = this.authService.getToken;
  comment = '';
  streets = [];
  hasStreetTypes = false;
  isCorrect = false;
  generateProgressAlert = false;
  where = '';
  when = '2020-07-04 10:11:11';
  statusTypes = [
    {id: '', displayed_name: ''},
  ];
  statusModel = {
    id: '',
    id_status_type: null,
    when: '',
    where: 'TYCHY',
  };

  displayPrice = false;
  hideOptions = false;

  public userModel: UserModel = new UserModel();

  constructor(
    private shipmentService: ShipmentService,
    private parcelsService: ParcelsService,
    private parcelService: ParcelService,
    private clientsService: ClientsService,
    private router: Router,
    private route: ActivatedRoute,
    private toastsService: ToastsService,
    private authService: AuthService,
    private calendarDaysService: CalendarDaysService
  ) {
    this.api = environment.api;
  }


  country(parcel): string {
    if (null !== parcel.buffer_number && parcel.out_number == '-' && parcel.in_number == '-') {
      return '';
    }

    if (parcel.return_parcel) {
      if (4 === parcel.id_in_courier) {
        return 'Węgry';
      }
      else if (5 === parcel.id_in_courier) {
        return 'Bułgaria';
      }

      return 'Rumunia';
    }

    if (4 === parcel.id_out_courier) {
      return 'Węgry';
    }
    else if (5 === parcel.id_out_courier) {
      return 'Bułgaria';
    }

    return 'Rumunia';
  }

  flagUrl(parcel): string {
    if (null !== parcel.buffer_number && parcel.out_number == '-' && parcel.in_number == '-') {
      return this.api + 'app/v1/asset/icon/danger';
    }

    if (parcel.return_parcel) {
      if (4 === parcel.id_in_courier) {
        return this.api + 'app/v1/asset/flag/HU';
      }
      else if (5 === parcel.id_in_courier) {
        return this.api + 'app/v1/asset/flag/BG';
      }

      return this.api + 'app/v1/asset/flag/RO';
    }

    if (4 === parcel.id_out_courier) {
      return this.api + 'app/v1/asset/flag/HU';
    }
    else if (5 === parcel.id_out_courier) {
      return this.api + 'app/v1/asset/flag/BG';
    }

    return this.api + 'app/v1/asset/flag/RO';
  }

  returnLabelAllowed = false;
  getStatuses() {
    this.parcelsService.getParcel(this.id).subscribe(
      (s: ParcelModel) => {
        this.parcel = s;
        this.hideOptions = !(s.id_out_courier == '2' || s.id_in_courier == '2')
        if (['web', 'api_v2', 'baselinker'].includes(this.parcel.creator)) {
          this.hideOptions = false;
        }
        this.returnLabelAllowed = !(s.return_parcel == 1 && s.in_number == '-');
        if (this.returnLabelAllowed) {
          this.calendarDaysService.findNextWorkingDay().subscribe(
            (response: any) => {
              this.requestPickupForm.pickupDate = response.date;
            }
          );
        }

        let phone_number = this.parcel.recipient_phone_number_1;
        if (this.parcel.phone_prefix_1 !== null) {
          phone_number = this.parcel.phone_prefix_1 + this.parcel.recipient_phone_number_1;
        }
        this.parcel.recipient_phone_number = phone_number;

        this.statusModel.id = s.id;
        this.setTime();
        this.getStatusHistory(s.internal_number);
        this.requestPickupForm.id = s.shipment_id;
      }
    );
    this.parcelsService.getStatusTypes(1).subscribe(
      (response: any) => {
        this.statusTypes = response;
        if (response.length > 0) {
          this.statusModel.id_status_type = response[0].id;
        }
      }
    );
  }

  getStatusHistory(internalNumber) {
    this.parcelsService.getParcelStatuses(internalNumber).subscribe(
      (response: any) => {
        response = response.sort((a, b) => {
          return (a.when < b.when) ? 1 : ((a.when > b.when) ? -1 : 0);
        });
        this.statuses = response;
      }
    );
  }

  setTime() {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());
    const hour = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    this.statusModel.when = `${year}-${month}-${day}T${hour}:${minutes}`;
  }

  ngOnInit() {
    this.authService.isLogged.subscribe((userModel) => {
      this.userModel = userModel;
    });
    this.route.params.subscribe(
      (params) => {
        if (params != null) {
          if (this.id !== params.id) {
            this.id = params.id;
            this.statusModel.id = this.parcel.id;
            this.getStatuses();
          }
        }
      }
    );
    this.parcelsService.askShowPrice().subscribe(
      (response: any) => {
        this.displayPrice = response.show;
      }
    );

    document.querySelector('#recipient_phone_number').addEventListener('keyup', (e) => {
      e.stopPropagation();
    }, false);
  }

  ngOnDestroy() {
    document.querySelector('#recipient_phone_number').removeEventListener('keyup', () => {
    });
  }

  onRemoveParcel() {
    this.parcelsService.removeParcel(this.parcel.id).subscribe(
      (response: any) => {
        this.toastsService.showMessage('parcel_removed', 's');
        this.router.navigateByUrl('/parcels');
      }
    );
  }

  onCancelParcel() {
    this.parcelsService.cancelParcel(this.parcel.id, this.comment).subscribe(
      (response: any) => {
        this.toastsService.showMessage('parcel_canceled', 's');
        this.router.navigateByUrl('/parcels');
        UIkit.modal('#cancel').hide();
        // document.getElementById('cancel').remove();
      }
    );
  }

  onEditParcel(form): void {
    if (this.isCorrect) {
      form.value.out_service = this.parcel.out_service;
      this.parcelsService.updateParcel(form.value).subscribe(
        (response: any) => {
          this.toastsService.showMessage('parcel_updated', 's');
          this.parcelsService.getParcel(this.id).subscribe(
            (s: ParcelModel) => {
              this.parcel = s;
              let phone_number = this.parcel.recipient_phone_number_1;
              if (this.parcel.phone_prefix_1 !== null) {
                phone_number = this.parcel.phone_prefix_1 + this.parcel.recipient_phone_number_1;
              }
              this.parcel.recipient_phone_number = phone_number;
            });
        }
      );
    } else {
      this.onCheckParsell(form);
    }
  }

  openModal(name) {
    const modal = document.getElementsByClassName(name + '-modal');
    if (modal.length > 1) {
      modal[1].remove();
    }

    UIkit.modal('#' + name).show();
  }

  onStatusChange() {
    this.parcelsService.setStatus(this.statusModel).subscribe(
      (response: any) => {
        this.toastsService.showMessage('status_added', 's');
        this.statusModel.id_status_type = null;
        this.statusModel.when = '';
        this.statusModel.where = 'TYCHY';
        this.getStatuses();
        UIkit.modal('#changeStatus').hide();
      }
    );
  }

  onCheckParsell(form): void {
    this.parcelsService.checkParcel(form.value).subscribe(
      (s) => {
        this.toastsService.showMessage('parcel_is_correct', 's');
        this.isCorrect = true;
      }
    );
  }

  searchCity(input): void {
    this.destinationCityCode = '';
    this.parcelsService.getCity(input.value).subscribe(
      (success: any) => {
        this.cities = success;
        this.hasStreetTypes = false;
      }
    );
  }

  searchStreet(cityCode, input): void {
    if (input.value === '' || input.value == null) {
      this.streets = [];
      return;
    }
    this.parcelsService.getStreet(input, cityCode).subscribe(
      (success: any) => {
        this.streets = success;
        if (success.length > 0) {
          this.hasStreetTypes = true;
        }
      }, error => {
        this.streets = [];
      }
    );
  }

  setCity(city): void {
    this.cities = [];
    this.parcel.destination_city_code = city.id;
    this.parcel.destination_city = city.name_eng;
    this.parcel.destination_post_code = city.post_code;
  }

  setStreet(street): void {
    this.streets = [];
    this.parcel.destination_street = street.nameEn;
    this.parcel.destination_street_type = street.typeEn;
    if (street.typeEn === '' || street.typeEn == null) {
      this.hasStreetTypes = false;
    }
  }

  setClient(city): void {
    this.clients = [];
    this.idClient = city.id;
    this.client = city.name;
  }

  searchClient(input): void {
    this.clientsService.searchClient(input.value).subscribe(
      (response: Array<any>) => {
        this.clients = response;
      }
    );
  }

  makeParcel(): void {
    this.parcelsService.makeParcelRequest({id: this.parcel.id}).subscribe(
      (success: any) => {
        this.toastsService.showMessage('parcel_made', 's');
        this.parcel.out_number = 'new';
      }
    );
  }

  downloadFile(url, name, paperSize) {
    if (this.parcel.return_parcel == 1) {
      this.parcelService.findLabelMetadata(this.parcel.internal_number, paperSize).subscribe(
        (metadata: any) => {
          var time = new Date().getTime() - new Date(metadata.created_at).getTime();
          if (time < 30000) {
            this.toastsService.showMessage('Twoja etykieta nie jest jeszcze gotowa', 'e')
            return;
          }
          if (this.parcel.return_parcel) {
            if (metadata.fantom && !metadata.detailed) {
              this.toastsService.showMessage('Twoja etykieta nie jest jeszcze gotowa', 'e')
              return;
            }
          }

          this.getFile(url, name, paperSize);
        },
        error => {
          switch (error.status) {
            case 404:
              this.toastsService.showMessage('Twoja etykieta nie jest jeszcze gotowa', 'e');
              break;
          }
        }
      );
    }
    else {
      this.getFile(url, name, paperSize)
    }
  }

  getFile(url, name, paperSize) {
    const randomToken = Math.round(Math.random() * 1000);
    url += `?sc=${randomToken}`;
    this.generateProgressAlert = true;
    this.parcelsService.getFile(url, name);
  }

  addNextParcel() {
    this.generateProgressAlert = false;
    document.getElementById('label').remove();
    this.router.navigateByUrl('/parcel/new');
  }

  async onStatus(idStatus) {
    const promised = await this.toastsService.showConfirmModal();
    if (!promised) {
      return;
    }
    this.parcelsService.deleteStatus(idStatus).subscribe(
      (s: StatusModel) => {
        this.toastsService.showMessage('status_removed', 's');
        this.getStatuses();
      }
    );
  }

  copyTrackingUrl(id) {
    navigator.clipboard.writeText(window.location.protocol + '//' + window.location.host + `/tracking/${id}`);
    this.toastsService.showMessage('link_was_copied', 's');
  }


  goToLastProblem() {
    this.parcelsService.getProblemId(this.parcel.id).subscribe(
      (response: any) => {
        this.goToProblem(response.id);
      }
    );
  }

  goToProblem(idProblem) {
    if (idProblem !== null) {
      window.open('/problem/' + idProblem, '_blank');
    }
  }

  //adopt buffer parcel to client
  openAdoptBufferParcelToClientModal() {
    UIkit.modal('#adoptBufferParcelToClientModal').show();
  }

  setAdoptBufferParcelToClientClientData(client): void {
    this.adoptBufferParcelToClientClientList = [];
    this.adoptBufferParcelToClientForm.id_client = client.id;
    this.adoptBufferParcelToClientClientName = client.name;
  }

  adoptBufferParcelToClientClientList = [];

  searchClientForAdoptBufferParcelToClientForm(input): void {
    this.clientsService.searchClient(input.value).subscribe(
      (response: Array<any>) => {
        this.adoptBufferParcelToClientClientList = response;
      }
    );
  }

  adoptBufferParcelToClientClientName = null;

  adoptBufferParcelToClientForm = {
    id_parcel: this.id,
    id_client: null,
    is_emag_parcel: 1,
  }

  submitAdoptBufferParcelToClientForm() {
    this.adoptBufferParcelToClientForm.id_parcel = this.parcel.id;
    this.parcelService.adoptBufferParcelToClient(this.adoptBufferParcelToClientForm).subscribe(
      (response : any) => {
        this.toastsService.showMessage('Zapisano klienta dla przesyłki buforowej', 's');
        this.toastsService.showMessage('Za chwilę nastąpi próba przekierowania do nowego widoku przesyłki', 's');
        UIkit.modal('#adoptBufferParcelToClientModal').hide();
        setTimeout(
          () => {
            this.parcelService.findByIdentifier(
              this.parcel.id,
              ['internal_number']
            ).subscribe((internalNumberResponse: any) => {
              window.location.href = '/parcel/' + internalNumberResponse.internal_number
            })
          }, 7000
        );
      },
      (error: any) => {
        UIkit.modal('#adoptBufferParcelToClientModal').hide();
        this.toastsService.showMessage('Wystąpił nieznany błąd', 'e');
      },
    );
  }

  //adopt unprocessable parcel client
  openAdoptUnprocessableParcelToClientModal() {
    UIkit.modal('#adoptUnprocessableParcelToClientModal').show();
  }

  setAdoptUnprocessableParcelToClientClientData(client): void {
    this.adoptUnprocessableParcelToClientClientList = [];
    this.adoptUnprocessableParcelToClientForm.id_client = client.id;
    this.adoptUnprocessableParcelToClientClientName = client.name;
  }

  adoptUnprocessableParcelToClientClientList = [];

  searchClientForAdoptUnprocessableParcelToClientForm(input): void {
    this.clientsService.searchClient(input.value).subscribe(
      (response: Array<any>) => {
        this.adoptUnprocessableParcelToClientClientList = response;
      }
    );
  }

  adoptUnprocessableParcelToClientClientName = null;

  adoptUnprocessableParcelToClientForm = {
    id_parcel: this.id,
    id_client: null,
    is_emag_parcel: 1,
    return: 0,
  }

  submitAdoptUnprocessableParcelToClientForm() {
    this.adoptUnprocessableParcelToClientForm.id_parcel = this.parcel.id;
    console.log(this.adoptUnprocessableParcelToClientForm);
    this.parcelService.adoptUnprocessableParcelToClient(this.adoptUnprocessableParcelToClientForm).subscribe(
      (response : any) => {
        this.toastsService.showMessage('Zapisano klienta dla przesyłki nieprocesowalnej', 's');
        this.toastsService.showMessage('Za chwilę nastąpi próba przekierowania do nowego widoku przesyłki', 's');
        UIkit.modal('#adoptUnprocessableParcelToClientModal').hide();

        setTimeout(
          () => {
            this.parcelService.findByIdentifier(
              this.parcel.id,
              ['internal_number']
            ).subscribe((internalNumberResponse: any) => {
              window.location.href = '/parcel/' + internalNumberResponse.internal_number
            })
          }, 7000
        );
      },
      (error: any) => {
        UIkit.modal('#adoptUnprocessableParcelToClientModal').hide();
        this.toastsService.showMessage('Wystąpił nieznany błąd', 'e');
      },
    );
  }

//mark as remove tool
openConfirmMarkAsRemovedModal() {
this.showConfirmMarkAsDeletedModal().then((confirmed)=> {
  if (confirmed) {
    this.parcelService.delete(this.parcel.id, 'mark_only').subscribe(
      (response: any) => {
        this.toastsService.showMessage('Przesyłka została oznacozna jako usunięta!', 's');
        this.getStatusHistory(this.parcel.internal_number);
      }
    )
  }
});
}

async showConfirmMarkAsDeletedModal(): Promise<boolean> {
return new Promise ( (resolve, reject) => {
  UIkit.modal.confirm(
    '<h3 style="color: #bd2130">Czy na pewno chcesz oznaczyć tę przesyłkę jako usuniętą?</h3>',
    {labels: {cancel: 'Nie', ok: 'Tak'}}
  ).then(() => {
    resolve(true);
  }, () => {
    resolve(false);
  });
} );
}

requestPickupForm = {
  id: null, //this is shipment id
  pickupDate: null,
  maxPickupHour: '18:00'
};

submitRequestPickup() {
this.shipmentService.requestReturnPickup(this.requestPickupForm).subscribe(
  (response: any) => {
    UIkit.modal('#requestPickupModal').hide();
    this.toastsService.showMessage(
      'Kurier odbierze przesyłkę po ' + response.body.from,
      's');
    this.getStatuses();
  },
  (e) => {
    this.toastsService.showMessage('Nie można zamówić kuriara w wybranym terminie!', 'e');
  }
);
}
}

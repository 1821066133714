import { Component, OnInit } from '@angular/core';
import {ClientModel} from '../../../models/client.model';
import {ClientsService} from '../../../services/clients/clients.service';

@Component({
  selector: 'app-client-company-data',
  templateUrl: './client-company-data.component.html',
  styleUrls: ['./client-company-data.component.scss']
})
export class ClientCompanyDataComponent implements OnInit {

  client = new ClientModel();
  constructor(private clientsService: ClientsService) { }

  ngOnInit() {
    this.showClient();
  }

  showClient() {
    this.clientsService.getMyData().subscribe(
        (s: ClientModel) => {
          this.client = s;
        }
    );
  }

}

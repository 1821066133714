<div class="uk-grid">
    <div class="uk-grid uk-width-1-1">
        <div class="uk-width-1-1 navbar uk-card-default">
          <p class="nav-text">
            <span *ngIf="clientId">{{client.name}}: Dodaj dane uwierzytelniające zewnętrzengo API</span>
            <button class="uk-button uk-button-primary padding-horizontal-small button-opt " [routerLink]="'/client/'+clientId+'/card/external_api_credentials'" [attr.uk-tooltip]="'Wróć'"><span uk-icon="sign-out"></span></button>
          </p>
        </div>
    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-left uk-margin-medium-top uk-width-1-1">
        <div class="mobile-overflow">
            <form #credentialsForm="ngForm" (ngSubmit)="onCreateCredentials(credentialsForm)">
                <div class="uk-grid uk-width-1-1">
                    <div class=" uk-width-1-2@m" *ngIf="model.type === credentialsType">
                        <label [class.required]="model.type === credentialsType">Nazwa użytkownka</label>
                        <input [required]="model.type === credentialsType" class="uk-input" type="text" name="username" [(ngModel)]="model.username" ngModel [(ngModel)]="generatedSlug" > <!-- [(ngModel)]="model.username" -->
                    </div>
                    <div class=" uk-width-1-2@m">
                        <label class="">Typ</label>
                        <select class="uk-select"  name="type" [(ngModel)]="model.type" required (ngModelChange)="checkType()">
                            <option disabled selected>Wybierz typ uprawnień</option>
                            <option value="baselinker">baselinker</option>
                            <option value="api_v2">API v2</option>
                        </select>
                    </div>
                    <div class=" uk-width-1-2@m" *ngIf="model.type === credentialsType">
                        <label [class.required]="model.type === credentialsType">Hasło</label>
                        <input [required]="model.type === credentialsType" class="uk-input" type="text" name="password" #password="ngModel" ngModel [(ngModel)]="model.password">
                    </div>
                    <div class=" uk-width-1-2@m" *ngIf="model.type === 'another'">
                        <label>IP</label>
                        <input class="uk-input" type="text" name="ip" [(ngModel)]="model.ip">
                    </div>
                    <div class=" uk-width-1-2@m" *ngIf="model.type === 'another'">
                        <label>Token</label>
                        <input class="uk-input" type="text" name="token" [(ngModel)]="model.token">
                    </div>
                </div>
                <div class="uk-margin">
                    <button type="button" *ngIf="model.type === credentialsType" class="uk-button uk-button-primary uk-margin-small-right" (click)="onConvertToSlug(client.name)">Generuj Nazwę</button>
                    <button  type="button"*ngIf="model.type === credentialsType" class="uk-button uk-button-primary uk-margin-small-right" (click)="onGeneratePassword()">Generuj Hasło</button>
                    <button type="submit" class="uk-button uk-button-primary uk-margin-small-right" *ngIf="model.type" [disabled]="credentialsForm.form.invalid">{{'add' |translate}}</button>
                </div>
            </form>

        </div>
    </div>
</div>

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    private apiurl = '';

    constructor(private http: HttpClient, private router: Router) {
        this.apiurl = environment.api;
    }

    createUser(form) {
        const url = this.apiurl + 'user';
        return this.http.post(url, form);
    }

    getUser(id) {
        const url = this.apiurl + 'user/' + id;
        return this.http.get(url);
    }

    updateUser(form) {
        const url = this.apiurl + 'user';
        return this.http.put(url, form);
    }

    removeUser(id) {
        const url = this.apiurl + 'user';
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: {
                id
            },
        };
        return this.http.delete(url, options);
    }
}

<div class="uk-grid">
  <div class="uk-width-1-1 navbar">

    <p class="nav-text">
      {{'find_your_postal_code'|translate}}
      <button
        class="uk-button uk-button-primary padding-horizontal-small uk-float-right uk-margin-small-top uk-margin-small-right"
        (click)="onChangeLanguage('pl')"
        [attr.uk-tooltip]="'PL'"><img src="/assets/img/poland.png" alt="Poland" style="width: 20px;"></button>
      <button class="uk-button uk-button-primary padding-horizontal-small uk-float-right uk-margin-small-top "
              (click)="onChangeLanguage('ro')"
              [attr.uk-tooltip]="'RO'"><img src="/assets/img/romania.png" alt="Romania" style="width: 20px;"></button>
    </p>

    <!--
        <select class="uk-select" (change)="onChangeLanguage()">
          <option value="pl" [selected]="lang=='pl'">PL</option>
          <option value="ro" [selected]="lang=='ro'">RO</option>
        </select>
    -->

  </div>

  <div class="uk-width-1-1 uk-margin-medium-top">
    <div class="uk-container uk-margin-auto">
      <div class="uk-first-column uk-margin-top" style="margin: 0 auto; max-width: 800px">
        <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right">
          <div class="uk-text-center uk-margin-bottom">
            <img src="/assets/img/re-logo.png" class="img-fluid" routerLink="/">
          </div>
            <div>
              <label>{{'search_town' | translate}}</label>
              <input name="searchText" class="uk-input" type="text" required [(ngModel)]="searchText" (keyup)="searchTown()" #searchTextField autocomplete="off">
              <div style="position: absolute; max-height: 200px;" class="uk-overflow-auto">
                <ul class="uk-list uk-list-divider uk-position-relative uk-margin-remove-top" style="background-color: white; width: 100%;" [style.width]="searchTextField.clientWidth + 'px'" *ngIf="options.length > 0">
                  <li *ngFor="let option of options" (click)="selectOption(option)" style="padding-left: 10px!important;">{{option.name}} ({{option.voivodeship}}, {{option.municipality}})</li>
                </ul>
            </div>
          </div>

          <div class="uk-alert uk-alert-danger" *ngIf="error">
            {{"town_find_error" | translate}}
          </div>

          <div *ngIf="null !== selectedOption" class="mt-10">
            <label>{{'your_postal_code' | translate}}</label>
            <input name="post_code" class="uk-input" type="text" required [(ngModel)]="selectedOption.postal_code" disabled>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


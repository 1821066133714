<div class="uk-grid">
  <div class="uk-width-1-1 navbar">
    <p class="nav-text">
      <span *ngIf="id===null">Tworzenie nowego rodzaju nieprawidłowości</span>
      <span *ngIf="id!==null">Rodzaj nieprawidłowości: {{problem.name}}</span>
      <button class="uk-button uk-button-primary padding-horizontal-small button-opt " [attr.uk-tooltip]="'Wróć'" [routerLink]="'/problem-rules'"><span uk-icon="sign-out"></span></button>
      <button *ngIf="problem.system==0 && id!==null" class="uk-button uk-button-danger padding-horizontal-small uk-margin-remove-right button-opt " (click)="delete()" [attr.uk-tooltip]="'Usuń definicję nieprawidłowości'"><span uk-icon="ban"></span></button>
    </p>
  </div>

  <div class="uk-width-1-1 inner-shadow">

    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
      <div class="mobile-overflow">
          <div class="uk-grid">
            <div class="uk-width-1-2 uk-margin-small-top">
              <label>Nazwa</label>
              <input type="text" class="uk-input" [(ngModel)]="problem.name" name="name">
            </div>

            <div class="uk-width-1-6 uk-margin-small-top" *ngIf="problem.variant!='none'">
              <label>Kryterium</label>
              <select [(ngModel)]="problem.variant" class="uk-select">
                <option value="days">Dni</option>
                <option value="hours">Godziny</option>
              </select>
            </div>
            <div *ngIf="problem.variant=='hours'" class="uk-width-1-3 uk-margin-small-top">
              <label>Ilość godzin liczona od stanu bazowego</label>
              <input type="number" min="1" max="720" step="1" class="uk-input" [(ngModel)]="problem.period" name="period">
            </div>
            <div *ngIf="problem.variant=='days'" class="uk-width-1-6 uk-margin-small-top">
              <label title="Ilość dni liczona od stanu bazowego">Ilość dni</label>
              <input type="number" min="0" max="40" step="1" class="uk-input" [(ngModel)]="problem.period" name="period">
            </div>
            <div *ngIf="problem.variant=='days'" class="uk-width-1-6 uk-margin-small-top">
              <label>Godzina graniczna(PL)</label>
              <input type="time"  class="uk-input" [(ngModel)]="problem.alert_at" name="alert_at">
            </div>

            <div class="uk-width-1-2 uk-margin-small-top" *ngIf="id!==null && problem.variant=='none'">
              <label>Kod</label>
              <input type="text" class="uk-input" [(ngModel)]="problem.code" name="code" disabled>
            </div>

            <div class="uk-width-1-2 uk-margin-small-top">
              <label>Stan bazowy</label>
              <select class="uk-select" name="id_base_status" [(ngModel)]="problem.id_base_status" [disabled]="id!==null">
                <option *ngIf="problem.id_base_status===null">Nie wybrano</option>
                <option *ngFor="let statusType of statusTypes" value="{{statusType.id}}">
                  {{statusType.displayed_name}}
                </option>
              </select>
            </div>
            <div class="uk-width-1-2 uk-margin-small-top">
              <label>Stan oczekiwany</label>
              <select class="uk-select" name="id_next_status" [(ngModel)]="problem.id_next_status" [disabled]="id!==null">
                <option *ngIf="problem.id_next_status===null">Nie wybrano</option>
                <option *ngFor="let statusType of statusTypes" value="{{statusType.id}}">
                  {{statusType.displayed_name}}
                </option>
              </select>
            </div>
            <div class="uk-width-1-2 uk-margin-small-top">
              <label>Automatycznie wyślij powiadomienie</label>
              <select class="uk-select" name="auto_send_email" [(ngModel)]="problem.auto_send_email">
                <option value="0">Nie</option>
                <option value="1">Tak</option>
              </select>
            </div>
            <div class="uk-width-1-2 uk-margin-small-top" *ngIf="problem.auto_send_email==1">
              <label>Odbiorcy wiadomości <span uk-icon="info" title="Aby dodać wielu odbiorców należy rozdzielić adresy email średnikiem (bez spacji)."></span></label>
              <input type="text" name="auto_mail_addressats" class="uk-input" [(ngModel)]="problem.auto_mail_addressats">
            </div>
            <div class="uk-width-1-2 uk-margin-small-top" *ngIf="problem.auto_send_email==1">
              <label>Ukryta kopia do <span uk-icon="info" title="Aby dodać wielu odbiorców należy rozdzielić adresy email średnikiem (bez spacji)."></span></label>
              <input type="text" name="auto_mail_bccs" class="uk-input" [(ngModel)]="problem.auto_mail_bccs">
            </div>

            <div class="uk-width-1-2 uk-margin-small-top" *ngIf="problem.auto_send_email==1">
              <label>Odpowiedź do <span uk-icon="info" title="W treści oraz tytule maila możliwe jest wykorzystanie następujacych zmiennych: !!internal_number!! - numer przesyłki RE, !!out_number!! - numer wyjściowy przesyłki, !!requested_at!! - data zarejestrowania przesyłki u kuriera, !!delivered_at!! - data doręczenia"></span></label>
              <input type="text" name="auto_mail_reply_to" class="uk-input" [(ngModel)]="problem.auto_mail_reply_to">
            </div>

            <div class="uk-width-1-1 uk-margin-small-top" *ngIf="problem.auto_send_email==1">
              <label>Tytuł wiadomości <span uk-icon="info" title="W treści oraz tytule maila możliwe jest wykorzystanie następujacych zmiennych: !!internal_number!! - numer przesyłki RE, !!out_number!! - numer wyjściowy przesyłki, !!requested_at!! - data zarejestrowania przesyłki u kuriera, !!delivered_at!! - data doręczenia"></span></label>

              <input type="text" name="auto_mail_subject" class="uk-input" [(ngModel)]="problem.auto_mail_subject">
            </div>
            <div class="uk-width-1-1 uk-margin-small-top" *ngIf="problem.auto_send_email==1">
              <label>Treść wiadomości <span uk-icon="info" title="W treści oraz tytule maila możliwe jest wykorzystanie następujacych zmiennych: !!internal_number!! - numer przesyłki RE, !!out_number!! - numer wyjściowy przesyłki, !!requested_at!! - data zarejestrowania przesyłki u kuriera, !!delivered_at!! - data doręczenia"></span></label>
              <textarea type="text" class="uk-textarea" cols="4" name="auto_mail_body" [(ngModel)]="problem.auto_mail_body"></textarea>
            </div>
          </div>

          <div class="uk-margin">
            <button class="uk-button uk-button-primary" (click)="submit()">{{'save' | translate}}</button>
          </div>
      </div>

    </div>

  </div>

</div>


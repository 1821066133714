import { Component, OnInit } from '@angular/core';
import {ClientsService} from "../../../services/clients/clients.service";
import {ClientModel} from "../../../models/client.model";
import {ActivatedRoute, Router} from '@angular/router';
import {ToastsService} from "../../../services/toasts.service";
import {AuthService} from "../../../services/auth/auth.service";
import {UserModel} from "../../../models/user.model";

@Component({
  selector: 'app-iai-settings',
  templateUrl: './iai-settings.component.html',
  styleUrls: ['./iai-settings.component.scss']
})
export class IaiSettingsComponent implements OnInit {
  client: any = new ClientModel();
  id: string = null;
  public userModel: UserModel = new UserModel();
  constructor(
    private clientsService: ClientsService,
    private activatedRoute: ActivatedRoute,
    private toastsService: ToastsService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.authService.isLogged.subscribe((userModel) => {
      this.userModel = userModel;
    });
    this.getClient();
  }

  getClient() {
    this.clientsService.getClient(this.userModel.id_client).subscribe(
      (s: ClientModel) => {
        this.client = s;
      }
    );
  }

  change(){
    this.clientsService.updateIAISettings({
      iai_password: this.client.iai_password
    }).subscribe((resonse: any)=>{
      this.toastsService.showMessage('Zmiany zostały zapisane!', 's');
    });
  }


  copyPassword() {
    navigator.clipboard.writeText(this.client.iai_password);
    this.toastsService.showMessage('password_was_copied', 's');
  }

  generateIAIPassword() {
    this.clientsService.generateIAIPassword().subscribe(
      (response: any)=>{
        this.client.iai_password = response.password;
      }
    );
  }

}

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ToastsService} from '../toasts.service';
import {ParcelsService} from "../parcels/parcels.service";
@Injectable({
  providedIn: 'root'
})
export class ShipmentService {
  private apiurl = '';
  constructor(private http: HttpClient, private toastsService: ToastsService, private parcelsService: ParcelsService) {
    this.apiurl = environment.api + 'app/v1/shipment';
  }

  requestReturnPickup(form) {
    return this.http.put(
        this.apiurl + '/pickup',
        form,
        {observe: 'response'}
    );
  }
}

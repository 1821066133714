import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../services/auth/auth.service';
import {ToastsService} from '../../../services/toasts.service';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

    constructor(private authService: AuthService, private toastsService: ToastsService) {
    }

    ngOnInit() {
    }

    onChangePassword(form) {
        this.authService.updatePassword(form.value).subscribe(
            (s) => {
                this.toastsService.showMessage('password_changed', 's');
            }
        );
    }

}

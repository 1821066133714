import {Component, OnInit, ViewChild} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {AuthService} from '../../../services/auth/auth.service';
import {UserModel} from '../../../models/user.model';
import {ClientsService} from '../../../services/clients/clients.service';
import {Subject} from 'rxjs';
import {DataTableDirective} from 'angular-datatables';
import {UserRole} from "../../../enums/user-role";
import {TagsService} from "../../../services/tags/tags.service";

@Component({
  selector: 'app-tag-list',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {
  clients = [];
  apiUrl = null;
  dtTranslation = null;

  items = [];
  public dtInit = false;
  statusModel = {
    ids: [],
    id_status_type: null,
    when: null,
    where: 'TYCHY',
  };

  public userModel: UserModel = new UserModel();
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  timeout = 100;
  subscriptions = [];
  statusTypes = [];

  form = {
    type: 'client',
    label: null,
    value: null,
  }


  constructor(
    private authService: AuthService,
    private clientsService: ClientsService,
    private tagsService: TagsService,
  ) {
    this.apiUrl = environment.api;
  }

  ngOnInit() {
    this.authService.isLogged.subscribe((userModel) => {
      this.userModel = userModel;
    });
  }


  searchClient(input): void {
    this.clientsService.searchClient(input.value).subscribe(
      (response: Array<any>) => {
        this.clients = response;
      }
    );
  }

  setClient(client): void {
    this.clients = [];
    this.form.value = client.id;
    this.form.label = client.name;
  }


  orderTag() {
    this.tagsService.order(this.form).subscribe(
      (response: Blob) => {
        var url = window.URL.createObjectURL(response);
        var anchor = document.createElement("a");
        anchor.download = this.form.type + ' ' + this.form.label + ' tag.pdf';
        anchor.href = url;
        anchor.click();
      }
    );
  }

  protected readonly UserRole = UserRole;
  protected readonly Object = Object;
}

<div class="uk-grid">
  <div class="uk-width-1-1 navbar">
    <p class="nav-text">
      {{'problem'| translate}} {{problemModel.code}} - {{problemModel.internal_number}}
      <button class="uk-button uk-button-primary padding-horizontal-small button-opt" [attr.uk-tooltip]="'Wróć'" [routerLink]="'/problems'"><span uk-icon="sign-out"></span></button>
      <a class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right" [attr.uk-tooltip]="'Przejdż do podglądu przesyłki'" (click)="goToParcel()"><span uk-icon="forward"></span></a>
      <a *ngIf="problemModel.status!='solved'" class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right" [attr.uk-tooltip]="'Ustaw jako rozwiązaną'" (click)="setSolved()"><span uk-icon="bolt"></span></a>
      <a *ngIf="problemModel.status=='solved'" class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right" [attr.uk-tooltip]="'Ustaw jako nierozwiązaną'" (click)="setNotSolved()"><span uk-icon="history"></span></a>
      <a *ngIf="problemModel.status=='noticed'" class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right" [attr.uk-tooltip]="'Ustaw jako w trakcie'" (click)="setInProgress()"><span uk-icon="clock"></span></a>
      <a *ngIf="problemModel.status!='solved' && problemModel.code=='dpd_parcel_not_created'" class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right"  style="background-color: var(--warning-re) !important;" [attr.uk-tooltip]="'Nadaj ponownie'" (click)="reSend()"><span uk-icon="refresh"></span></a>
      <button class="uk-button uk-button-danger padding-horizontal-small button-opt uk-margin-remove-right" (click)="delete()" [attr.uk-tooltip]="'Uznaj za niebyłą'"><span uk-icon="ban"></span></button>
    </p>
  </div>

  <div class="uk-width-1-1 inner-shadow">

    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
      <p class="uk-heading-divider">Dane zgłoszenia</p>
      <div class="mobile-overflow uk-padding-remove-top">
        <div class="uk-width-1-1  uk-padding-remove-top">
            <div class="uk-grid">
              <div class="uk-width-1-4">
                <label>Numer przesyłki </label>
                <input class="uk-input" [(ngModel)]="problemModel.internal_number" name="internal_number" disabled>
              </div>
              <div class="uk-width-1-4">
                <label>Kurier wyjściowy</label>
                <input class="uk-input" [(ngModel)]="problemModel.out_courier" name="out_courier" disabled>
              </div>
              <div class="uk-width-1-4">
                <label>Wyjściowy numer przesłki</label>
                <input class="uk-input" [(ngModel)]="problemModel.out_number" name="out_number" disabled>
              </div>
              <div class="uk-width-1-4">
                <label>Zwrotny numer przesłki</label>
                <input class="uk-input" [(ngModel)]="problemModel.return_number" name="return_number" disabled>
              </div>

              <div class="uk-width-1-2">
                <label>Nazwa nieprawidłowości</label>
                <input class="uk-input" [(ngModel)]="problemModel.rule" name="rule" disabled>
              </div>

              <div class="uk-width-1-4">
                <label>Kod nieprawidłowości</label>
                <input class="uk-input" [(ngModel)]="problemModel.code" name="code" disabled>
              </div>

              <div class="uk-width-1-4">
                <label>Data rozpoznania</label>
                <input class="uk-input" [(ngModel)]="detected_at" name="detectedAt" disabled>
              </div>

              <div class="uk-width-1-2">
                <div class="uk-grid">
                  <div class="uk-width-2-3">
                    <label>Klient </label>
                    <input class="uk-input" [(ngModel)]="problemModel.client" name="client" disabled>
                  </div>
                  <div class="uk-width-1-3">
                    <label>Własne dane DPD</label>
                    <select class="uk-select" name="default_api" [(ngModel)]="problemModel.default_api" disabled>
                      <option value="0">Tak</option>
                      <option value="1">Nie</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="uk-width-1-4">
                <label>Status</label>
                <input class="uk-input" value="{{problemModel.status|translate}}" name="status" disabled>
              </div>

              <div class="uk-width-1-4">
                <label>Data rozwiązania</label>
                <input class="uk-input" [(ngModel)]="problemModel.solved_at" name="created_at" disabled>
              </div>

              <div class="uk-width-1-2">
                <label>Stan bazowy</label>
                <input class="uk-input" [(ngModel)]="problemModel.base_status" name="base_status" disabled>
              </div>
              <div class="uk-width-1-2">
                <label>Stan oczekiwany</label>
                <input class="uk-input" [(ngModel)]="problemModel.next_status" name="next_status" disabled>
              </div>

            </div>
        </div>
      </div>
      <div class="uk-grid">
        <div class="uk-width-1-2@m uk-margin-medium-top">
          <p class="uk-heading-divider">Krótka historia przesyłki</p>
          <table class="uk-table uk-width-1-1 uk-table-hover uk-table-divider">
            <thead>
            <tr>
              <th>{{'date' | translate}}</th>
              <th>{{'status' | translate}}</th>
            </tr>
            </thead>
            <tbody *ngIf="threeLastStatuses?.length > 0">
            <tr *ngFor="let status of threeLastStatuses">
              <td><kbd>{{ status.when }}</kbd></td>
              <td>{{ status.displayed_name }}
                <span *ngIf="status.where != null">({{ (status.where).replace('or. ', '') }})</span>
              </td>
            </tr>
            </tbody>
            <tbody *ngIf="threeLastStatuses?.length == 0">
            <tr>
              <td colspan="2">{{'no_data' | translate}}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="uk-width-1-2@m uk-margin-medium-top">
          <p class="uk-heading-divider">{{'Notatka' | translate}}</p>
          <textarea [(ngModel)]="note" rows="8" name="note" class="uk-textarea"></textarea>
          <a class="uk-button uk-button-primary uk-margin-small-top" (click)="saveNote()">{{'save' | translate}}</a>
        </div>
      </div>
      <div *ngIf="payload!==null" class="uk-width-1-1 uk-margin-medium-top">
        <ul class="mobile-overflow uk-margin-top uk-accordion" uk-accordion>
          <li class="uk-close">
            <a class="uk-accordion-title" href="#" [title]="'Pozostałe informacje służące diagnostyce.'">Opcje programistyczne</a>
            <div class="uk-accordion-content">
              <p class="uk-heading-divider">Przechwycona wartość błędu</p>
              <textarea [(ngModel)]="payload" rows="8" name="payload" class="uk-textarea"></textarea>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="uk-grid">
  <div class="uk-width-1-1 navbar">
    <p class="nav-text">
      {{'manage_parcel_statuses' | translate}}
    </p>
  </div>


  <div class="uk-width-1-1 inner-shadow">

    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">


      <div class="uk-width-1-1@l">
        <form class="" #parcelForm="ngForm" (ngSubmit)="updateStatus()">

          <div class="uk-alert uk-alert-danger" *ngIf="error">
            {{"parcel_search_error" | translate}}
          </div>

          <div class="uk-grid">
            <div class="uk-width-1-3">
              <div>
                <label>{{'parcel_number' | translate}}</label>
                <input name="number" class="uk-input" type="text" required [(ngModel)]="statusModel.parcelNumber">
              </div>
            </div>
          </div>
          <div class="uk-grid uk-margin-remove-top">
            <div class="uk-width-1-3@m">
              <label>{{'choose_status' | translate}}</label>
              <select name="id_status_type" required class="uk-input" [(ngModel)]="statusModel.id_status_type">
                <option *ngFor="let statusType of statusTypes" value="{{statusType.id}}">{{statusType.displayed_name}}
                </option>
              </select>
            </div>

            <div class="uk-width-1-3@m">
              <label>{{'when' | translate}}</label>
              <input type="datetime-local" autocomplete="none" name="when" [(ngModel)]="statusModel.when" required
                     data-uk-timepicker class="uk-input">
            </div>

            <div class="uk-width-1-3@m">
              <label>{{'where' | translate}}</label>
              <input type="text" autocomplete="none" name="where" [(ngModel)]="statusModel.where" required
                     class="uk-input">
            </div>

          </div>

          <div class="uk-margin-top">
            <button [disabled]="!parcelForm.valid" type="submit" class="uk-button uk-button-primary">
              Dodaj status
            </button>
          </div>

        </form>

        <table class="uk-table uk-width-1-1 uk-table-hover uk-table-divider" *ngIf="statuses.length > 0">
          <thead>
          <tr>
            <th>{{'date' | translate}}</th>
            <th>{{'status' | translate}}</th>
            <th></th>
          </tr>
          </thead>
          <tbody *ngIf="statuses?.length > 0">
          <tr *ngFor="let status of statuses">
            <td><kbd>{{ status.when }}</kbd></td>
            <td>
              {{ status.displayed_name }}
              <span *ngIf="status.where != null">({{ (status.where).replace('or. ', '') }})</span>
              <small title="Login użytkownika który nadał status"><br>{{status.email}}</small>
            </td>
            <td>

              <button class="uk-button padding-horizontal-small" style="float: right; margin-top: 0"
                      [attr.uk-tooltip]="'Usuń status'" (click)="onStatus(status.id)"><span
                uk-icon="trash"></span>
              </button>

            </td>
          </tr>
          </tbody>
        </table>
      </div>


    </div>

  </div>
</div>


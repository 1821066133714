import {Component, OnInit} from '@angular/core';
import {UsersService} from '../../../services/users/users.service';
import {ActivatedRoute, Route, Router} from '@angular/router';
import {ToastsService} from '../../../services/toasts.service';
import {UserModel} from '../../../models/user.model';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
    user: UserModel = new UserModel();
    id = null;
    constructor(private usersService: UsersService,
                private router: Router,
                private route: ActivatedRoute,
                private toastsService: ToastsService,
    ) { }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.usersService.getUser(this.id).subscribe(
            (s: any) => {
                this.user = s;
            }
        );
    }

    onEditUser(form) {
        this.usersService.updateUser(form.value).subscribe(
            (response: any) => {
                this.toastsService.showMessage('user_updated', 's');
                this.router.navigateByUrl('/users');
            }
        );
    }

    async onRemoveUser() {
        const promised = await this.toastsService.showConfirmModal();
        if (!promised) {
            return;
        }
        this.usersService.removeUser(this.id).subscribe(
            (response: any) => {
                this.toastsService.showMessage('user_removed', 's');
                this.router.navigateByUrl('/users');
            }
        );
    }

}

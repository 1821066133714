
<div class="uk-grid">
  <div class="uk-width-1-1 navbar">
    <p class="nav-text">
      {{'manage_idosell' | translate}}
   </p>
  </div>

  <div class="uk-width-1-1 inner-shadow">

    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
      <div class="uk-grid">

        <div class="uk-width-1-3@m">
          <form>
            <div class="uk-grid">
              <div class=" uk-width-1-1">
                <label>{{'iai_username' | translate}}</label>
                <input class="uk-input" type="text" name="iai_username" #iai_username="ngModel" [ngModel]="client.iai_username" required readonly disabled>
              </div>
            </div>
            <div class="uk-margin">
              <button class="uk-button uk-button-primary" (click)="change()">{{'save' | translate}}</button>
            </div>
          </form>
        </div>
        <div class="uk-width-1-3@m">
          <form #apiNewToken="ngForm" >
            <div class="uk-grid">
              <div class=" uk-width-1-1">
                <label>{{'iai_password' | translate}}</label>
                <input class="uk-input" type="text" name="iai_password" #iai_password="ngModel" [ngModel]="client.iai_password" required>
              </div>
            </div>
            <div class="uk-margin">
              <button class="uk-button uk-button-primary" (click)="generateIAIPassword()">{{'generate_password' | translate}}</button>
              <button class="uk-button uk-button-primary" style="margin-right: 0" (click)="copyPassword()"><span uk-icon="copy"></span></button>
            </div>
          </form>
       </div>

      </div>
    </div>
  </div>
</div>

export class ClientModel {
  id: string;
  name: string;
  email: string;
  nip: string;
  post_code: string;
  street: string;
  address: string;
  bank_account_number: string;
  phone_number: string;
  phone_number_prefix: string;
  dpd_system_id: string;
  dpd_api_password: string;
  shipment_note: string;
  contact_name: string;
  city: string;
  re_broadcasting_facility_address: string;
  re_returning_address: string;
  api_enabled: boolean;
  allowed_ip: string;
  token: string;
  sender_signature: string;
  cod_net_price: number;
  insurance_percentage: number;
  show_price_parcel: boolean;
  cod_enabled: boolean;
  out_service: string;
  iai_password: string;
  download_ext: boolean;
  ext_number: boolean;
  iai_username: string;
  iai_idosell_enabled: boolean;
  smd_client_contact_id: string;
  smd_client_contact_name: string;
}

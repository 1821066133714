
export enum KeyCode {
  TAB = 9,
  ENTER = 13,
  ESCAPE = 27,
  PAGE_UP = 33,
  PAGE_DOWN = 34,
  DELETE = 46,

}

export class PriceModel {
    id: string;
    net_price: string;
    vat_percentage: string;
    max_insurance_value: string;
    min_weight_value: string;
    max_weight_value: string;
    this_side_up: string;
    liquid: string;
    not_expose_to_frost: string;
    cash_on_delivery: string;
    caution: string;
}

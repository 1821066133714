<div id="clientCredentail">
  <p class="uk-heading-divider">{{'active_api_credentails' | translate}}</p>
  <table class="uk-table uk-width-1-1 uk-table-hover uk-table-divider">
    <thead>
      <tr>
        <td style="width: 3%">LP.</td>
        <td>Rodzaj</td>
        <td>Numer umowy</td>
        <td>Komentarz</td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let cred of credentails; let key = index" (click)="open(cred.id)">
        <td>{{key+1}}</td>
        <td>{{cred.type}}</td>
        <td>{{cred.contract_number}}</td>
        <td>{{cred.comment}}</td>
      </tr>
    </tbody>
  </table>
</div>



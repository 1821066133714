import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastsService} from '../../../services/toasts.service';
import {CourierPricesService} from '../../../services/courier_prices/courier-prices.service';
import {PriceModel} from '../../../models/price.model';

@Component({
    selector: 'app-price',
    templateUrl: './price.component.html',
    styleUrls: ['./price.component.scss']
})
export class PriceComponent implements OnInit {
    id = null;
    price: PriceModel = new PriceModel();

    constructor(
        private courierPricesService: CourierPricesService,
        private router: Router,
        private route: ActivatedRoute,
        private toastsService: ToastsService,
    ) {
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.courierPricesService.getCourierPrice(this.id).subscribe(
            (s: any) => {
                this.price = s;
            }
        );
    }

    onEditPrice(form) {
        this.courierPricesService.updateCourierPrice(form.value).subscribe(
            (response: any) => {
                this.toastsService.showMessage('price_updated', 's');
                this.router.navigateByUrl('/prices');
            }
        );
    }

}

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Router} from "@angular/router";
import {ToastsService} from "../toasts.service";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ProblemsService {
  private apiurl = '';
  constructor(private http: HttpClient, private router: Router) {
    this.apiurl = environment.api;
  }

  showProblem(id){
    const url = this.apiurl + 'problems/one/'+id;
    return this.http.get(url);
  }

  getNewCount(){
    const url = this.apiurl + 'problems/count';
    return this.http.get(url);
  }

  progress(id){
    const url = this.apiurl + 'problems/progress';
    return this.http.put(url, {id});
  }

  notice(id){
    const url = this.apiurl + 'problems/notice';

    return this.http.put(url, {id});
  }

  resolve(id){
    const url = this.apiurl + 'problems/resolve';

    return this.http.put(url, {id});
  }

  unresolve(id){
    const url = this.apiurl + 'problems/unresolve';
    return this.http.put(url, {id});
  }

  resolveMass(ids){
    const url = this.apiurl + 'problems/resolve/many';

    return this.http.put(url, {ids});
  }

  progressMass(ids){
    const url = this.apiurl + 'problems/progress/many';

    return this.http.put(url, {ids});
  }

  resend(id){
    const url = this.apiurl + 'problems/resend';
    return this.http.put(url, {id});
  }

  resendMass(ids){
    const url = this.apiurl + 'problems/resend/many';
    return this.http.put(url, {ids});
  }

  updateNote(form){
    const url = this.apiurl + 'problems/note';
    return this.http.put(url, form);
  }

  delete(id){
    const url = this.apiurl + 'problems';
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id
      },
    };
    return this.http.delete(url, options);
  }

  getRules(){
    const url = this.apiurl + 'problems/rules';
    return this.http.get(url);
  }

  showRule(id){
    const url = this.apiurl + 'problems/rule/'+id;
    return this.http.get(url);
  }

  createRule(form){
    const url = this.apiurl + 'problems/rule';
    return this.http.post(url, form);
  }

  updateRule(form){
    const url = this.apiurl + 'problems/rule';
    return this.http.put(url, form);
  }

  removeRule(id) {
    const url = this.apiurl + 'problems/rule';
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id
      },
    };
    return this.http.delete(url, options);
  }
}

<div *ngIf=" clientId && id">
    <div class="uk-grid">
        <div class="uk-width-1-1 navbar">
          <p class="nav-text">
            <span *ngIf="id">{{client.name}}:  Modyfikacja danych uwieżytelniających</span>
            <button class="uk-button uk-button-primary padding-horizontal-small button-opt " [routerLink]="'/client/'+clientId+'/card/external_api_credentials'" [attr.uk-tooltip]="'Wróć'"><span uk-icon="sign-out"></span></button>
            <button *ngIf="id" class="uk-button uk-button-danger padding-horizontal-small uk-margin-remove-right button-opt " (click)="delete()" [attr.uk-tooltip]="'Usuń dane dostępowe'"><span uk-icon="trash"></span></button>
          </p>
        </div>
    <tbody>
        <tr *ngFor="let credential of credentials; let key = index">
            <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
                <div class="mobile-overflow">
                        <form #updateClientForm="ngForm" (ngSubmit)="onSubmit(updateClientForm)">
                        <div class="uk-grid">
                            <div class=" uk-width-1-2@m">
                                <label class="required">Nazwa użytkownka</label>
                                <input class="uk-input" type="text" name="username" #username="ngModel" ngModel
                                    [(ngModel)]="credential.username">
                            </div>
                            <div class=" uk-width-1-2@m">
                                <label class="required">Typ danych</label>
                                <input class="uk-input" type="text" name="type" #type="ngModel" ngModel
                                    [(ngModel)]="credential.type" readonly>
                            </div>
                            <div class=" uk-width-1-2@m">
                                <label>Hasło</label>
                                <input class="uk-input" type="text" name="password" #password=""  ngModel>
                                    <!--[(ngModel)]="credential.password">-->
                            </div>
                            <div class=" uk-width-1-2@m" *ngIf="credential.type === 'another'">
                                <label>IP</label>
                                <input class="uk-input" type="text" name="ip" #ip="ngModel"
                                    [(ngModel)]="credential.ip" ngModel>
                            </div>
                            <div class=" uk-width-1-2@m" *ngIf="credential.type === 'another'">
                                <label>Token</label>
                                <input class="uk-input" type="text" name="token" #token="ngModel" ngModel
                                [(ngModel)]="credential.token">
                            </div>
                            <div class=" uk-width-1-2@m">
                                <label>Utworzył</label>
                                <input class="uk-input" type="text" name="creator" #creator="ngModel" ngModel
                                    [(ngModel)]="credential.creator_id" readonly>
                            </div>
                            <div class="uk-margin">
                                <button class="uk-button uk-button-primary"
                                    [disabled]="updateClientForm.form.invalid">Zapisz</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </tr>
    </tbody>
</div>
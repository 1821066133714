import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import { ClientExternalApiCredentialsService } from 'src/app/services/client-external-api-credentials/client-external-api-credentials.service';


@Component({
  selector: 'app-client-external-api-credentials',
  templateUrl: './client-external-api-credentials.component.html',
  styleUrls: ['./client-external-api-credentials.component.scss']
})
export class ClientExternalApiCredentialsComponent implements OnInit{

  @Input() clientId;
  id = null;
  credentials: [];
  selectedItem: boolean = false;

  constructor(
    private clientExternalApiCredentialsService: ClientExternalApiCredentialsService,
    private route: Router
  ) {
  }

  ngOnInit() {
    this.getCredentials();
  }

  getCredentials() {
    this.clientExternalApiCredentialsService.listByClientId(this.clientId).subscribe(
      (response: []) => {
        this.credentials = response;
      }
    );
  }

  open(id = null) {
    this.clientExternalApiCredentialsService.findByIdclient(this.clientId, id).subscribe(
      (response: []) => {
        this.credentials = response;
      }
    );

    if(id !== null && id !== undefined)
    {
      this.route.navigateByUrl('/client/' + this.clientId + '/external_api_credentials/' + id);
    }
  }
}

<div class="uk-grid">
    <div class="uk-width-1-1 navbar">
        <p class="nav-text">
            {{'manage_users' | translate}}
            <button class="uk-button uk-button-primary padding-horizontal-small button-opt" routerLink="/users/new" [attr.uk-tooltip]="'Dodaj użytkownika'"><span uk-icon="plus"></span></button>
        </p>
    </div>


  <div class="uk-width-1-1 inner-shadow">

    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
            <div class="mobile-overflow">
                <div class="uk-grid uk-margin-bottom">

                    <div class="uk-width-1-4@m">
                        <label>&nbsp;</label>
                        <button class="uk-button uk-button-primary uk-width-1-1" (click)="refreshUsersList()">
                            <span *ngIf="deletedUsers == ''">{{'all_users' | translate}}</span>
                            <span *ngIf="deletedUsers == '/deleted'">{{'deleted_users' | translate}}</span>
                        </button>
                    </div>
                </div>

                <table class="uk-table uk-width-1-1 uk-table-hover uk-table-divider" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                    <thead>
                    <tr>
                        <th>{{'id' | translate}}</th>
                        <th>{{'email' | translate}}</th>
                        <th>{{'email_verified_at' | translate}}</th>
                    </tr>
                    </thead>
                    <tbody *ngIf="users?.length > 0">
                    <tr *ngFor="let user of users" [routerLink]=" '/users/user/' + user.id ">
                        <td>{{ user.id }}</td>
                        <td>{{ user.email }}</td>
                        <td>{{ user.email_verified_at }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>

    </div>

</div>


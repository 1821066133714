import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ParcelsService} from '../../../services/parcels/parcels.service';
import {ToastsService} from '../../../services/toasts.service';
import {environment} from '../../../../environments/environment';
import {ClientsService} from '../../../services/clients/clients.service';
import {ScanningService} from '../../../services/scanning/scanning.service';
import {ParcelService} from '../../../services/parcel/parcel.service';
import {ClassificationService} from '../../../services/classification/classification.service';

@Component({
  selector: 'app-parcel-scan-search',
  templateUrl: './parcel-scan-search.component.html',
  styleUrls: ['./parcel-scan-search.component.scss']
})
export class ParcelScanSearchComponent implements OnInit, OnDestroy {
  @ViewChild('barcodeInput') barcodeInput: ElementRef;
  internalNumber = null;
  id = null;
  statuses = [];
  error = false;

  idClient = null;
  client = null;

  scanningLocked = false;

  public lastChars = '';
  public lastKeyCode = null;
  public clearLastCharsInterval = null;
  barcode = '';

  constructor(
      private parcelService: ParcelService,
      private route: ActivatedRoute,
      private parcelsService: ParcelsService,
      private toastsService: ToastsService
  ) {
  }

  ngOnInit() {
    this.keyReading();
    this.clearLastChars();
  }

  runSearch() {
    if (this.barcode === this.lastChars) {
      this.toastsService.showBigMessage('Zeskanowana przesyłka jest przesyłką której szukasz!');
    }
  }

  clear() {
    this.barcode = '';
  }

  ngOnDestroy() {
    document.removeEventListener('keyup', this.listenerAction, false);
  }

  keyReading() {
    document.addEventListener('keyup', this.listenerAction, false);
  }


  eanChecker(tempChars) {
    if (tempChars.length === 8) {
      this.toastsService.showMessage('Zeskanowano nieprawidłowy kod - wykryto EAN8', 'w', 5000);
      return false;
    }
    if (tempChars.length === 13) {
      this.toastsService.showMessage('Zeskanowano nieprawidłowy kod - wykryto EAN13', 'w', 5000);
      return false;
    }
    if (tempChars.length === 14) {
      this.toastsService.showMessage('Zeskanowano nieprawidłowy kod - wykryto EAN14', 'w', 5000);
      return false;
    }
    if (tempChars.length > 28) {
      this.toastsService.showMessage('Zbyt szybko skanujesz przesyłki', 'w', 5000);
      return false;
    }

    return true;
  }

  listenerAction = (e) => {
    this.clearLastChars();
    if ((e.ctrlKey || e.metaKey)) {
      return;
    }

    this.lastKeyCode = e.keyCode;

    if ((e.keyCode >= 48 && e.keyCode <= 57 && this.lastKeyCode !== 16)
      || e.keyCode === 189 || (e.keyCode > 40 && e.keyCode < 91)) {
      if (e.keyCode === 189) {
        this.lastChars += '_';
      } else {
        this.lastChars += String.fromCharCode(e.keyCode) + '';
      }
    }

    this.lastKeyCode = e.keyCode;
  }

  checkNumbers() {
    if (this.lastChars.length === 0 || !this.eanChecker(this.lastChars)){
      return;
    }

    if (this.barcode.length === 0) {
      this.barcode = this.lastChars;
    } else {
      this.runSearch();
    }
  }

  clearLastChars() {
    if (this.clearLastCharsInterval != null) {
      clearInterval(this.clearLastCharsInterval);
      this.clearLastCharsInterval = null;
    }
    this.clearLastCharsInterval = setInterval(
      () => {
        this.checkNumbers();
        this.lastChars = '';
      }, 800
    );
  }
}

import {Component, OnInit} from '@angular/core';
import {CourierPricesService} from '../../../services/courier_prices/courier-prices.service';

@Component({
    selector: 'app-prices',
    templateUrl: './prices.component.html',
    styleUrls: ['./prices.component.scss']
})
export class PricesComponent implements OnInit {
    prices = [];
    courierId = 1;
    constructor(private courierPricesService: CourierPricesService) {
    }

    ngOnInit() {
        this.getPrices();
    }

    getPrices() {
        this.courierPricesService.getCourierPrices(this.courierId).subscribe(
            (response: any) => {
                this.prices = response;
            }
        );
    }
}

import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ScanningService {
  private apiurl = '';
  constructor(private http: HttpClient, private router: Router) {
    this.apiurl = environment.api;
  }

  scanParcel(form) {
    const url = this.apiurl + 'app/v1/parcel/scan';
    return this.http.post(url, form,  {observe: 'response'});
  }

  scanAndAssignToClient(form) {
    const url = this.apiurl + 'app/v1/parcel/scan-and-assign-to-client';
    return this.http.put(url, form,  {observe: 'response'});
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {ClientsService} from '../../../services/clients/clients.service';
import {ActivatedRoute, Router} from '@angular/router';
import {OperationalPriceListModel} from '../../../models/operational-price-list.model';
import {CountryModel} from '../../../models/country.model';
import {CourierModel} from '../../../models/courier.model';
import {CourierService} from '../../../services/courier/courier.service';
import {CountryService} from '../../../services/country/country.service';
import {OperationalPriceListWeightThresholdModel} from '../../../models/operational-price-list-weight-threshold.model';
import {OperationalPriceListService} from '../../../services/operational-price-list/operational-price-list.service';
import {ToastsService} from '../../../services/toasts.service';
import {CurrencyService} from '../../../services/currency/currency.service';

@Component({
  selector: 'app-operational-price-list-form',
  templateUrl: './operational-price-list-form.component.html',
  styleUrls: ['./operational-price-list-form.component.scss']
})
export class OperationalPriceListFormComponent implements OnInit {
  form: OperationalPriceListModel = new OperationalPriceListModel();
  changesDisabled = false; // to do sprawdzac czy można zmienic -> czy jest już jakas przesyłka która z niego skorzystała

  currentActiveIds = [];
  couriers: CourierModel[];
  countries: CountryModel[];
  currencies: [];
  specialAssociations: [];
  type: string;
  id = null;
  idClient = null;

  isDefaultPriceListAvailable = false;
  priceLists = [];
  clientName = null;

  constructor(
    private router: Router,
    private toastsService: ToastsService,
    private activatedRoute: ActivatedRoute,
    private courierService: CourierService,
    private countryService: CountryService,
    private clientsService: ClientsService,
    private currencyService: CurrencyService,
    private operationalPriceListService: OperationalPriceListService
  ) {
  }

  ngOnInit(): void {
    this.idClient = this.activatedRoute.snapshot.paramMap.get('idClient');
    this.type = this.activatedRoute.snapshot.paramMap.get('type');
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.getCouriers();
    this.getCountries();
    this.getCurrencies();
    this.getSpecificAssociation();
    this.checkIsPriceListUsed();
    if (this.idClient !== null) {
      this.form.id_client = this.idClient;
      this.clientsService.getClient(this.idClient).subscribe(
        (response: any) => {
          this.clientName = response.name;
        }
      );
    }

    if (this.id === null) {
      this.form.type = this.type;
    } else {
      this.operationalPriceListService.find(this.type, this.id).subscribe(
        (response: OperationalPriceListModel) => {
          this.form = response;
        }
      );
    }
    this.operationalPriceListService.currentActive(this.type).subscribe(
      (response: []) => {
        this.currentActiveIds = response;
      }
    );
  }

  save() {
    if (this.id === null) {
      this.store();
    }
    else {
      this.update();
    }
  }

  store() {
    this.operationalPriceListService.store(this.form).subscribe(
      (response: any) => {
        this.toastsService.showMessage('Utworzono pomyślnie!', 's');

        this.router.navigateByUrl(
          this.idClient === undefined || this.idClient === null
            ? '/operational-price-list/' + this.type + '/form/' + response.body.id
            : '/operational-price-list/' + this.type + '/client_form/' + this.idClient + '/' + response.body.id
        );
      },
      (error: any) => {
        if (error.status === 422) {
          this.toastsService.showMessage(error.error.message, 'e');
        }
      }
    );
  }

  checkIsPriceListUsed() {
    this.operationalPriceListService.checkIsUsed(this.type, this.id ?? 0).subscribe(
      (response: any) => {
        this.changesDisabled = response.used;
        if (this.changesDisabled) {
          this.toastsService.showMessage('Cennik jest już aktualnie w użyciu', 'w');
        }
      }
    );
  }

  update() {
    this.operationalPriceListService.update(this.form).subscribe(
      () => {
        this.toastsService.showMessage('Zmodyfikowano pomyślnie!', 's');
        this.operationalPriceListService.find(this.type, this.id).subscribe(
          (response: OperationalPriceListModel) => {
            this.form = response;
          }
        );
      },
      (error: any) => {
        if (error.status === 422) {
          this.toastsService.showMessage(error.error.message, 'e');
        }
      }
    );
  }

  getCouriers() {
    this.courierService.list().subscribe(
      (response: CourierModel[]) => {
        this.couriers = response;
      }
    );
  }

  getCurrencies() {
    this.currencyService.list().subscribe(
      (response: []) => {
        this.currencies = response;
      }
    );
  }

  getCountries() {
    this.countryService.list().subscribe(
      (response: CountryModel[]) => {
        this.countries = response;
      }
    );
  }

  getSpecificAssociation() {
    this.operationalPriceListService.specialAssociation(this.type).subscribe(
      (response: []) => {
        this.specialAssociations = response;
      }
    );
  }

  deleteWeightThreshold(index) {
    this.form.weight_thresholds.splice(index, 1);
  }

  addWeightThreshold() {
    this.form.weight_thresholds.push(
      new OperationalPriceListWeightThresholdModel()
    );
  }

  delete() {
    this.operationalPriceListService.delete(this.type, this.id).subscribe(
      (response: any) => {
        this.toastsService.showMessage('Usunięto pomyślnie!', 's');
        if (this.idClient === null) {
          this.router.navigateByUrl('/operational-price-list/' + this.type + '/list');
        } else {
          this.router.navigateByUrl('/client/' + this.idClient + '/card/' + this.type + '_operational_price_list');
        }
      }
    );
  }

  back() {
    if (this.idClient === null) {
      this.router.navigateByUrl('/operational-price-list/' + this.type + '/list');
    } else {
      this.router.navigateByUrl('/client/' + this.idClient + '/card/' + this.type + '_operational_price_list');
    }
  }

  deactivate() {
    this.operationalPriceListService.deactivate(this.type, this.id).subscribe(
      (response: any) => {
        this.router.navigateByUrl('/client/' + this.idClient + '/card/' + this.type + '_operational_price_list');
        this.toastsService.showMessage('Powrócono do korzystania z głównego cennika!', 's');
      }
    );
  }

  checkIsDefaultAvailable() {
    if (undefined === this.form.special_association) {
      return;
    }

    this.operationalPriceListService.currentActiveDefault(
      this.type,
      this.form.special_association,
      this.form.id_courier,
      this.form.id_country
    ).subscribe(
      (response: OperationalPriceListModel) => {
        this.isDefaultPriceListAvailable = response.id !== undefined;
      }
    );
  }

  cloneFromDefault() {
    this.operationalPriceListService.currentActiveDefault(
      this.type,
      this.form.special_association,
      this.form.id_courier,
      this.form.id_country
    ).subscribe(
      (response: OperationalPriceListModel) => {
        if (response.id === undefined) {
          this.toastsService.showMessage('Brak aktywnego domyślnego cennika!', 'w');
          return;
        }
        this.form = response;
        this.form.begin_date = this.findDate();
        this.form.id = null;
        this.form.id_client = this.idClient;
      }
    );
  }

  findDate() {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());

    return `${year}-${month}-${day}`;
  }
}

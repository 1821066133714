import { Component, OnInit } from '@angular/core';
import {ClientsService} from "../../../services/clients/clients.service";
import {ClientModel} from "../../../models/client.model";
import {ActivatedRoute, Router} from '@angular/router';
import {ToastsService} from "../../../services/toasts.service";
import {AuthService} from "../../../services/auth/auth.service";
import {UserModel} from "../../../models/user.model";

@Component({
  selector: 'app-api-settings',
  templateUrl: './api-settings.component.html',
  styleUrls: ['./api-settings.component.scss']
})
export class ApiSettingsComponent implements OnInit {
  client: any = new ClientModel();
  id: string = null;
  public userModel: UserModel = new UserModel();
  constructor(
    private clientsService: ClientsService,
    private activatedRoute: ActivatedRoute,
    private toastsService: ToastsService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.authService.isLogged.subscribe((userModel) => {
      this.userModel = userModel;
    });
    this.getClient();
  }

  getClient() {
    this.clientsService.getClient(this.userModel.id_client).subscribe(
      (s: ClientModel) => {
        this.client = s;
      }
    );
  }

  changeIP(form) {
    this.clientsService.changeAPIAllowedIP(form.value).subscribe(
      (s) => {
        this.toastsService.showMessage('api_ip_changed', 's');
      }
    );
  }

  generateNewToken() {
    this.clientsService.generateNewAPIToken().subscribe(
      (s: any) => {
        this.client.token = s.token;
        this.toastsService.showMessage('new_api_token_generated', 's');
      }
    );
  }

  copyToken() {
    navigator.clipboard.writeText(this.client.token);
    this.toastsService.showMessage('token_was_copied', 's');
  }

}

<div class="uk-grid">
    <div class="uk-width-1-1 navbar">
        <p class="nav-text">
            {{'mail_check' | translate}}
        </p>
    </div>


   <div class="uk-container uk-margin-top uk-margin-auto">
       <div class=" uk-margin-auto uk-width-1-1" *ngIf="!validate">
           <div class="uk-alert-danger" uk-alert>
               <p>{{'mail_invalid_message' | translate}}</p>
           </div>
       </div>


       <div class=" uk-margin-auto  uk-width-1-1" *ngIf="validate">
           <div class="uk-alert-success" uk-alert>
               <p>{{'mail_valid_message' | translate}}</p>
               <a class="uk-button-primary uk-button uk-margin-top uk-width-1-1" routerLink="/">{{'login_page' | translate}}</a>
           </div>
       </div>
   </div>


</div>


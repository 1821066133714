import { Component, OnInit } from '@angular/core';
import {UsersService} from '../../../services/users/users.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-user-new',
  templateUrl: './user-new.component.html',
  styleUrls: ['./user-new.component.scss']
})
export class UserNewComponent implements OnInit {

  constructor(private usersService: UsersService,
              private toastr: ToastrService,
              private router: Router,
              private translate: TranslateService) { }

  ngOnInit() {
  }

  onCreateUser(form) {
    this.usersService.createUser(form.value).subscribe(
        (response: any) => {
          this.toastr.error(this.translate.instant('user_added'));
          this.router.navigateByUrl('/users');
        }
    );
  }

}

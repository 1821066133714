import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ClientAdditionalParametersService {
  private apiurl = '';
  constructor(private http: HttpClient, private router: Router) {
    this.apiurl = environment.api;
  }

  store(clientId, form) {
    const url = this.apiurl + 'app/v1/client/' +  clientId + '/additional';

    return this.http.post(url, form);
  }

  update(clientId, form) {
    const url = this.apiurl + 'app/v1/client/' +  clientId + '/additional';

    return this.http.put(url, form);
  }

  delete(clientId, id) {
    const url = this.apiurl + 'app/v1/client/' +  clientId + '/additional';

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id,
      },
    };

    return this.http.delete(url, options);
  }

  listByClientId(clientId) {
    const url = this.apiurl + 'app/v1/client/' +  clientId + '/additional';

    return this.http.get(url);
  }

  findByIdclient(clientId, id) {
    const url = this.apiurl + 'app/v1/client/' +  clientId + '/additional/' + id;

    return this.http.get(url);
  }

  getTypes() {
    const url = this.apiurl + 'app/v1/client/additional_property_types';

    return this.http.get(url);
  }

  findType(id) {
    const url = this.apiurl + 'app/v1/client/additional_property_types/' + id;

    return this.http.get(url);
  }

  scanParcel(form) {
    const url = this.apiurl + 'app/v1/parcel/scan';
    return this.http.post(url, form,  {observe: 'response'});
  }
}

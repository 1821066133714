<div>
    <p class="uk-heading-divider">{{'client_prices' | translate}}</p>
    <table class="uk-table uk-width-1-1 uk-table-hover uk-table-divider">
      <thead>
      <tr>
        <th>{{'net_price' | translate}}</th>
        <th>{{'vat_percentage' | translate}}</th>
        <th>{{'min_weight_value' | translate}}</th>
        <th>{{'max_weight_value' | translate}}</th>
      </tr>
      </thead>
      <tbody *ngIf="prices?.length > 0">
      <tr *ngFor="let price of prices" [routerLink]="'/client/'+clientId+'/price/'+price.id">
        <td >{{ price.net_price }}</td>
        <td >{{ price.vat_percentage }}</td>
        <td >{{ price.min_weight_value}}</td>
        <td >{{ price.max_weight_value}}</td>
      </tr>
      </tbody>
      <tbody *ngIf="prices?.length == 0">
      <tr>
        <td colspan="2">{{'no_data' | translate}}</td>
      </tr>
      </tbody>
    </table>
</div>


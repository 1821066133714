<div class="sidebar computer-only">
  <div>
    <div class="mb-1">
      <div>
        <img src="/assets/img/re-logo.png" class="uk-align-center uk-padding-top logo sidebar-logo-size">
      </div>
    </div>
    <div class="uk-width-1-1 uk-m mb-1">
      <div>
        <h4 class="uk-text uk-text-muted uk-text-center">{{userModel.email}}</h4>
      </div>
    </div>
  </div>
  <check-permissions-modal></check-permissions-modal>
  <export-list [type]="'list'" #exportList></export-list>
</div>


<div class="sidebar mobile-only">
  <div>
    <div class="mb-1 mt-10">
      <div>
        <img src="/assets/img/re-logo.png" class="uk-align-center uk-padding-top logo pt-10">
      </div>
    </div>
    <div class="uk-width-1-1 mb-1">
      <div>
        <h4 class="uk-text uk-text-muted uk-text-center">{{userModel.email}}</h4>
      </div>
    </div>
    <a class="uk-button uk-button-primary uk-width-1-1 mb-1" (click)="mobileMenuIsActive = !mobileMenuIsActive">{{'Menu' | translate}}</a>
    <div class="uk-width-1-1" [class.uk-hidden]="!mobileMenuIsActive">
    </div>
  </div>
  <check-permissions-modal></check-permissions-modal>
  <export-list [type]="'list'" #exportList></export-list>
</div>

import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CredentialsService} from '../../../../services/credentails/credentials.service';
import {ToastsService} from '../../../../services/toasts.service';
import {
  CourierApiCredentialsService
} from '../../../../services/courier-api-credentials/courier-api-credentials.service';
declare var UIkit: any;
@Component({
  selector: 'app-client-credentail',
  templateUrl: './client-credentail.component.html',
  styleUrls: ['./client-credentail.component.scss']
})
export class ClientCredentailComponent implements OnInit {
  id = null;
  idClient = null;
  protected = false;
  needCheck = false;
  checked = false;
  types = [];
  couriers = [];
  protectedAssigns = [];
  checkingResponse = {
    client_id: null,
    phone_number: null,
    email: null,
  }
  model = {
    id_courier: 2,
    id_client: this.idClient,
    id_type: null,
    assign: null,
    username: null,
    password: null,
    contract_number: null,
    service: null,
    note: null,
    comment: null
  };

  constructor(
    private courierApiCredentialService: CourierApiCredentialsService,
    private credentailsService: CredentialsService,
    private route: ActivatedRoute,
    private router: Router,
    private toastsService: ToastsService
  ) {
  }

  ngOnInit(): void {
    this.getCredentialsTypesProtectedAssigns();
    try {
      this.idClient = this.route.snapshot.paramMap.get('idClient');
      const id = this.route.snapshot.paramMap.get('id');
      if (id !== undefined) {
        this.id = id;
      }
    } catch (error) {
      this.id = null;
    }
    this.getTypes();
    if (this.id !== null) {
      this.showItem();
    }
    else{
      this.getCouriers();
    }
  }

  getTypes(){
    this.credentailsService.getTypes().subscribe(
      (types: any) => {
        this.types = types;
      }
    );
  }

  showItem(){
    this.credentailsService.show(this.id).subscribe(
      (response: any) => {
        this.model = response;
        this.isProtected();
      }
    );
  }

  getCredentialsTypesProtectedAssigns(){
    this.credentailsService.getProtectedAssigns().subscribe(
      (response: any) => {
        this.protectedAssigns = response;
        this.isProtected();
      }
    );
  }

  isProtected(){
    this.protected = this.protectedAssigns.indexOf(this.model.assign) >= 0;
  }

  submit() {
    if (null === this.id) {
      this.create();
    }
    else {
      this.update();
    }
  }

  create(){
    this.model.id_client = this.idClient;
    this.credentailsService.store(this.model).subscribe(
      (response: any) => {
        this.router.navigateByUrl('/client/' + this.idClient + '/credentail/' + response.body.id).then(r => {
          this.toastsService.showMessage('Pomyślnie utworzono dane dostępowe', 's');
        });
      }, e => {
        for (const errorField in e.error.errors) {
          this.toastsService.showMessage(e.error.errors[errorField][0], 'e');
        }
      }
    );
  }

  check() {
    this.courierApiCredentialService.check({
        id_courier: this.model.id_courier,
        username: this.model.username,
        password: this.model.password,
      }).subscribe(
      (response: any) => {
        console.log()
        this.toastsService.showMessage('Wprowadzone dane są poprawne!', 's', 1000);
        this.checked = true;
        this.needCheck = false;
      },
      (e: any) => {
        switch (e.status) {
          case 406:
            this.toastsService.showMessage(
              'Wprowadzone dane są pozwalają na wykonanie poprawnego żądania do API wybranego kuriera!',
              'e',
              10000
            );
        }
      }
    );
  }

  update(){
      this.credentailsService.update(this.model).subscribe(
        (response: any) => {
          this.toastsService.showMessage('Pomyślnie zaktualizowano dane dostępowe', 's');
       }, e => {
        for (const errorField in e.error.errors) {
          this.toastsService.showMessage(e.error.errors[errorField][0], 'e', 50000);
        }
      });
  }

  async delete(){
    const promised = await this.toastsService.showConfirmModal();
    if (!promised) {
      return;
    }

    this.credentailsService.delete(this.id).subscribe(
      (response: any) => {
        this.router.navigateByUrl('/client/' + this.idClient + '/card/api').then(r => {
          this.toastsService.showMessage('Dane dostępowe zostały pomyślie usunięte!', 's');
        });
      }
    );
  }

  getCouriers(){
    this.credentailsService.getCouriers().subscribe(
      (response: any) => {
        this.couriers = response;
      }
    );
  }

  setNeedCheck(){
    this.needCheck = true;
  }

  async unpin(){
    const promised = await this.toastsService.showConfirmModal();
    if (!promised) {
      return;
    }

    this.credentailsService.unpinMainCredentials(this.idClient).subscribe(
      (response: any) => {
        this.router.navigateByUrl('/client/' + this.idClient).then(r => {
          this.toastsService.showMessage('Pomyślnie odpięto główne dane od klienta', 's');
        });
      }
    );
  }
}

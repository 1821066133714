export class ParcelModel {
    id: string;
    internal_number: string;
    deleted_at: string;
    recipient_name: string;
    recipient_email: string;
    phone_prefix_1: string;
    recipient_phone_number_1: string;
    phone_prefix_2: string;
    recipient_phone_number_2: string;
    recipient_private_person: string;
    client_recipient_number: string;
    destination_city: string;
    destination_city_code: string;
    destination_post_code: string;
    destination_street_type: string;
    destination_address: string;
    declared_content: string;
    declared_value: string;
    insurance_value: string;
    cash_on_delivery: string;
    cash_on_delivery_value: string;
    this_side_up: string;
    not_expose_to_frost: string;
    liquid: string;
    caution: string;
    out_number: string;
    id_client: string;
    name: string;
    weight: string;
    width: string;
    length: string;
    height: string;
    destination_address_note: string;
    destination_street: string;
    recipient_contact: string;
    return_number: string;
    in_number: string;
    accounted_weight: string;
    volumetric_weight: string;
    price: 0;
    net_price: 0;
    vat_percentage: 0;
    problem: 0;
    delivery_twice_checked: 0;
    correctly_requested_at: null;
    creator: null;
    ron: 0;
    out_service: null;
    default_api: 0;
    recipient_phone_number: string;
    note: string;
    id_out_courier: string;
    id_locker: string;
    locker: boolean;
    locker_name: string;
    courier_name: string;
    buffer_number: string;
    forced_client_id: null;
    error_message: string;
    emag_parcel: null;
    id_in_courier: null;
    return_parcel: null;
    in_pickup_date: null;
    shipment_id: null;
    processable: null;
}

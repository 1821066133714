import {Component, OnInit, ViewChild} from '@angular/core';
import {Subject} from "rxjs";
import {DataTableDirective} from "angular-datatables";
import {environment} from "../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";



@Component({
  selector: 'app-parcel-reported-resignations-list',
  templateUrl: './parcel-reported-resignations-list.component.html',
  styleUrls: ['./parcel-reported-resignations-list.component.scss']
})
export class ParcelReportedResignationsListComponent implements OnInit {
  apiurl: string;
  public dtInit = false;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  dtTranslation = null;
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  resignations = [];

  constructor(private http: HttpClient) {
    this.apiurl = environment.api;
  }

  ngOnInit() {
    this.showResignations();
  }

  showResignations() {
    const that = this;
    if (!this.dtInit) {
      setTimeout(
        () => {
          this.dtTrigger.next();
        }, 100
      );
    } else {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtInit = false;
        setTimeout(
          () => {
            this.dtTrigger.next();
          }, 100
        );
      });
    }


    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      processing: true,
      language: this.dtTranslation,
      ajax: (dataTablesParameters: any, callback) => {
        that.http
          .post(
            this.apiurl + 'app/v1/parcel/resignation/ajax',
            dataTablesParameters
          ).subscribe((resp: any) => {
          this.dtInit = true;
          that.resignations = resp.data;

          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      },
      columns: [
        {data: 'waybill', name: 'prr.waybill'},
        {data: 'client_name', name: 'c.name'},
        {data: 'scanned_at', name: 'prr.scanned_at'},
        {data: 'resend_at', name: 'prr.resend_at'},
      ]
    };
  }
}

<div id="check-permissions-modal" class="check-permissions-modal" uk-modal>
  <div class="uk-modal-dialog">
    <button class="uk-modal-close-default" type="button" uk-close></button>

    <div class="uk-modal-header">
      <h2 class="uk-modal-title">{{'check_permissions' | translate}}</h2>
    </div>

    <div class=" uk-modal-body">
        <div class="uk-grid">
          <div class="uk-width-1-2@m">
            <label class="required">{{'Login API DPD' | translate}}</label>
            <input class="uk-input" type="text" [(ngModel)]="model.username" required>
          </div>
          <div class="uk-width-1-2@m">
            <label class="required">{{'Hasło' | translate}}</label>
            <input class="uk-input" type="text" [(ngModel)]="model.password" required>
          </div>
        </div>
        <div class="uk-margin">
          <button class="uk-button uk-button-primary" (click)="check()">{{'check' | translate}}</button>
        </div>
        <table *ngIf="result!==null">
          <tr>
            <td>
              Contract number:
            </td>
            <td width="10px">
            </td>
            <td>
              {{result.client_id}}
            </td>
          </tr>
          <tr>
            <td>
              Email:
            </td>
            <td>
            </td>
            <td>
              {{result.email}}
            </td>
          </tr>
          <tr>
            <td>
              Phone number:
            </td>
            <td>
            </td>
            <td>
              {{result.phone_number}}
            </td>
          </tr>
        </table>
    </div>
  </div>
</div>

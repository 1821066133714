<div>
  <table class="uk-table uk-width-1-1 uk-table-hover uk-table-divider"  style="width: 100% !important;" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
    <thead>
    <tr>
      <th>{{'name' | translate}}</th>
      <th>{{'begin_date' | translate}}</th>
      <th>{{'special_association' | translate}}</th>
      <th *ngIf="type === 'cost'">{{'courier' | translate}}</th>
      <th *ngIf="type === 'income'">{{'country' | translate}}</th>
    </tr>
    </thead>
    <tbody *ngIf="priceLists?.length > 0">
      <tr *ngFor="let priceList of priceLists"
          [routerLink]="'/operational-price-list/'+type+'/client_form/'+clientId+'/'+priceList.id"
          [class.uk-active]="currentActivePriceLists.includes(priceList.id)"
          [title]="currentActivePriceLists.includes(priceList.id)? 'Bieżący aktywny cennik' : ''"
      >
        <td>
          {{priceList.name}}
        </td>
        <td>
          {{priceList.begin_date}}
          <span *ngIf="priceList.deactivated_at !==null" style="padding-left: 10px">(Nieaktywny od: {{priceList.deactivated_at}})</span>
        </td>
        <td>
          {{priceList.special_association | translate }}
        </td>
        <td *ngIf="type==='cost'">
          {{priceList.courier}}
        </td>
        <td *ngIf="type==='income'">
          {{priceList.country}}
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="priceLists?.length == 0">
    <tr>
      <td colspan="2">{{'no_data' | translate}}</td>
    </tr>
    </tbody>
  </table>
</div>



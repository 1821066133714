import {Component} from '@angular/core';
import {AbstractSidebarComponent} from '../abstract-sidebar/abstract-sidebar.component';
import {AuthService} from '../../../services/auth/auth.service';
import {ProblemsService} from '../../../services/problems/problems.service';
import {ExportService} from '../../../services/export/export.service';

@Component({
  selector: 'app-sidebar-client',
  templateUrl: './sidebar-client.component.html',
  styleUrls: ['./sidebar-client.component.scss']
})

export class SidebarClientComponent extends AbstractSidebarComponent {}

import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ToastsService} from "../../../services/toasts.service";

@Component({
    selector: 'app-statuses',
    templateUrl: './statuses.component.html',
    styleUrls: ['./statuses.component.scss']
})
export class StatusesComponent implements OnInit {
    dtOptions: DataTables.Settings = {};
    statuses: any = [];
    apiurl = '';
    dtTranslation = null;
    constructor(private http: HttpClient,  private toastsService: ToastsService) {
        this.apiurl = environment.api;
        this.dtTranslation = toastsService.dtTranslation;
    }

    ngOnInit() {
        this.showStatuses();
    }

    showStatuses() {
        const that = this;
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 25,
            serverSide: true,
            processing: true,
            language: this.dtTranslation,
            ajax: (dataTablesParameters: any, callback) => {
                that.http
                    .post(
                        this.apiurl + 'app/v1/status_type/datatable',
                        dataTablesParameters, {}
                    ).subscribe((resp: any) => {
                    that.statuses = resp.data;

                    callback({
                        recordsTotal: resp.recordsTotal,
                        recordsFiltered: resp.recordsFiltered,
                        data: []
                    });
                });
            },
            columns: [
                {data: 'id', name: 'pst.id'},
                {data: 'name', name: 'pst.name'},
                {data: 'displayed_name', name: 'pst.displayed_name'},
                {data: 'internal', name: 'pst.internal'},
            ]
        };
    }
}

<div class="uk-grid">
  <div class="uk-width-1-1 navbar">
    <p class="nav-text">
      <span *ngIf="id===null">Tworzenie przedziału cenowego</span>
      <span *ngIf="id!==null">Edycja przedziału cenowego</span>
      <button class="uk-button uk-button-primary padding-horizontal-small button-opt " [attr.uk-tooltip]="'Wróć'" [routerLink]="'/client/'+idClient+'/card/pricing'"><span uk-icon="sign-out"></span></button>
      <button *ngIf="id !== null" class="uk-button uk-button-danger padding-horizontal-small uk-margin-remove-right button-opt " (click)="delete()" [attr.uk-tooltip]="'Usuń przedział cenowy'"><span uk-icon="ban"></span></button>
    </p>
  </div>
  <div class="uk-width-1-1 inner-shadow">
    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
      <form #priceForm="ngForm" (ngSubmit)="submitPrice()">
        <input  type="hidden" name="id" [ngModel]="tempPrice.id">
        <input  type="hidden" name="service_id" [ngModel]="tempPrice.service_id">
        <input  type="hidden" name="id_client" [ngModel]="tempPrice.id_client">
        <div class=" uk-width-1-1">
          <label>{{'net_price' | translate}}</label>
          <input class="uk-input" type="number" name="net_price" [(ngModel)]="tempPrice.net_price" required>
        </div>
        <div class=" uk-width-1-1">
          <label>{{'vat_percentage' | translate}}</label>
          <input class="uk-input" type="number" name="vat_percentage" min="0" max="100" [(ngModel)]="tempPrice.vat_percentage" required>
        </div>
        <div class=" uk-width-1-1">
          <label>{{'min_weight_value' | translate}}</label>
          <input class="uk-input" type="number" name="min_weight_value" min="0" max="100" [(ngModel)]="tempPrice.min_weight_value" required>
        </div>
        <div class=" uk-width-1-1">
          <label>{{'max_weight_value' | translate}}</label>
          <input class="uk-input" type="number" name="max_weight_value" min="0" max="100"  [(ngModel)]="tempPrice.max_weight_value" required>
        </div>

        <div class="uk-margin">
          <button class="uk-button uk-button-primary" [disabled]="priceForm.form.invalid">{{'save' | translate}}</button>
        </div>
      </form>
    </div>
  </div>
</div>

import {Component} from '@angular/core';
import {AbstractSidebarComponent} from '../abstract-sidebar/abstract-sidebar.component';

@Component({
  selector: 'app-sidebar-warehouse-worker',
  templateUrl: './sidebar-warehouse-worker.component.html',
  styleUrls: ['./sidebar-warehouse-worker.component.scss']
})

export class SidebarWarehouseWorkerComponent extends AbstractSidebarComponent{}

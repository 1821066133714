import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ClientModel } from '../../../models/client.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientsService } from '../../../services/clients/clients.service';
import { ToastsService } from '../../../services/toasts.service';
import { CredentialsService } from '../../../services/credentails/credentials.service';
import {
  ClientAdditionalParametersService
} from '../../../services/client-additional-parameters/client-additional-parameters.service';
import { BillingService } from '../../../services/billing/billing.service';
import { ExportService } from '../../../services/export/export.service';
import { ParcelsService } from '../../../services/parcels/parcels.service';
import { environment } from '../../../../environments/environment';

declare var UIkit: any;

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})

export class ClientComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,
    private clientAdditionalParametersService: ClientAdditionalParametersService,
    private credentailsService: CredentialsService,
    private billingService: BillingService,
    private clientsService: ClientsService,
    private toastsService: ToastsService,
    private exportService: ExportService,
    private parcelService: ParcelsService,
    private route: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.apiUrl = environment.api;
  }
  apiUrl = '';
  card = 'data';
  client: any = new ClientModel();
  id: string = null;
  prices = [];
  tempPrice: any = {};
  hasDPDMain = false;

  previousClientId: number;
  nextClientId: number;

  shipmentsInPerioid: any;

  isAllCheckedMessageDisplayed = false;
  hasPinnedDefaultCredentials = false;
  hasAccountingPeriodSelected = false;

  billingFrom = null;
  billingTo = null;

  lockGenerating = false;

  generated = false;

  pendingExportUiid = null;

  pendingExportModel = null;

  timer = null;

  downloadAbleUuid = null;

  types = [];
  type = {
    code: null,
  };
  typeId: number;
  isClientBocked = false;
  parameters = [];

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.credentailsService.hasDefaultCredentials(this.id).subscribe(
      (response: any) => {
        this.hasAccountingPeriodSelected = response.has_default_credentials;
      }
    );
    try {
      const card = this.activatedRoute.snapshot.paramMap.get('card');
      if (card !== undefined && card !== null) {
        this.card = card;
      }
    }
    catch (e) {
      this.card = 'data';
    }
    this.getClient();
    this.getPrices();
    this.checkIsHasDPDRoMain();
    this.loadClientNavigation(this.id);
    this.getTypes();
    this.checkIsClientDispatchBlocked();
    this.clientsService.findPeriod(this.id).subscribe(
      (response: any) => {
        this.billingFrom = response.start;
        this.billingTo = response.end;
      }
    );

    this.activatedRoute.params.subscribe(params => {
      const clientId = +params['id']
    })
      this.loadClientNavigation(this.id);
      this.fetchShipments();
  }

  openDownloadBillingModal() {
    const modals = document.getElementsByClassName('downloadBillingModal');
    if (modals.length > 1) {
      modals[1].remove();
    }
    UIkit.modal('#downloadBillingModal').show();
  }

  downloadBilling() {
    this.parcelService.getPublicFileToken().subscribe(
      (tokenResponse: any) => {
        const uuid = this.pendingExportUiid ?? this.downloadAbleUuid;

        window.open(
          this.apiUrl
          + 'app/v1/export/download/'
          + uuid
          + '?_public_token='
          + tokenResponse.public_token
        );
        this.pendingExportUiid = null;
        this.generated = false;
      }
    );
  }

  fetchBillingStatusPeriodically() {
    this.fetchBillingStatus();

    this.timer = setInterval(() => {
      this.fetchBillingStatus();
    }, 5000);
  }

  fetchBillingStatus() {
    if (null === this.pendingExportUiid) {
      clearInterval(this.timer);
      this.pendingExportModel = null;
      return;
    }

    this.exportService.showByUuid(this.pendingExportUiid).subscribe(
      (billing: any) => {

        this.pendingExportModel = billing;

        switch (billing?.status) {
          case 'validated':
            this.toastsService.showMessage('Twoje zestawienie ' + billing.name + ' jest gotowe do pobrania!', 's');
            this.generated = true;
            break;
          case 'validation_error':
            this.toastsService.showMessage('Wystąpił błąd walidacji twojego zestawienia ' + billing.name + '!', 'w');
            this.generated = true;
            this.downloadAbleUuid = this.pendingExportUiid;
            this.pendingExportUiid = null;
            break;
          case 'internal_error':
            this.toastsService.showMessage('Wystąpił błąd wewnętrzny podczas generowania twojego zestawienia ' + billing.name + '!', 'e');
            this.pendingExportUiid = null;
            break;
        }
      }
    );
  }

  orderExport(reAccount = false) {
    if (undefined === this.billingFrom || undefined === this.billingTo) {
      return;
    }

    this.exportService.makeOrder({
      type: 'billing',
      client_id: this.id,
      start: this.billingFrom,
      end: this.billingTo,
      re_account: reAccount,
    }).subscribe(
      (response: any) => {
        this.pendingExportUiid = response.uuid;
        this.toastsService.showMessage('Złożono zlecenie generowania zestawienia!', 's');
        this.fetchBillingStatusPeriodically();
      },
      (error) => {
        if (error.status === 409) {
          this.toastsService.showMessage('Przed wygenerowaniem raportu do rozliczeń przypisz przesyłki buforowe do klientów.', 'e');
          }
      }
    );
  }

  reAccountAndGenerateBilling() {
    this.orderExport(true);
  }

  generateBilling() {
    this.orderExport(false);
  }

  getClient() {
    this.clientsService.getClient(this.id).subscribe(
      (s: ClientModel) => {
        this.client = s;
      }
    );
  }

  async loadClientNavigation(id: string) {
    this.clientsService.getClientNavigation(this.id).subscribe(
      (navigationData: any) => {
        this.previousClientId = navigationData.previous_client;
        this.nextClientId = navigationData.next_client;
      }
    );
  }

  goToPrev() {
    if (null === this.previousClientId) {
      return;
    }

    this.route.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.route.navigate(['/client', this.previousClientId.toString()]);
    });
  }

  goToNext() {
    if (null === this.nextClientId) {
      return;
    }
    this.route.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.route.navigate(['/client', this.nextClientId.toString()]);
   });
  }

  fetchShipments(){
      this.clientsService.getClientShipmentsInPerioid(this.id).subscribe(
        (response: any)  => {
            this.shipmentsInPerioid = response;
          },
        (error) => {
          if (error.status === 404) {
            this.shipmentsInPerioid = 'Brak okresu rozliczeniowego';
          }
        }
      );
  }

  pinMain() {
    this.credentailsService.pinMainCredentials(this.id).subscribe(
      (response: any) => {
        this.route.navigateByUrl('/client/' + this.id + '/credentail/' + response.id).then(r => {
          this.toastsService.showMessage('Pomyślnie podpięto główne dane dostępowe!', 's');
        });
      }
    );
  }

  generateIAIPassword() {
    this.clientsService.generateIAIPassword().subscribe(
      (response: any) => {
        this.client.iai_password = response.password;
      }
    );
  }

  checkIsHasDPDRoMain() {
    this.credentailsService.checkIsClientHasCredentailByAssign(this.id, 'dpd_ro_main').subscribe(
      (response: any) => {
        this.hasDPDMain = response.has;
      }
    );
  }

  getPrices() {
    this.clientsService.getPrices(this.id).subscribe(
      (s: any) => {
        this.prices = s;
      }
    );
  }

  async onRemoveClient() {
    const promised = await this.toastsService.showConfirmModal();
    if (!promised) {
      return;
    }
    this.clientsService.removeClient(this.id).subscribe(
      (s) => {
        this.route.navigateByUrl('/clients');
        this.toastsService.showMessage('removed_client', 's');
      }
    );
  }


  async onBlockDispatch() {
    this.clientAdditionalParametersService.store(this.client.id, {
      client_id: this.client.id,
      type_id: this.typeId,
      value: true
    }).subscribe(
      (s) => {
        this.route.navigate(['/clients/']).then(r => {
          this.toastsService.showMessage('Nadawanie zostało zablokowane dla klienta ' + this.client.name, 'e');
          this.cdr.detectChanges();
        });
      }
    );
  }

  onUnblockDispatch() {
    if (this.parameters.some(parameter => parameter.code === 'SENDING_SHIPMENTS_BLOCK')) {
      const parameterId = this.parameters.find(parameter => parameter.code === 'SENDING_SHIPMENTS_BLOCK')?.id;

      this.clientAdditionalParametersService.delete(this.id, parameterId).subscribe(
        (response: any) => {
          this.route.navigate(['/client/' + this.id + '/card/parameters']).then(r => {
            this.toastsService.showMessage('sending_shipments_unblock', 's');
          });
        }
      );
    }

  }

  getTypes(){
    this.clientAdditionalParametersService.getTypes().subscribe(
      (types: any) => {
        this.types = types;

        const selectedType = this.types.find(type => type.code === 'SENDING_SHIPMENTS_BLOCK');
        if (selectedType) {
          this.typeId = selectedType.id;
        }
      }
    );
  }

  checkIsClientDispatchBlocked() {
    this.clientAdditionalParametersService.listByClientId(this.id).subscribe(
      (response: []) => {
        this.parameters = response;
        if (this.parameters.some(parameter => parameter.code === 'SENDING_SHIPMENTS_BLOCK')) {
          this.isClientBocked = true;
        }
      }
    );
  }

  addOperationalPriceList(type) {
    this.route.navigateByUrl('/operational-price-list/' + type + '/client_form/' + this.id);
  }
}

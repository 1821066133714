import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import {ParcelsService} from "../../../../services/parcels/parcels.service";
import {PublicService} from "../../../../services/publlic/public.service";

@Component({
  selector: 'app-find-postal-code',
  templateUrl: './find-postal-code.component.html',
  styleUrls: ['./find-postal-code.component.scss']
})
export class FindPostalCodeComponent implements OnInit {
  searchText = '';
  id = null;
  statuses = [];
  error = false;
  activeLanguage = null;
  browserLanguage = null;
  availableLanguages = ['pl', 'ro'];
  options = [];
  selectedOption = null;

  constructor(private translate: TranslateService, private route: ActivatedRoute,
              private publicService: PublicService) {
  }

  ngOnInit() {
    this.browserLanguage = (navigator.language).split('-')[0];
    this.setDefaultLang();
    this.id = this.route.snapshot.paramMap.get('id');

  }

  searchTown() {
    this.error = false;

    if (3 > this.searchText.length) {
      this.options = [];
      return;
    }

    this.publicService.townsAutocomplete(this.searchText).subscribe((data: []) => {
      if (data.length === 0) {
        this.error = true;
      }

      this.options = data;
    });
  }

  selectOption(option) {
    this.searchText = option.name + ' ('  + option.voivodeship + ', ' + option.municipality + ')';
    this.selectedOption = option;
    this.options = [];
  }


  setDefaultLang() {
    const lastSelectedLanguage = localStorage.getItem('language');
    let languageToSet = 'ro';
    if (lastSelectedLanguage == null) {
      if (this.availableLanguages.indexOf(this.browserLanguage) > -1) {
        languageToSet = this.browserLanguage;
      }
    } else {
      languageToSet = lastSelectedLanguage;
    }
    localStorage.setItem('language', languageToSet);
    this.translate.setDefaultLang(languageToSet);
    this.activeLanguage = languageToSet;
  }

  onChangeLanguage(selectedLanguage) {
    if (this.availableLanguages.indexOf(selectedLanguage) > -1) {
      localStorage.setItem('language', selectedLanguage);
      this.translate.setDefaultLang(selectedLanguage);
      this.activeLanguage = selectedLanguage;
    }
  }


}

<div class="uk-grid">
    <div class="uk-width-1-1 navbar">
        <p class="nav-text">
            {{'user' | translate}}: {{user.email}}
            <button class="uk-button uk-button-danger padding-horizontal-small button-opt" (click)="onRemoveUser()">
                <span uk-icon="trash" *ngIf="user.deleted_at == null" [attr.uk-tooltip]="'Zablokuj użytkownika'"></span>
                <span uk-icon="history"  *ngIf="user.deleted_at != null" [attr.uk-tooltip]="'Odblokuj użytkownika'"></span>
            </button>
        </p>
    </div>


  <div class="uk-width-1-1 inner-shadow">

    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
            <div class="mobile-overflow">
                <form #userForm="ngForm" (ngSubmit)="onEditUser(userForm)">
                    <input type="hidden" name="id" #id="ngModel" [(ngModel)]="user.id">
                    <div class="uk-grid">
                        <div class=" uk-width-1-2@m">
                            <label class="required">{{'email' | translate}}</label>
                            <input class="uk-input" type="text" name="email" #email="ngModel" [(ngModel)]="user.email" required email>
                        </div>
                        <div class=" uk-width-1-2@m">
                            <label class="required">{{'role' | translate}}</label>
                            <select class="uk-select" name="role" #role="ngModel" [(ngModel)]="user.role" required>
                                <option value="0">{{'admin' | translate}}</option>
                                <option value="1">{{'sort_worker' | translate}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="uk-margin">
                        <button class="uk-button uk-button-primary" [disabled]="userForm.form.invalid">{{'save' | translate}}</button>
                    </div>

                </form>
            </div>

        </div>

    </div>

</div>


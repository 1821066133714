<div class="uk-grid">
  <div class="uk-width-1-1 navbar">
    <p class="nav-text">
      Zarządzaj definicjami nieprawidłowości
      <button class="uk-button uk-button-primary padding-horizontal-small button-opt" [attr.uk-tooltip]="'Wróć'" [routerLink]="'/problems'"><span uk-icon="sign-out"></span></button>
      <button class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right" routerLink="/problem-rule" [attr.uk-tooltip]="'Utwórz definicję nieprawidłowości'"><span uk-icon="plus"></span></button>
    </p>
  </div>


  <div class="uk-width-1-1 inner-shadow">

    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
      <div class="mobile-overflow">

        <table style="width: 100%" class="uk-table uk-width-1-1 uk-table-hover uk-table-divider" datatable [dtOptions]="dtOptions" >
          <thead>
          <tr>
            <th style="width: 25%">{{'name' | translate}}</th>
            <th style="width: 25%">{{'base_status' | translate}}</th>
            <th style="width: 25%">{{'next_status' | translate}}</th>
            <th style="width: 15%">{{'period' | translate}}</th>
          </tr>
          </thead>
          <tbody *ngIf="statuses?.length > 0">
          <tr *ngFor="let status of statuses" [routerLink]=" '/problem-rule/' + status.id ">
            <td>{{ status.name }}</td>
            <td>{{ status.base_status }}</td>
            <td>{{ status.next_status }}</td>
            <td>{{ status.period}}</td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>

  </div>

</div>

import {Injectable} from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class InternalInstructionCodeService {

  decode(code: string): any {
    let result = code.match(/[|]([A-Za-z0-9\=]{1,})$/);
    if (undefined === result[1]) {
      return null;
    }

    try {
      return JSON.parse(atob(result[1]));
    }
    catch (error) {
      console.error('Error decoding internal instruction code:', error);
      return null;
    }
  }
}

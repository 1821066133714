import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PublicService {
    private apiurl = '';

  constructor(private http: HttpClient, private router: Router) {
    this.apiurl = environment.api;
  }


  townsAutocomplete(searchText) {
    return this.http.post(
      this.apiurl + 'app/v1/public/address/database/RO/town/autocomplete',
      {searchText},
    );
  }
}

import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ToastsService} from '../../../services/toasts.service';
import {Subject} from 'rxjs';
import {DataTableDirective} from 'angular-datatables';
import {UserModel} from "../../../models/user.model";
import {ClientModel} from '../../../models/client.model';
import {ExternalInboundService} from "../../../services/external_inbound/external-inbound.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-external-inbound-one',
  templateUrl: './external-inbound-one.component.html',
  styleUrls: ['./external-inbound-one.component.scss']
})
export class ExternalInboundOneComponent implements OnInit {
  couriers: [];
  id = null;

  externalInbound = {
    id: null,
    scanned_at: null,
    barcode: null,
    waybill: null,
    scanned_by_user: {
      email: null,
    },
    inbound_courier_id: null,
    parcels: [],
    comment: null,
  }


  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private toastsService: ToastsService,
    private externalInboundService: ExternalInboundService
  ) {
  }

  ngOnInit() {
    this.externalInboundService.couriers().subscribe(
      (response: []) => {
        this.couriers = response;
      }
    );

    this.route.params.subscribe(
      (params) => {
        if (params != null) {
          if (this.id !== params.id) {
            this.id = params.id;
            this.externalInboundService.show(this.id).subscribe(
              (response: any) => {
                this.externalInbound = response;
                console.log(response);
              }
            )
          }
        }
      }
    );
  }

  update(){
    this.externalInboundService.update({
      id: this.externalInbound.id,
      comment: this.externalInbound.comment,
    }).subscribe(
      (response: any) => {
        this.toastsService.showMessage('Zaktualizowano pomyślnie!', 's', 1000);
      }
    )
  }
}

import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CourierApiCredentialsService {
  private readonly apiUrl;
  constructor(private http: HttpClient, private router: Router) {
    this.apiUrl = environment.api + 'app/v1/credential/';
  }

  check(form) {
    return this.http.patch(this.apiUrl, form,  {observe: 'response'});
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {ClientModel} from '../../../../models/client.model';
import {ActivatedRoute, Router} from '@angular/router';
import {ClientsService} from '../../../../services/clients/clients.service';
import {ToastsService} from '../../../../services/toasts.service';
import {CountryService} from "../../../../services/country/country.service";
declare var UIkit: any;
@Component({
    selector: 'app-client-data',
    templateUrl: './client-data.component.html',
    styleUrls: ['./client-data.component.scss']
})

export class ClientDataComponent implements OnInit {
    page = 'data';
    contacts = [];
    contact = null;
    idContact = null;

    countries = [];

    @Input() client: ClientModel;

    constructor(
      private countryService: CountryService,
      private clientsService: ClientsService,
      private toastsService: ToastsService,
      private route: Router
     ) {
    }

    ngOnInit() {
      setTimeout(
        () => {
          this.clientsService.findClientCountries(this.client.id).subscribe(
            (clientCountriesResponse: any) => {
              this.countryService.list().subscribe(
                (countriesResponse: any) => {
                  for (let country of countriesResponse) {
                    if (country.iso_code !== 'PL') {
                      for (let clientCountry of clientCountriesResponse) {
                        if (clientCountry.id === country.id) {
                          country.checked = true;
                          break;
                        }
                        country.checked = false;
                      }

                      this.countries.push(country);
                    }
                  }
                }
              );
            }
          );
        }, 1000,
      );
    }

    onEditClient() {
      let form: any = this.client;
      form.allowed_countries = [];
      for (let country of this.countries) {
        if (country.checked) {
          form.allowed_countries.push(country.id);
        }
      }

      this.clientsService.updateClient(form).subscribe(
          (s) => {
              this.toastsService.showMessage('edited_client', 's');
          },
        error => {
          for (const err of Object.keys(error.error.errors)) {
            this.toastsService.showMessage(error.error.errors[err][0], 'e', 5000);
          }
        }
      );
    }

  changeAllowedCountry(index) {
    this.countries[index].checked = !this.countries[index].checked;
  }

    async onRemoveClient() {
        const promised = await this.toastsService.showConfirmModal();
        if (!promised) {
            return;
        }
        this.clientsService.removeClient(this.client.id).subscribe(
            (s) => {
                this.route.navigateByUrl('/clients');
                this.toastsService.showMessage('removed_client', 's');
            }
        );
    }
}

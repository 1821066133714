<script src="client-credentails/client-credentials.component.ts"></script>
<div class="uk-grid">
  <div class="uk-width-1-1 navbar">
      <div class="nav-text">
          {{'client' | translate}}: {{client.name}}
        <button *ngIf="card=='pricing'" class="uk-button uk-button-primary padding-horizontal-small button-opt" [routerLink]="'/client/'+id+'/price'">
          <span uk-icon="plus"></span>
        </button>
        <button *ngIf="card=='parameters'" class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-left " [attr.uk-tooltip]="'Zdefiniuj dodatkowy parametr'" [routerLink]="'/client/'+id+'/parameter'">
          <span uk-icon="plus"></span>
        </button>
        <button *ngIf="card=='api'" class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-left " [attr.uk-tooltip]="'Utwórz dane dostępowe'" [routerLink]="'/client/'+id+'/credentail'">
          <span uk-icon="plus"></span>
        </button>
        <button *ngIf="card=='api' && !hasPinnedDefaultCredentials" (click)="pinMain()" class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-left uk-margin-remove-right" [attr.uk-tooltip]="'Przypnij główne dane dostępowe'">
          <span uk-icon="link"></span>
        </button>
        <button *ngIf="card=='cost_operational_price_list'" class="uk-button uk-button-primary padding-horizontal-small button-opt"
                [attr.uk-tooltip]="'Utwórz cennik kosztowy'"
                (click)="addOperationalPriceList('cost')"
        >
          <span uk-icon="plus"></span>
        </button>
        <button *ngIf="card=='income_operational_price_list'" class="uk-button uk-button-primary padding-horizontal-small button-opt"
                [attr.uk-tooltip]="'Utwórz cennik przychodowy'"
                (click)="addOperationalPriceList('income')"
        >
          <span uk-icon="plus"></span>
        </button>
        <button *ngIf="card=='external_api_credentials'" class="uk-button uk-button-primary padding-horizontal-small button-opt">
          <span uk-icon="plus" [attr.uk-tooltip]="'Dodaj dane uwieżytelniające'" [routerLink]="'/client/'+id+'/external_api_credentials/'"></span>
      </button>
      <button *ngIf="card=='external_api_credentials_detials'" class="uk-button uk-button-danger padding-horizontal-small button-opt">
        <span uk-icon="trash" [attr.uk-tooltip]="'Usuń dane uwieżytelniające'"></span>
      </button>
        <button *ngIf="card=='data'" class="uk-button uk-button-danger padding-horizontal-small button-opt" (click)="onRemoveClient()">
            <span uk-icon="trash" *ngIf="client.deleted_at == null" [attr.uk-tooltip]="'Zablokuj klienta'"></span>
            <span uk-icon="history"  *ngIf="client.deleted_at != null" [attr.uk-tooltip]="'Odblokuj klienta'"></span>
        </button>
        <button *ngIf="card=='data'" class="center uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right " style="background-color: orange">
          <span uk-icon="ban" *ngIf="isClientBocked == false" [attr.uk-tooltip]="'Zablokuj nadawanie przesyłek'"  (click)="onBlockDispatch()"></span>
          <span uk-icon="refresh" *ngIf="isClientBocked == true" [attr.uk-tooltip]="'Odblokuj nadawanie przesyłek'"  (click)="onUnblockDispatch()"></span>
        </button>
        <button class="uk-button uk-button-primary padding-horizontal-small button-opt  uk-margin-remove-right " (click)="openDownloadBillingModal()">
          <span uk-icon="file-text" *ngIf="client.deleted_at == null" [attr.uk-tooltip]="'Generuj wyciąg przesyłek do faktury'"></span>
        </button>
        <button class="center uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right " (click)="goToNext()">
          <span uk-icon="arrow-right"  [attr.uk-tooltip]="'Następny klient'"></span>
        </button>
        <button class="center uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right" (click)="goToPrev()">
          <span uk-icon="arrow-left"  [attr.uk-tooltip]="'Poprzedni klient'"></span>
        </button>
    </div>
  </div>
  <div style="text-align: center; width: 80%; padding-top: 0; margin-top: -20px">
    <span style="font-size: 13px" title="Ilość przesyłek w bieżącym okresie rozliczeniowym">L. przesyłek : &nbsp;</span> <b>{{ shipmentsInPerioid }}</b>
  </div>
  <div class="uk-width-1-1 inner-shadow">
    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
      <div class="uk-grid uk-margin-bottom">
        <div class="uk-width-1-6@m">
          <button class="uk-button uk-button-primary uk-width-1-1"
                  [class.uk-button-primary]="card === 'data'" (click)="card='data'">
            <span>Dane klienta</span>
          </button>
        </div>
        <div class="uk-width-1-6@m">
          <button class="uk-button uk-button-primary uk-width-1-1"
                  [class.uk-button-primary]="card === 'api'" (click)="card='api'">
            <span>Dane dostępowe API</span>
          </button>
        </div>
        <div class="uk-width-1-6@m">
          <button class="uk-button uk-width-1-1" title="Cennik przychodowy"
                  [class.uk-button-primary]="card === 'income_operational_price_list'" (click)="card='income_operational_price_list'">
            <span>C. przychodowy</span>
          </button>
        </div>
        <div class="uk-width-1-6@m">
          <button class="uk-button uk-width-1-1" title="Cennik kosztowy"
                  [class.uk-button-primary]="card === 'cost_operational_price_list'" (click)="card='cost_operational_price_list'">
            <span>C. kosztowy</span>
          </button>
        </div>
        <div class="uk-width-1-6@m">
          <button class="uk-button uk-width-1-1" title="Dodatkowe parametry"
                  [class.uk-button-primary]="card === 'parameters'" (click)="card='parameters'">
            <span>Dod. parametry</span>
          </button>
        </div>
        <div class="uk-width-1-6@m">
          <button class="uk-button uk-width-1-1" title="Konfiguracja API"
                  [class.uk-button-primary]="card === 'external_api_credentials'" (click)="card='external_api_credentials'">
            <span>Konfiguracja API</span>
          </button>
          </div>
      </div>
      <app-client-data *ngIf="card=='data'" [client]="client">
      </app-client-data>
      <app-client-credentials *ngIf="card=='api'" [clientId]="id">
      </app-client-credentials>
      <app-client-prices *ngIf="card=='pricing'" [clientId]="id">
      </app-client-prices>
      <app-client-operational-price-list *ngIf="card==='income_operational_price_list'" [clientId]="id" [type]="'income'">
      </app-client-operational-price-list>
      <app-client-operational-price-list *ngIf="card==='cost_operational_price_list'" [clientId]="id" [type]="'cost'">
      </app-client-operational-price-list>
      <app-client-additional-parameters *ngIf="card=='parameters'" [clientId]="id">
      </app-client-additional-parameters>

      <app-client-new-external-api-credentials *ngIf="card === 'new_external_api_credentials'" [clientId]="id">
      </app-client-new-external-api-credentials>
      <app-client-external-api-credentials-detials *ngIf="card === 'external_api_credentials_detials'"  [clientId]="id">
      </app-client-external-api-credentials-detials >
      <app-client-external-api-credentials *ngIf="card === 'external_api_credentials'" [clientId]="id">
      </app-client-external-api-credentials>

    </div>
  </div>
  <div id="downloadBillingModal" uk-modal class="downloadBillingModal">
    <div class="uk-modal-dialog">
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <div class="uk-modal-header">
        <h2 class="uk-modal-title">{{'Pobierz zestawienie do faktury' | translate}}</h2>
      </div>
      <div class=" uk-modal-body">
        <div class="uk-grid">
          <div class="uk-width-1-1 uk-text-center uk-text-bold uk-margin-medium-bottom"
               *ngIf="null !== pendingExportUiid">
              {{pendingExportModel?.status | translate}}
              <span *ngIf="'accounting' === pendingExportModel?.status && pendingExportModel?.total_count > 0">
                {{ ((pendingExportModel?.performed_count / pendingExportModel?.total_count)*100).toFixed(2) }} %
              </span>
          </div>
          <div class="uk-width-1-2@m">
            <label>Początek o. rozliczeniowego</label>
            <input type="date" class="uk-input" [(ngModel)]="billingFrom">
          </div>
          <div class="uk-width-1-2@m">
            <label>Koniec okresu rozliczeniowego</label>
            <input type="date" class="uk-input" [(ngModel)]="billingTo">
          </div>
          <div class="uk-width-1-1@m">
            <br>
            <button [disabled]="null !== pendingExportUiid"
                    class="uk-button-primary uk-button"
                    (click)="reAccountAndGenerateBilling()"
            >Przelicz i Generuj</button>
            <button [disabled]="null !== pendingExportUiid"
                    class="uk-button-primary uk-button uk-margin-left"
                    (click)="generateBilling()"
            >Generuj</button>
            <button *ngIf="generated"
                    class="uk-button-primary uk-margin-left uk-button"
                    (click)="downloadBilling()"
            >Pobierz</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

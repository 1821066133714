<div class="uk-grid">
    <div class="uk-width-1-1 navbar">
        <p class="nav-text">
            {{'parcel_reported_resignation' | translate}}
            <button class="uk-button uk-button-primary padding-horizontal-small button-opt"
                    [attr.uk-tooltip]="'Wróć'"
                    [routerLink]="'/my-parcel-reported-resignations'"
            >
              <span uk-icon="sign-out"></span>
            </button>
            <button class="uk-button uk-button-danger padding-horizontal-small button-opt uk-margin-remove-right"
                    *ngIf="null !== id"
                    [attr.uk-tooltip]="'Usuń'"
                    (click)="delete()"
            >
              <span uk-icon="ban"></span>
            </button>
        </p>
    </div>

    <div class="uk-width-1-1 inner-shadow">
        <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
            <div class="mobile-overflow">
              <div class="uk-grid">
                <div class="uk-width-1-2@m">
                  <label class="required">Numer przesyłki</label>
                  <input class="uk-input" type="text" [(ngModel)]="model.waybill" [disabled]="null !== id">
                </div>
                <div class="uk-width-1-1@m">
                  <label>Dodatkowe informacje</label>
                  <textarea class="uk-input" [(ngModel)]="model.note" [disabled]="null !== id"></textarea>
                </div>
                <div class="uk-width-1-2@m" *ngIf="null !== id">
                  <label>Zeskanowano dnia</label>
                  <input class="uk-input" type="datetime" disabled [(ngModel)]="model.scanned_at">
                </div>
                <div class="uk-width-1-2@m" *ngIf="null !== id">
                  <label>Odesłano dnia</label>
                  <input class="uk-input" type="datetime" disabled [(ngModel)]="model.resend_at">
                </div>
                <div class="uk-width-3-4@m uk-margin-bottom uk-margin-top" *ngIf="null === id">
                  <button class="uk-button uk-button-primary" (click)="save()">Zapisz</button>
                </div>
              </div>
          </div>
        </div>
    </div>
</div>

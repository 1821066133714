<div id="clientCredentail">
    <div *ngIf="!selectedItem">
        <table class="uk-table uk-width-1-1 uk-table-hover uk-table-divider">
            <thead>
                <tr>
                    <td style="width: 3%">LP.</td>
                    <td>Nazwa użytkownika</td>
                    <td>Typ</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let credential of credentials; let key = index" (click)="open(credential.id)">
                    <td>{{key+1}}</td>
                    <td>{{credential.username}}</td>
                    <td>{{credential.type}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
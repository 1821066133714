<div class="uk-grid">
    <div class="uk-width-1-1 navbar">
        <p class="nav-text">
            {{'manage_statuses' | translate}}
          <button class="uk-button uk-button-primary padding-horizontal-small button-opt" routerLink="/status/new" [attr.uk-tooltip]="'Utwórz status'"><span uk-icon="plus"></span></button>
        </p>
    </div>


  <div class="uk-width-1-1 inner-shadow">

    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
            <div class="mobile-overflow">

                <table style="width: 100%" class="uk-table uk-width-1-1 uk-table-hover uk-table-divider" datatable [dtOptions]="dtOptions" >
                    <thead>
                    <tr>
                        <th style="width: 10%">{{'id' | translate}}</th>
                        <th style="width: 25%">{{'name' | translate}}</th>
                        <th style="width: 25%">{{'displayed_name' | translate}}</th>
                        <th style="width: 25%">{{'displayed_name_ro' | translate}}</th>
                        <th style="width: 15%">{{'internal_status' | translate}}</th>
                    </tr>
                    </thead>
                    <tbody *ngIf="statuses?.length > 0">
                    <tr *ngFor="let status of statuses" [routerLink]=" '/statuses/status/' + status.id ">
                        <td>{{ status.id }}</td>
                        <td>{{ status.name }}</td>
                        <td>{{ status.displayed_name }}</td>
                        <td>{{ status.displayed_name_ro }}</td>
                        <td>
                            <span *ngIf="status.internal == 0">{{'no' | translate}}</span>
                            <span *ngIf="status.internal == 1">{{'yes' | translate}}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>

    </div>

</div>


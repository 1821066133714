<div class="uk-grid">
    <div class="uk-width-1-1 navbar">
      <p class="nav-text">
          {{'Faktura' | translate}} {{client.name}} za okres {{invoice.period_from}} - {{invoice.period_to}}
          <button class="uk-button uk-button-primary padding-horizontal-small button-opt"
                  [attr.uk-tooltip]="'Wróć'"
                  [routerLink]="'/invoices'"
          >
            <span uk-icon="sign-out"></span>
          </button>
        <a class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right"
           [attr.uk-tooltip]="'Przejdż do podglądu przesyłki'"
           (click)="showInFakturownia()">
          <span uk-icon="forward"></span>
        </a>

          <a class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right"
              (click)="downloadInvoice()"
             [attr.uk-tooltip]="'Pobierz fakturę'"
          >
            <span uk-icon="file-pdf"></span>
          </a>
          <a class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right"
            (click)="downloadExportedFile()"

             [attr.uk-tooltip]="'Pobierz zestawienie'"
          >
            <span uk-icon="file-text"></span>
          </a>
          <a class="uk-button uk-button-primary padding-horizontal-small button-opt uk-margin-remove-right" *ngIf="false === ['sent', 'issued', 'printed'].includes(invoice.status)"
             (click)="reIssue()"

             [attr.uk-tooltip]="'Wystaw ponownie'"
          >
            <span uk-icon="refresh"></span>
          </a>
      </p>
    </div>
    <div class="uk-width-1-1 inner-shadow">
      <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
            <div class="mobile-overflow">
              <div class="uk-grid">
                <div class="uk-width-1-4@m">
                  <label>{{'Stauts' | translate}}</label>
                  <input class="uk-input" type="text" value=" {{'invoice_status_' +invoice.status | translate}}"  disabled>
                </div>
                <div class="uk-width-3-4@m">
                  <div class="uk-grid">
                    <div class="uk-width-1-4@m">
                      <label>{{'Numer' | translate}}</label>
                      <input class="uk-input" type="text" value="{{invoice.invoice_number ?? '-'}}"  disabled>
                    </div>
                    <div class="uk-width-1-4@m">
                      <label>{{'Kwota całkowita' | translate}}</label>
                      <input class="uk-input" type="text" value="{{invoice.total_amount ?? '-'}}"  disabled>
                    </div>
                    <div class="uk-width-1-4@m">
                      <label>{{'Data wystawienia' | translate}}</label>
                      <input class="uk-input" type="text" value="{{invoice.issue_date ?? '-'}}"  disabled>
                    </div>
                    <div class="uk-width-1-4@m">
                      <label>{{'Termin płatności' | translate}}</label>
                      <input class="uk-input" type="text" value="{{invoice.payment_to_date ?? '-'}}"  disabled>
                    </div>
                    </div>
                </div>
              </div>
            </div>

          <div class="uk-width-1-1@m mt-23" *ngIf="null !== invoice.email_id">
            <p class="uk-heading-divider">
              {{'Adresy email na które wysłano fakturę' | translate}}
            </p>
            <div class="uk-grid">
              <div class="uk-width-1-2@m">
                <label>{{'Adres email' | translate}}</label>
              </div>
              <div class="uk-width-1-4@m">
                <label>{{'Data wysłania' | translate}}</label>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1@m" *ngFor="let recipient of email?.recipients ?? []">
            <div class="uk-grid">
              <div class="uk-width-1-2@m">
                <input class="uk-input" type="text" value="<{{recipient.name}}> {{recipient.address}}"  disabled>
              </div>
              <div class="uk-width-1-4@m">
                <input class="uk-input" type="text" value="{{recipient.sent_at}}"  disabled>
              </div>
            </div>
          </div>

        <div class="uk-width-1-1@m mt-23" *ngIf="'report_validation_error' === invoice.status">
          <p class="uk-heading-divider">
            {{'Błędy walidacji' | translate}}
          </p>
          <table class="uk-width-1-1 uk-table uk-width-1-1 uk-table-hover uk-table-divider" id="validation-errors-table">
            <thead>
            <tr>
              <th>
                LP
              </th>
              <th>
                Błąd walidacji
              </th>
              <th style="font-size: 10px">
                Zweryfikowano
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let error of validationErrors; index as i">
              <td style="width: 3%"> {{ i + 1 }} </td>

              <td *ngIf="1 >= error.parcelInternalNumbers.length"
                  (click)="openParcel(error)"
                  title="Kliknij aby otworzyć!"
                  class="link">
                {{ exportedFileService.formatErrorMessage(error) | translate}}
                <span *ngFor="let dimensionNumber of Object.keys(error.additionalData?.dimensions ?? [])">
                            <br>
                            waga: {{error.additionalData.dimensions[dimensionNumber].weight}} kg; wymiary: {{error.additionalData.dimensions[dimensionNumber].width}}x{{error.additionalData.dimensions[dimensionNumber].height}}x{{error.additionalData.dimensions[dimensionNumber].length}}
                        </span>
              </td>
              <td *ngIf="error.parcelInternalNumbers.length > 1" class="link">
                {{ exportedFileService.formatErrorMessage(error) | translate}} <br>
                Przesyłki składowe wielopaka to:
                <kbd *ngIf="undefined == error.additionalData.dimensions">
                  <a *ngFor="let number of error.parcelInternalNumbers" href="parcel/{{number}}" target="_blank">{{number}}, </a>
                </kbd>
                <span *ngFor="let dimensionNumber of Object.keys(error.additionalData?.dimensions ?? [])">
                           <br>
                          <a href="parcel/{{dimensionNumber}}" target="_blank"><kbd>{{dimensionNumber}}</kbd></a> waga: {{error.additionalData.dimensions[dimensionNumber].weight}} kg; wymiary: {{error.additionalData.dimensions[dimensionNumber].width}}x{{error.additionalData.dimensions[dimensionNumber].height}}x{{error.additionalData.dimensions[dimensionNumber].length}}
                        </span>
              </td>
              <td style="align-content: center">
                <input *ngIf="false === ['no_operational_price_list_match', 'missing_amount'].includes(error.type)" type="checkbox" [(ngModel)]="error.reviewed" (change)="reviewValidationError(error)">
              </td>
            </tr>
            </tbody>
          </table>

        </div>
    </div>
  </div>
</div>


import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../services/auth/auth.service';
import {ToastsService} from '../../../services/toasts.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-password-recover',
  templateUrl: './password-recover.component.html',
  styleUrls: ['./password-recover.component.scss']
})
export class PasswordRecoverComponent implements OnInit {

  constructor(
      private authService: AuthService,
      private toastsService: ToastsService,
      private router: Router
  ) { }

  ngOnInit() {
  }

  onSubmit(form) {
    this.authService.recoverPassword(form.value).subscribe(
        (s) => {
          this.toastsService.showMessage('password_recover_email_sent', 's');
          this.router.navigateByUrl('/');
        }
    );
  }
}

<div class="uk-grid">
    <div class="uk-width-1-1 navbar">
        <p class="nav-text">
            {{'profile' | translate}}
        </p>
    </div>


  <div class="uk-width-1-1 inner-shadow">

    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
            <div class="mobile-overflow">
                <form #userForm="ngForm" (ngSubmit)="onChangePassword(userForm)">
                    <div class="uk-grid">
                        <div class=" uk-width-1-2@m">
                            <label>{{'password' | translate}}</label>
                            <input class="uk-input" type="password" name="password" #password="ngModel" ngModel required min="6">
                        </div>

                        <div class=" uk-width-1-2@m">
                            <label>{{'password_confirmation' | translate}}</label>
                            <input class="uk-input" type="password" name="password_confirmation" #password_confirmation="ngModel" ngModel required min="6">
                        </div>
                    </div>

                    <div class="uk-margin">
                        <button class="uk-button uk-button-primary" [disabled]="userForm.form.invalid">{{'save' | translate}}</button>
                    </div>

                </form>
            </div>

        </div>

    </div>

</div>


import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ParcelsService} from '../../../services/parcels/parcels.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastsService} from '../../../services/toasts.service';
import {ClientsService} from '../../../services/clients/clients.service';
import {UserModel} from '../../../models/user.model';
import {AuthService} from '../../../services/auth/auth.service';

@Component({
  selector: 'app-parcel-new',
  templateUrl: './parcel-new.component.html',
  styleUrls: ['./parcel-new.component.scss']
})
export class ParcelNewComponent implements OnInit, OnDestroy {
  cities = [];
  clients = [];
  streets = [];
  destinationCityCode = '';
  recipientEmail = '';
  destinationStreetType = '';
  destinationPostCode = '';
  destinationCity = null;
  destinationStreet = null;
  destinationAddressNote = null;
  idClient = null;
  client = null;
  isCorrect = false;
  declaredValue = null;
  isPrivatePerson = true;
  hasStreetTypes = false;
  ajaxActive = false;
  displayPrice = false;
  Cash_on_delivery = 0;
  Length = null;
  Width = null;
  Height = null;
  Weight = null;
  recipientPhoneNumber = '07';
  recipientPhoneNumberTouched = false;
  isDeclaredValueTouched = false;
  declaredContent = null;
  recipientName = null;

  SubPricePLN = 0;
  SubPriceRON = 0;
  Price = 0;
  NetPrice = 0;
  CODEnabled = false;
  isReturn = false;


  public userModel: UserModel = new UserModel();

  constructor(
    private activatedRoute: ActivatedRoute,
    private parcelsService: ParcelsService,
    private authService: AuthService,
    private clientsService: ClientsService,
    private router: Router,
    private route: ActivatedRoute,
    private toastsService: ToastsService,
  ) {
  }

  ngOnInit(): void {
    this.authService.isLogged.subscribe((userModel) => {
      this.userModel = userModel;
    });
    this.isPrivatePerson = true;
    this.isCorrect = true;
    this.parcelsService.askShowPrice().subscribe(
      (response: any) => {
        this.displayPrice = response.show;
        this.CODEnabled = response.cod;
      }
    );
    this.declaredValue = 0;

    this.isReturn = '/parcel/return' === this.router.url;

    document.querySelector('#recipient_phone_number').addEventListener('keyup', (e) => {
      e.stopPropagation();
    }, false);

    if (null !== this.activatedRoute.snapshot.paramMap.get('internalNumber')) {
      this.parcelsService.copy(this.activatedRoute.snapshot.paramMap.get('internalNumber')).subscribe(
        (response: any) => {
          this.Length = response.length;
          this.Weight = response.weight;
          this.Width = response.width;
          this.Height = response.height;
          this.recipientPhoneNumber = response.recipient_phone_number_1;
          if (null !== response.phone_prefix_1) {
            this.recipientPhoneNumber = response.phone_prefix_1 + this.recipientPhoneNumber;
          }
          this.destinationPostCode = response.post_code;
          this.declaredValue = response.declared_value;
          this.declaredContent = response.declared_content;
          this.destinationPostCode = response.destination_post_code;
          this.recipientName = response.recipient_name;
          this.destinationCityCode = response.destination_city_code;
          this.destinationCity = response.destination_city;
          this.destinationAddressNote = response.address_note;
          this.recipientEmail = response.recipient_email;
          this.idClient = response.id_client;
        }
      );
    }
  }

  ngOnDestroy() {
    document.querySelector('#recipient_phone_number').removeEventListener('keyup', () => {
    });
  }

  searchClient(input): void {
    this.clientsService.searchClient(input.value).subscribe(
      (response: Array<any>) => {
        this.clients = response;
      }
    );
  }

  touchRecipientPhoneNumberField() {
    this.recipientPhoneNumberTouched = true;
  }

  touchDeclaredValueField() {
    if (!this.isDeclaredValueTouched && this.declaredValue == 0) {
      this.declaredValue = '';
      this.isDeclaredValueTouched = true;
    }
  }

  onCreateParcel(form): void {
    form.value.is_return = this.isReturn;
    if (form.value.cash_on_delivery == true) {
      if (form.value.cash_on_delivery_value == 0
        || form.value.cash_on_delivery_value == ''
        || form.value.cash_on_delivery_value == null) {
        this.toastsService.showMessage('Nie wprowadzono wartości pobrania!', 'e');
        return;
      }
    }
    if (this.isCorrect) {
      this.ajaxActive = true;
      this.parcelsService.createParcel(form.value).subscribe(
        (s: any) => {
          this.router.navigateByUrl('/parcel/' + s.internal_number);
          this.toastsService.showMessage('parcel_added', 's');
          this.ajaxActive = false;
        }, (e: any) => {
          this.ajaxActive = false;
        }
      );
    } else {
      this.onCheckParsell(form);
    }
  }

  onCheckParsell(form): void {
    this.parcelsService.checkParcel(form.value).subscribe(
      (s) => {
        this.toastsService.showMessage('parcel_is_correct', 's');
        this.isCorrect = true;
      }
    );
  }

  searchCity(input): void {
    this.destinationCityCode = '';
    this.parcelsService.getCity(input.value).subscribe(
      (success: any) => {
        this.cities = success;
        this.hasStreetTypes = false;
      }
    );
  }

  searchStreet(cityCode, input): void {
    if (input.value === '' || input.value == null) {
      this.streets = [];
      return;
    }
    this.parcelsService.getStreet(input.value, cityCode).subscribe(
      (success: any) => {
        this.streets = success;
        if (success.length > 0) {
          this.hasStreetTypes = true;
        }
      }, error => {
        this.streets = [];
      }
    );
  }

  setCity(city): void {
    this.cities = [];
    this.destinationCityCode = city.id;
    this.destinationCity = city.name_eng;
    this.destinationPostCode = city.post_code;
  }

  setStreet(street): void {
    this.destinationAddressNote = street.typeEn + ' ' + street.nameEn;
    this.streets = [];
    this.destinationStreet = street.nameEn;
    this.destinationStreetType = street.typeEn;
    if (street.typeEn === '' || street.typeEn == null) {
      this.hasStreetTypes = false;
    }
  }

  setClient(client): void {
    this.clients = [];
    this.idClient = client.id;
    this.client = client.name;
  }

  checkParcelPrice() {
    if (this.declaredValue == null || this.declaredValue == '') {
      this.declaredValue = 0;
      this.isDeclaredValueTouched = false;
    }

    if (!this.displayPrice) {
      return;
    }
    if (this.idClient === null) {
      return;
    }

    this.parcelsService.checkParcelPrice({
      id_client: this.idClient,
      length: this.Length,
      width: this.Width,
      height: this.Height,
      weight: this.Weight,
      declared_value: this.declaredValue,
      cash_on_delivery: this.Cash_on_delivery
    }).subscribe((response: any) => {
      if (
        this.Price != response.price || this.NetPrice != response.net_price ||
        this.SubPricePLN != response.pln || this.SubPriceRON != response.ron) {
        this.toastsService.showMessage('Szacowany koszt został zaktualizowany!', 's', 900);
      }

      this.NetPrice = response.net_price ?? 0;
      this.Price = response.price ?? 0;
      this.SubPriceRON = response.ron ?? 0;
      this.SubPricePLN = response.pln ?? 0;
    });
  }

  fillRandomData() {
    if (this.userModel.role > 0)
      return;

    this.parcelsService.getRandomParcelData().subscribe((response: any) => {
      this.Length = response.length;
      this.Weight = response.weight;
      this.Width = response.width;
      this.Height = response.height;
      this.recipientPhoneNumber = response.recipient_phone_number;
      this.destinationPostCode = response.post_code;
      this.declaredValue = response.declared_value;
      this.declaredContent = response.declared_content;
      this.recipientName = response.recipient_name;
      this.destinationCityCode = response.site_id;
      this.destinationCity = response.site_name;
      this.destinationAddressNote = response.address_note;
      this.toastsService.showMessage('Pamiętaj o anulowaniu testowej przesyłki!', 'w');
    });
  }

}

import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ToastsService} from '../../../services/toasts.service';
import {Subject} from 'rxjs';
import {DataTableDirective} from 'angular-datatables';


@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject();
    @ViewChild(DataTableDirective, {static: true}) dtElement: DataTableDirective;
    public dtInit = false;
    users: any = [];
    apiurl = '';
    deletedUsers = '';
    dtTranslation = null;
    constructor(private http: HttpClient,  private toastsService: ToastsService) {
        this.apiurl = environment.api;
        this.dtTranslation = toastsService.dtTranslation;
    }

    refreshUsersList() {
        this.deletedUsers = (this.deletedUsers === '') ? '/deleted' : '';
        this.showUsers();
    }

    ngOnInit() {
        this.showUsers();
    }

    showUsers() {
        const that = this;
        if (!this.dtInit) {
            setTimeout(
                () => {
                    this.dtTrigger.next();
                }, 100
            );
        } else {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.destroy();
                this.dtInit = false;
                setTimeout(
                    () => {
                        this.dtTrigger.next();
                    }, 100
                );
            });
        }
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 25,
            serverSide: true,
            processing: true,
            language: this.dtTranslation,
            ajax: (dataTablesParameters: any, callback) => {
                that.http
                    .post(
                        this.apiurl + 'users/data' + this.deletedUsers,
                        dataTablesParameters
                    ).subscribe((resp: any) => {
                    this.dtInit = true;
                    that.users = resp.data;

                    callback({
                        recordsTotal: resp.recordsTotal,
                        recordsFiltered: resp.recordsFiltered,
                        data: []
                    });
                });
            },
            columns: [
                {data: 'id', name: 'id'},
                {data: 'email', name: 'email'},
                {data: 'email_verified_at', name: 'email_verified_at'},
            ]
        };
    }

}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {ClientsService} from "../clients/clients.service";

@Injectable({
    providedIn: 'root'
})
export class UnknownClientAliasService {
    private apiurl = '';

  constructor(
    private http: HttpClient,
    private router: Router,
  ) {
    this.apiurl = environment.api + 'app/v1/unknown_client_alias';
  }


  list() {
      return this.http.get(this.apiurl);
  }

  resolve(form) {
    return this.http.put(this.apiurl, form, {observe: 'response'});
  }

  find(id) {
    return this.http.get(this.apiurl + '/' + id);
  }
}

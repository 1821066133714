import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ToastsService} from "../../../services/toasts.service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-problem-rules',
  templateUrl: './problem-rules.component.html',
  styleUrls: ['./problem-rules.component.scss']
})
export class ProblemRulesComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  rules=[];
  statuses: any = [];
  apiurl = '';
  dtTranslation = null;
  constructor(private http: HttpClient,  private toastsService: ToastsService) {
    this.apiurl = environment.api;
    this.dtTranslation = toastsService.dtTranslation;
  }

  ngOnInit() {
    this.showStatuses();
  }

  showStatuses() {
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      serverSide: true,
      processing: true,
      language: this.dtTranslation,
      ajax: (dataTablesParameters: any, callback) => {
        that.http
          .post(
            this.apiurl + 'problems/rules/data',
            dataTablesParameters, {}
          ).subscribe((resp: any) => {
          that.statuses = resp.data;

          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      },
      columns: [
        {data: 'name', name: 'pr.name'},
        {data: 'base_status', name: 'base_status'},
        {data: 'next_status', name: 'next_status'},
        {data: 'period', name: 'pr.period'},
      ]
    };
  }
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {ParcelsService} from "../parcels/parcels.service";

@Injectable({
  providedIn: 'root'
})
export class BillingService {
  private apiurl = '';

  constructor(private http: HttpClient, private router: Router, private parcelsService: ParcelsService) {
    this.apiurl = environment.api + 'app/v1/client/';
  }
}

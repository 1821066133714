import {Injectable} from '@angular/core';
import {ToastrModule, ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
declare var UIkit: any;
@Injectable({
    providedIn: 'root'
})
export class ToastsService {

    dtTranslation = {
        processing:     'Przetwarzanie...',
        search:         'Szukaj:',
        lengthMenu:     'Pokaż _MENU_ pozycji',
        info:           'Pozycje od _START_ do _END_ z _TOTAL_ łącznie',
        infoEmpty:      'Pozycji 0 z 0 dostępnych',
        infoFiltered:   '(filtrowanie spośród _MAX_ dostępnych pozycji)',
        infoPostFix:    '',
        loadingRecords: 'Wczytywanie...',
        zeroRecords:    'Nie znaleziono pasujących pozycji',
        emptyTabe:     'Brak danych',
        paginate: {
            first:      'Pierwsza',
            previous:   'Poprzednia',
            next:       'Następna',
            last:       'Ostatnia'
        },
        aria: {
            sortAscending: ': aktywuj, by posortować kolumnę rosnąco',
            sortDescending: ': aktywuj, by posortować kolumnę malejąco'
        }
    };

    constructor(
        private toastr: ToastrService,
        private translate: TranslateService,
    ) {
    }

    showMessage(message, type, timeOut= 10000): void {
        if (type === 'e') {
            this.toastr.error(this.translate.instant(message), null, {timeOut});
        } else if (type === 's') {
            this.toastr.info(this.translate.instant(message), null, {timeOut});
        } else if (type === 'w') {
          this.toastr.warning(this.translate.instant(message), null, {timeOut});
        }
    }


    showShortSuccessMessage(timeOut = 950) {
      const toastClass = 'short-success-toast';

      this.toastr.info(
        this.translate.instant(
          '<img src="/assets/img/check.png">' +
          '<input type="hidden" value="' + Math.random() + '">',
        ),
        null,
        {timeOut,
          toastClass,
          positionClass: 'toast-center-center',
          enableHtml: true,
          titleClass: 'toast-success'
        }
      );
    }

    showDeletedParcelMessage(timeOut = 950) {
      this.toastr.warning(
        this.translate.instant(
          '<br>' +
          '<img src="/assets/img/cancel-icon.png" width="200px"><br>' +
          '<br>Umieść przesyłkę w strefie weryfikacji'),
        'Przesyłka została usunięta!',
        {timeOut,
          toastClass: 'big-toast toast-parcel-deleted-error',
          positionClass: 'center-top-toast',
          enableHtml: true
        }
      );
    }

  showWrongClientAliasParcel(timeOut = 950) {
    this.toastr.warning(
      this.translate.instant(
        '<br>' +
        '<img src="/assets/img/cancel-icon.png" width="200px"><br>' +
        '<br>Umieść przesyłkę w strefie weryfikacji (14) '),
      'Przesyłka posiada niedozwolony alias klienta!',
      {timeOut,
        toastClass: 'big-toast toast-parcel-deleted-error',
        positionClass: 'center-top-toast',
        enableHtml: true
      }
    );
  }

  showTooEarlyScannedMessage(timeOut = 950) {
    this.showBigErrorMessage(
      'Brak informacji o przesyłce!',
      'Podejmij kolejną próbę za kilka chwil!',
    );
  }

  showBigErrorMessage(message: string, description: string, timeOut: number = 950): void {
    this.toastr.warning(
      this.translate.instant(
        '<br>' +
        '<img src="/assets/img/cancel-icon.png" width="200px"><br>' +
        '<br>' + description
      ),
      message,
      {
        timeOut,
        toastClass: 'big-toast toast-parcel-data-not-downloaded-yet-error',
        positionClass: 'center-top-toast',
        enableHtml: true
      },
    );
  }

  showBlockDispatchMessage(timeOut = 3950) {
    this.toastr.warning(
      this.translate.instant(
        '<br>' +
        '<img src="/assets/img/cancel-icon.png" width="300px"><br>' +
        '<br>Klient przypisany do tej paczki ma zablokowane nadawanie!'),
      'Blokada nadawania',
      {timeOut,
        toastClass: 'big-toast toast-parcel-data-not-downloaded-yet-error',
        positionClass: 'center-top-toast',
        enableHtml: true
      }
    );
  }

  showNotFullyScannedMultiPackMessage(timeOut = 950) {
    this.toastr.warning(
      this.translate.instant(
        '<br>' +
        '<img src="/assets/img/cancel-icon.png" width="200px"><br>' +
        '<br>Przekaż do poczekalni wielopaków!'),
      'Niekompletny wielopak',


      {timeOut,
        toastClass: 'big-toast toast-parcel-data-not-downloaded-yet-error',
        positionClass: 'center-top-toast',
        enableHtml: true
      }
    );
  }

  showMultiPackIsCompletedMessage(waybill, timeOut = 950) {
    this.toastr.success(
      this.translate.instant(
        '<img width="300" \n' +
        '     height="300" src="/assets/img/big-check.png">' +
        '<input type="hidden" value="' + Math.random() + '">' +
        '<br>Właśnie stał się kompletny, pobierz pozostałe przesyłki wielopaka z poczekalni!'),
      'Wielopak '+ waybill,


      {timeOut,
        toastClass: 'big-toast dark-green-success-toast ',
        positionClass: 'center-top-toast',
        enableHtml: true
      }
    );
  }

    showSortingMessage(courier, bucketIdentifier = null, timeOut = 950) {
      if (null === courier) {
        this.toastr.warning(
          this.translate.instant(
            '<br>' +
            '<img src="/assets/img/error-icon.png" width="200px"><br>' +
            '<br>Przekaż przesyłkę do stanowiska przyjmowania przesyłek'),
          'Brak przesyłki w systemie',
          {timeOut,
            toastClass: 'big-toast toast-classification-warning',
            positionClass: 'center-top-toast',
            enableHtml: true
          }
        );
        return;
      }
      if (false === courier) {
        this.toastr.warning(
          this.translate.instant(
            '<br>' +
            '<img src="/assets/img/cancel-icon.png" width="200px"><br>' +
            '<br>Umieść przesyłkę w strefie weryfikacji'),
          'Przesyłka nie jest dopuszczona do wysyłki!',
          {timeOut,
            toastClass: 'big-toast toast-classification-error',
            positionClass: 'center-top-toast',
            enableHtml: true
          }
        );
        return;
      }
      const toastClass = 'big-toast toast-classification-' + bucketIdentifier;
      this.toastr.warning(
        this.translate.instant(
          '<b><span class="big-toast-text">' + bucketIdentifier + '</span></b><br>'
          + '<b>' + courier + '</b>'
        ),
        null,
        {timeOut,
          toastClass,
          positionClass: 'center-top-toast',
          enableHtml: true,
        }
      );
    }

  showBigMessage(message, timeOut = 950) {
    const toastClass = 'big-toast';

    this.toastr.warning(
      this.translate.instant(message),
      null,
      {timeOut,
        toastClass,
        positionClass: 'center-top-toast',
        enableHtml: true,
        titleClass: 'big-toast-title'
      }
    );
  }

  showBigSuccessMessage(message = '', timeOut = 950) {
      const toastClass = 'big-toast toast-success';
      this.toastr.success(
        this.translate.instant(
          '<img width="300" \n' +
          '     height="300" src="/assets/img/big-check.png">' +
          '<input type="hidden" value="' + Math.random() + '">',
        ),
        message,
        {
          timeOut,
          toastClass,
          positionClass: 'center-top-toast',
          enableHtml: true,
          titleClass: 'big-toast-title'
        }
      );
  }

    async showConfirmModal(): Promise<boolean> {
        return new Promise ( (resolve, reject) => {
            UIkit.modal.confirm('Czy napewno chcesz wykonać akcję?', {labels: {cancel: 'Nie', ok: 'Tak'}}).then(() => {
                resolve(true);
            }, () => {
                resolve(false);
            });
        } );
    }

  async showConfirmDimensionsModal(form, message): Promise<boolean> {
    return new Promise ( (resolve, reject) => {
      UIkit.modal.confirm(
        '<h3 style="color: #bd2130">' + message + '</h3>'
        + 'Czy napewno chcesz zpisać następujące wymiary?'
        + '<br> Waga: '+ form.weight + 'kg'
        + '<br> Wysokość: ' + form.height + ' cm'
        + '<br> Szerokość: ' + form.width + ' cm'
        + '<br> Długość: ' + form.length +' cm',
        {labels: {cancel: 'Nie', ok: 'Tak'}}
      ).then(() => {
        resolve(true);
      }, () => {
        resolve(false);
      });
    } );
  }
}

import {OperationalPriceListWeightThresholdModel} from "./operational-price-list-weight-threshold.model";
import {OperationalPriceListAccountFactorType} from "../enums/operational-price-list-account-factor-type";

export class OperationalPriceListModel {
  id: string;
  type: string;
  id_added_by_user: string;
  id_courier: string;
  id_country: string = "1";
  id_client: string;
  begin_date: string;
  name: 'Cennik';
  currency: string = 'PLN';
  specific_factors_currency: string = 'RON';

  //cash on delivery
  cod_account_factor_minimal = null;
  cod_account_factor_maximal = null;
  cod_account_factor = 0;
  cod_account_factor_type = OperationalPriceListAccountFactorType.LUMP_SUM;
  //insurance
  insurance_account_factor_minimal = null;
  insurance_account_factor_maximal = null;
  insurance_account_factor = 0;
  insurance_account_factor_type = OperationalPriceListAccountFactorType.LUMP_SUM;
  //oversize
  oversize_account_factor_minimal = null;
  oversize_account_factor_maximal = null;
  oversize_account_factor = 0;
  oversize_account_factor_type =OperationalPriceListAccountFactorType.LUMP_SUM;
  //overweight
  overweight_account_factor_minimal = null;
  overweight_account_factor_maximal = null;
  overweight_account_factor = 0;
  overweight_account_factor_type = OperationalPriceListAccountFactorType.LUMP_SUM;
  //multi pack
  multi_pack_account_factor_minimal = null;
  multi_pack_account_factor_maximal = null;
  multi_pack_account_factor = 0;
  multi_pack_account_factor_type = OperationalPriceListAccountFactorType.LUMP_SUM;
  //cod card payment
  cod_card_payment_account_factor_minimal = null;
  cod_card_payment_account_factor_maximal = null;
  cod_card_payment_account_factor = 0;
  cod_card_payment_account_factor_type = OperationalPriceListAccountFactorType.LUMP_SUM;
  //unclaimed
  unclaimed_account_factor_minimal = null;
  unclaimed_account_factor_maximal = null;
  unclaimed_account_factor = 0;
  unclaimed_account_factor_type = OperationalPriceListAccountFactorType.LUMP_SUM;
  //collect from client
  collect_from_client_account_factor_minimal = null;
  collect_from_client_account_factor_maximal =null;
  collect_from_client_account_factor = 0;
  collect_from_client_account_factor_type = OperationalPriceListAccountFactorType.LUMP_SUM;
  //non conveyable
  non_conveyable_account_factor_minimal = null;
  non_conveyable_account_factor_maximal = null;
  non_conveyable_account_factor = 0;
  non_conveyable_account_factor_type = OperationalPriceListAccountFactorType.LUMP_SUM;

  special_association: string;

  weight_thresholds: OperationalPriceListWeightThresholdModel[] = [
    {
      "id": "",
      "limiting_threshold": "3.00",
      "base_amount": "0.00",
    }, {
      "id": "",
      "limiting_threshold": "5.00",
      "base_amount": "0.00",
    }, {
      "id": "",
      "limiting_threshold": "15.00",
      "base_amount": "0.00",
    }, {
      "id": "",
      "limiting_threshold": "20.00",
      "base_amount": "0.00",
    }, {
      "id": "",
      "limiting_threshold": "30.00",
      "base_amount": "0.00",
    }
  ];
}

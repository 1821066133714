import {Component, OnInit, ViewChild} from '@angular/core';
import {ClientsService} from '../../../services/clients/clients.service';
import {StatsService} from '../../../services/stats/stats.service';
import {Subject} from 'rxjs';
import {UserModel} from '../../../models/user.model';
import {DataTableDirective} from 'angular-datatables';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {
  dtTranslation = null;
  @ViewChild(DataTableDirective, {static: true}) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {
    pagingType: 'full_numbers',
    pageLength: 25,
    language: this.dtTranslation,
    ordering: true,
    order: [[6, 'DESC']],
    columns: [
      {data: 'name', name: 'name'},
      {data: 'daily', name: 'daily'},
      {data: 'yesterday', name: 'yesterday'},
      {data: 'day_before_yesterday', name: 'day_before_yesterday'},
      {data: 'weekly', name: 'weekly'},
      {data: 'monthly', name: 'monthly'},
      {data: 'ever', name: 'ever'},
    ]
  };

  selectedStatus = 'received_by_re';

  dtTrigger: Subject<any> = new Subject();
  public userModel: UserModel = new UserModel();
  dtInit = false;
  clients = [];
  stats = [];
  type: string;
  currentDate: string;

  constructor(
    private clientsService: ClientsService,
    private statsService: StatsService,
    private authService: AuthService,
  ) {
  }


  ngOnInit() {
    this.authService.isLogged.subscribe((userModel) => {
      this.userModel = userModel;
    });
    this.getStats();
    this.setCurrentDate();
  }

  drawDatatable() {
    if (!this.dtInit) {
      setTimeout(
        () => {
          this.dtInit = true;
          this.dtTrigger.next();
        }, 300
      );
    } else {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        setTimeout(
          () => {
            this.dtTrigger.next();
          }, 300
        );
      });
    }
  }

  getStats() {
    this.statsService.getStats(this.selectedStatus).subscribe(
      (stats: []) => {
        this.stats = stats;
      },
      () => {
        console.log('Something went wrong while downloading statistics');
      },
      () => {
        this.getClientStats();
      }
    );
  }

  getClientStats() {
    this.clientsService.getClients().subscribe(
      (clients: any) => {
        this.clients = [];
        this.clients = clients;
        this.clients.push({id: 'all', name: 'Wszystkie przesyłki'});
      }, () => {
      }, () => {
        this.drawDatatable();
      }
    );
  }

  downloadXlsx(){
    this.statsService.download(this.selectedStatus).subscribe(
      (blob: Blob) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        if (this.selectedStatus === 'received_by_re'){
          link.download = 'statystyki_przesłek_klientów_' + this.currentDate + '.xlsx';
        } else if (this.selectedStatus === 'back_in_poland'){
          link.download = 'statystyki_zwrotów_klientów_' + this.currentDate + '.xlsx';
        }

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    );
  }

  setCurrentDate() {
    this.currentDate = new Date().toLocaleString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false }).replace(/:\d+ /, ' ');
  }
}

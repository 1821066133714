import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ClientsService} from '../../../../services/clients/clients.service';
import {
  ParcelReportedResignationsService
} from '../../../../services/parcel_reported_resignations/parcel-reported-resignations.service';
import {ToastsService} from '../../../../services/toasts.service';

@Component({
  selector: 'app-parcel-reported-resignation',
  templateUrl: './parcel-reported-resignation.component.html',
  styleUrls: ['./parcel-reported-resignation.component.scss']
})
export class ParcelReportedResignationComponent implements OnInit {
  id = null;
  clients = [];

  model = {
    id: null,
    user_id: null,
    user_name: null,
    client_id: null,
    client_name: null,
    waybill: null,
    scanned_at: null,
    resend_at: null,
    note: null,
    scanned_by_user_id: null,
    scanned_by_user_name: null
  };

  constructor(
    private router: Router,
    private toastsService: ToastsService,
    private activatedRoute: ActivatedRoute,
    private clientsService: ClientsService,
    private parcelReportedResignationsService: ParcelReportedResignationsService
  ) {
      this.id = this.activatedRoute.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    if (null !== this.id) {
      this.show();
    }
  }

  setTime() {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());
    const hour = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    this.model.resend_at = `${year}-${month}-${day}T${hour}:${minutes}`;
  }

  searchClient(input): void {
    this.clientsService.searchClient(input.value).subscribe(
      (response: Array<any>) => {
        this.clients = response;
      }
    );
  }

  setClient(client): void {
    this.clients = [];
    this.model.client_id = client.id;
    this.model.client_name = client.name;
  }

  save() {
    if (null === this.id) {
      this.parcelReportedResignationsService.store(this.model).subscribe(
        (response: any) => {
          this.toastsService.showMessage('Utworzono pomyślnie!', 's');
          this.id = response.id;
        }, e => {
          // tslint:disable-next-line:forin
          for (const error in e.error){
            this.toastsService.showMessage(e.error[error], 'e', 5000);
          }
        }
      );
    }
    else {
      this.parcelReportedResignationsService.update(this.model).subscribe(
        (response: any) => {
          this.toastsService.showMessage('Zaktualizowano pomyślnie!', 's');
        }, e => {
          // tslint:disable-next-line:forin
          for (const error in e.error){
            this.toastsService.showMessage(e.error[error], 'e', 5000);
          }
        }
      );
    }
  }

  async delete() {
    const promised = await this.toastsService.showConfirmModal();
    if (!promised) {
      return;
    }

    this.parcelReportedResignationsService.delete(this.id).subscribe(
      (response: any) => {
        this.toastsService.showMessage('Usunięto pomyslnie!', 's');
        this.router.navigateByUrl('/parcel-reported-resignations');
      }, e => {
        // tslint:disable-next-line:forin
        for (const error in e.error){
          this.toastsService.showMessage(e.error[error], 'e', 5000);
        }
      }
    );
  }

  show() {
    this.parcelReportedResignationsService.show(this.id).subscribe(
      (response: any) => {
        this.model = response;
        if (null === this.model.resend_at) {
          this.setTime();
        }
      }
    );
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CredentialsService} from '../../../../services/credentails/credentials.service';
import {ToastsService} from '../../../../services/toasts.service';
import {ClientsService} from "../../../../services/clients/clients.service";
declare var UIkit: any;
@Component({
  selector: 'app-client-price',
  templateUrl: './client-price.component.html',
  styleUrls: ['./client-price.component.scss']
})
export class ClientPriceComponent implements OnInit {
  id = null;
  idClient = null;
  tempPrice = {
    id: null,
    service_id: '2505',
    id_client: null,
    net_price: null,
    vat_percentage: null,
    min_weight_value: null,
    max_weight_value: null
  };
  constructor(
    private clientsService: ClientsService,
    private route: ActivatedRoute,
    private router: Router,
    private toastsService: ToastsService
  ) {
  }
  ngOnInit(): void {
    try {
      this.idClient = this.route.snapshot.paramMap.get('idClient');
      const id = this.route.snapshot.paramMap.get('id');
      if (id !== undefined) {
        this.id = id;
      }
    } catch (error) {
      this.id = null;
    }
    this.getItem();
  }

  getItem(){
    this.clientsService.getPrice(this.id).subscribe(
      (response: any) => {
        this.tempPrice = response;
      }
    );
  }

  submitPrice(){
    if (this.id === null){
      this.tempPrice.service_id = '2505';
      this.tempPrice.id_client = this.idClient;
      this.create();
    }
    else{
      this.update();
    }
  }

  create(){
    this.clientsService.addPrice(this.tempPrice).subscribe(
      (response: any) => {
        this.router.navigateByUrl('/client/' + this.idClient + '/price/' + response.id);
        this.toastsService.showMessage('Przedział cenowy został utworzony!', 's');
      }
    );
  }
  update(){
    this.clientsService.changePrice(this.tempPrice).subscribe(
      (response: any) => {
        this.toastsService.showMessage('Przedział cenowy został zmieniony!', 's');
      }
    );
  }
  delete(){
    this.clientsService.deletePrice(this.id).subscribe(
      (response: any) => {
        this.router.navigateByUrl('/client/' + this.idClient + '/card/pricing').then(s => {
          this.toastsService.showMessage('Przedział cenowy został usunięty', 's');
        });
      }
    );
  }
}

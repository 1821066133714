<div class="uk-grid">
    <div class="uk-width-1-1 navbar">
        <p class="nav-text">
            {{'my_company' | translate}}
        </p>
    </div>


  <div class="uk-width-1-1 inner-shadow">

    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">

            <div class="uk-grid">
                <div class="uk-width-1-1@m">
                    <form>
                        <div class="uk-grid">
                            <div class=" uk-width-1-3@m">
                                <label>{{'email' | translate}}</label>
                                <input class="uk-input" type="text" name="email" #email="ngModel" [ngModel]="client.email" disabled email>
                            </div>
                            <div class=" uk-width-1-3@m">
                                <label>{{'nip' | translate}}</label>
                                <input class="uk-input" type="text" name="nip" #nip="ngModel" [ngModel]="client.nip" disabled>
                            </div>
                            <div class=" uk-width-1-3@m">
                                <label>{{'name' | translate}}</label>
                                <input class="uk-input" type="text" name="name" #name="ngModel" [ngModel]="client.name" disabled>
                            </div>
                            <div class=" uk-width-1-3@m">
                                <label>{{'contact_name' | translate}}</label>
                                <input class="uk-input" type="text" name="contact_name" #contact_name="ngModel" [ngModel]="client.contact_name" disabled>
                            </div>
                            <div class=" uk-width-1-3@m">
                                <label>{{'post_code' | translate}}</label>
                                <input class="uk-input" type="text" name="post_code" #post_code="ngModel" [ngModel]="client.post_code" disabled>
                            </div>
                            <div class=" uk-width-1-3@m">
                                <label>{{'street' | translate}}</label>
                                <input class="uk-input" type="text" name="street" #street="ngModel" [ngModel]="client.street" disabled>
                            </div>
                            <div class=" uk-width-1-3@m">
                                <label>{{'address' | translate}}</label>
                                <input class="uk-input" type="text" name="address" #address="ngModel" [ngModel]="client.address" disabled>
                            </div>
                            <div class=" uk-width-1-3@m">
                                <label>{{'bank_account_number' | translate}}</label>
                                <input class="uk-input" type="text" name="bank_account_number" #bank_account_number="ngModel" [ngModel]="client.bank_account_number" disabled>
                            </div>
                            <div class=" uk-width-1-3@m">
                                <label>{{'city' | translate}}</label>
                                <input class="uk-input" type="text" name="city" #city="ngModel" [ngModel]="client.city" disabled>
                            </div>

                            <div class=" uk-width-1-3@m">
                                <label>{{'shipment_note' | translate}}</label>
                                <input class="uk-input" type="text" name="shipment_note" #shipment_note="ngModel" [ngModel]="client.shipment_note" disabled>
                            </div>

                            <div class=" uk-width-1-3@m">
                                <label>{{'phone_number_prefix' | translate}}</label>
                                <input class="uk-input" type="text" name="phone_number_prefix" pattern="(\+)[0-9]{2,3}" [class.uk-text-danger]="phone_number_prefix.invalid" #phone_number_prefix="ngModel" [ngModel]="client.phone_number_prefix" disabled>
                            </div>
                            <div class=" uk-width-1-3@m">
                                <label>{{'phone_number' | translate}}</label>
                                <input class="uk-input" type="text" name="phone_number" pattern="[0-9]{9,10}" [class.uk-text-danger]="phone_number.invalid" #phone_number="ngModel" [ngModel]="client.phone_number" disabled>
                            </div>
                            <div class=" uk-width-1-3@m">
                              <label>{{'re_returning_address' | translate}}</label>
                              <textarea class="uk-input" type="text" name="re_returning_address" #re_returning_address="ngModel" [ngModel]="client.re_returning_address" required cols="3" style="min-height: 5em" disabled></textarea>
                            </div>

                            <div class=" uk-width-1-3@m">
                              <label>{{'re_broadcasting_facility_address' | translate}}</label>
                              <textarea class="uk-input" type="text" name="re_broadcasting_facility_address" #re_broadcasting_facility_address="ngModel" [ngModel]="client.re_broadcasting_facility_address" required style="min-height: 5em" disabled></textarea>
                            </div>
                        </div>

                    </form>
                </div>



        </div>

    </div>

</div>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ToastsService} from '../../../../services/toasts.service';
import {CredentialsService} from '../../../../services/credentails/credentials.service';

@Component({
  selector: 'app-client-credentials',
  templateUrl: './client-credentials.component.html',
  styleUrls: ['./client-credentials.component.scss']
})

export class ClientCredentialsComponent implements OnInit {
  @Input() clientId;
  id = null;
  credentails: [];

  constructor(private credentailsService: CredentialsService,
              private toastsService: ToastsService,
              private route: Router
  ) {
  }

  ngOnInit() {
    this.getCredentials();
  }

  getCredentials() {
    this.credentailsService.getForClient(this.clientId).subscribe(
      (response: []) => {
        this.credentails = response;
      }
    );
  }

  open(id = null ) {
    this.route.navigateByUrl('/client/' + this.clientId + '/credentail/' + id);
  }
}

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CourierService {
  private apiurl = '';
  constructor(private http: HttpClient, private router: Router) {
    this.apiurl = environment.api + 'app/v1/courier';
  }

  list() {
    const url = this.apiurl + '/list';
    return this.http.get(url);
  }
}

import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ProblemsService} from "../../../services/problems/problems.service";
import {ParcelsService} from "../../../services/parcels/parcels.service";
import {ToastsService} from "../../../services/toasts.service";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
@Component({
  selector: 'app-problem-rule',
  templateUrl: './problem-rule.component.html',
  styleUrls: ['./problem-rule.component.scss']
})
export class ProblemRuleComponent implements OnInit {
  id = null;
  statusTypes=[];
  problem = {
    id: null,
    name: null,
    id_base_status: null,
    id_next_status: null,
    period: 1,
    variant: 'hours',
    alert_at: null,
    code: "",
    system: 0,
    auto_send_email: 0,
    auto_mail_addressats: '',
    auto_mail_subject: '',
    auto_mail_body: '',
    auto_mail_bccs: '',
    auto_mail_reply_to: ''
  };

  constructor(
    private problemsService: ProblemsService,
    private route: ActivatedRoute,
    private router: Router,
    private parcelsServices: ParcelsService,
    private toastsService: ToastsService
  ) {
  }

  getStatusTypes(){
    this.parcelsServices.getStatusTypes().subscribe(
      (response: [])=>{
        this.statusTypes=response;
      }
    );
  }

  showItem(){
    this.problemsService.showRule(this.id).subscribe(
      (response: any)=>{
        this.problem=response;
        if (response.other!==null){
          const json=JSON.parse(response.other);
          this.problem.auto_mail_body=json.auto_mail_body;
          this.problem.auto_mail_addressats=json.auto_mail_addressats;
          this.problem.auto_mail_subject=json.auto_mail_subject;
          this.problem.auto_mail_bccs=json.auto_mail_bccs;
          this.problem.auto_mail_reply_to=json.auto_mail_reply_to;
        }
      }
    );
  }

  ngOnInit(): void {
    try {
      const id = this.route.snapshot.paramMap.get('id');
      if (id != undefined)
        this.id = id;
    } catch (error) {
      this.id = null;
    }
    this.getStatusTypes();
    if (this.id!==null)
      this.showItem();
  }

  submit() {
    if (this.id===null)
      this.create();
    else
      this.update();
  }

  create(){
    this.problemsService.createRule(this.problem).subscribe(
      (response: any)=>{
        this.toastsService.showMessage('Utworzono pomyślnie!', 's');
        this.router.navigateByUrl('/problem-rule/'+response.id);
      }
    );
  }

  update(){
    this.problemsService.updateRule(this.problem).subscribe(
      (response: any)=>{
        this.toastsService.showMessage('Zmodyfikowano pomyślnie!', 's');
      }
    );
  }

  delete(){
    this.problemsService.removeRule(this.id).subscribe(
      (response: any)=>{
        this.router.navigateByUrl('/problem-rules')
        this.toastsService.showMessage('Usunięto pomyślnie!', 's');
      }
    );
  }

}

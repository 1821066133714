import {Component, OnInit, ViewChild} from '@angular/core';
import {ClientsService} from '../../../services/clients/clients.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UnknownClientAliasService} from "../../../services/unknown-client-alias/unknown-client-alias.service";
import {ToastsService} from "../../../services/toasts.service";

declare var UIkit: any;

@Component({
  selector: 'app-parcels-list',
  templateUrl: './unknown-client-alias.component.html',
  styleUrls: ['./unknown-client-alias.component.scss']
})
export class UnknownClientAliasComponent implements OnInit {
  clients = [];
  unknownClientAlias = null;

  id= null

  clientChanged = false;

  constructor(
    private route: ActivatedRoute,
    private clientsService: ClientsService,
    private toastService: ToastsService,
    private unknownClientAliasService: UnknownClientAliasService,
  ) {
  }

  ngOnInit() {
    try {
      const id = this.route.snapshot.paramMap.get('id');
      if (id != undefined)
        this.id = id;
    } catch (error) {
      return;
    }

    this.getUnknownClientAlias();
  }


  resolve() {
    this.unknownClientAliasService.resolve({
        id: this.id,
        client_id: this.unknownClientAlias.proposed_client_id,
        is_emag: this.unknownClientAlias.is_emag,
        resolved_type: this.clientChanged ? 'manual' : 'proposition',
    }).subscribe((response) => {
      this.toastService.showMessage('Alias został utworzony pomyślnie!', 's');
      window.location.href = '/unknown-client-aliases'
    }, e  => {
      console.log(e);
    });
  }

  getUnknownClientAlias() {
    this.unknownClientAlias = this.unknownClientAliasService.find(this.id).subscribe(
      (response: any) => {
        this.unknownClientAlias = response;
        this.unknownClientAlias.is_emag = this.unknownClientAlias.is_emag ? 1 : 0;
        this.clientsService.getClient(this.unknownClientAlias.proposed_client_id).subscribe(
          (clientResponse: any) => {
              this.unknownClientAlias.client_name = clientResponse.name;
          }
        );
      }
    );
  }

  searchClient(input): void {
    this.clientsService.searchClient(input.value).subscribe(
      (response: Array<any>) => {
        this.clients = response;
      }
    );
  }

  setClient(client): void {
    console.log(client)
    this.clientChanged = true;
    this.clients = [];
    this.unknownClientAlias.proposed_client_id = client.id;
    this.unknownClientAlias.client_name = client.name;
  }
}

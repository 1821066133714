<div class="uk-grid">
  <div class="uk-width-1-1 navbar">
    <p class="nav-text">
      Nierozpoznane aliasy klientów
    </p>
  </div>
  <div class="uk-width-1-1 inner-shadow">
    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
      <div class="mobile-overflow">
        <table style="width: 100%" class="uk-table uk-width-1-1 uk-table-hover uk-table-divider">
          <thead>
          <tr>
            <th style="width: 1%">LP</th>
            <th style="width: 15%">Alias</th>
          </tr>
          </thead>
          <tbody *ngIf="unknownClientAliases?.length > 0">
            <tr *ngFor="let unknownClientAlias of unknownClientAliases; let lp=index" class="pt-25" (click)="openClientAlias(unknownClientAlias.id)">
              <td>{{lp + 1}}</td>
              <td>{{unknownClientAlias.alias}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

import {Component, OnInit, ViewChild} from '@angular/core';
import {DataTableDirective} from "angular-datatables";
import {Subject} from "rxjs";
import {UserModel} from "../../../models/user.model";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../../../services/auth/auth.service";
import {ParcelsService} from "../../../services/parcels/parcels.service";
import {ToastsService} from "../../../services/toasts.service";
import {Router} from "@angular/router";
import {StatusesService} from "../../../services/statuses/statuses.service";
import {environment} from "../../../../environments/environment";
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import {ProblemsService} from "../../../services/problems/problems.service";
declare var UIkit: any;

@Component({
  selector: 'app-problems',
  templateUrl: './problems.component.html',
  styleUrls: ['./problems.component.scss']
})
export class ProblemsComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, {static: true}) dtElement: DataTableDirective;
  problems: any =[];
  dtTrigger: Subject<any> = new Subject();
  public dtInit = false;
  apiurl = '';
  dtTranslation = null;

  rules=[];
  filters = {
    from: '01.01.1999',
    to: '01.01.1999',
    status: 'all',
    rule: 0,
  };
  token = '';
  goToLocker = false;
  lastEvent = null;
  timeout = 100;
  statusesList = [];
  firstInitDt = true;
  lastChecked = false;

  defaultDates = {
    from: '01.01.1999',
    to: '01.01.1999',
  };
  statusTypes=[];

  solveModel = {
    ids: [],
    when: null,
    where: 'TYCHY',
  };


  public userModel: UserModel = new UserModel();

  constructor(private http: HttpClient, private authService: AuthService, private problemsService: ProblemsService,
              private toastsService: ToastsService, private router: Router, private statusesService: StatusesService
  ) {
    this.apiurl = environment.api;
    this.dtTranslation = toastsService.dtTranslation;
  }

  setTime() {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());
    const hour = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    this.solveModel.when = `${year}-${month}-${day}T${hour}:${minutes}`;
  }

  setGoToLocker(value) {
    this.goToLocker = value;
  }

  ngOnInit() {
    this.authService.isLogged.subscribe((userModel) => {
      this.userModel = userModel;
    });
    this.setTime();
    this.setDates();
    this.token = this.authService.getToken;
    this.getStatusesForSelect();
    this.getRules();
    const modalChangeStatus = document.getElementById('solveStatus');
    if (modalChangeStatus != null && modalChangeStatus !== undefined) {
      modalChangeStatus.remove();
    }
  }

  setDates() {
    let date = new Date();
    let day = String(date.getDate()).padStart(2, '0');
    let month = String(date.getMonth() + 1).padStart(2, '0');
    let year = String(date.getFullYear());
    this.filters.to = `${year}-${month}-${day}`;
    date = (new Date(date.setMonth(date.getMonth() - 1)));
    day = String(date.getDate()).padStart(2, '0');
    month = String(date.getMonth() + 1).padStart(2, '0');
    year = String(date.getFullYear());
    this.filters.from = `${year}-${month}-${day}`;

    this.defaultDates.from = {...this.filters}.from;
    this.defaultDates.to = {...this.filters}.to;
    this.showProblems();
  }

  searchDt() {
    const main = this;
    setTimeout(
      () => {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.columns().every(function () {
            const that = this;
            $('input', this.footer()).on('keyup change', function () {
              if (that.search() !== this['value']) {
                that.search(this['value'])
                  .draw();
              }
            });
          });
        });
      }, 500
    );
  }


  showProblems() {
    if (this.lastEvent != null) {
      clearTimeout(this.lastEvent);
    }
    if (this.filters.from === '' || this.filters.to === '') {
      return;
    }

    this.lastEvent = setTimeout(() => {
      const that = this;
      let cl = [
        {data: 'internal_number', name: 'p.internal_number'},
        {data: 'code', name: 'pr.code'},
        {data: 'problem_rule', name: 'rule_name'},
        {data: 'status', name: 'x.status'},
        {data: 'created_at', name: 'x.created_at'},
        {data: 'solved_at', name: 'x.solved_at'},
        {data: 'selected', name: 'selected'}
        ];
      if (!this.dtInit) {
        setTimeout(
          () => {
            this.dtTrigger.next();
          }, 100
        );
      } else {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtInit = false;
          setTimeout(
            () => {
              this.dtTrigger.next();
            }, 100
          );
        });
      }
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 25,
        lengthMenu: [25, 50, 100, 250, 500],
        serverSide: true,
        processing: true,
        stateSave: true,
        order: [[cl.length - 1, 'DESC']],
        ajax: (dataTablesParameters: any, callback) => {
          that.http
            .post(
              this.apiurl + `problems/data/${that.filters.from}/${that.filters.to}/${that.filters.status}/${that.filters.rule}`,
              dataTablesParameters
            ).subscribe((resp: any) => {
            this.timeout = 900;
            that.problems = resp.data;
            that.dtInit = true;
            this.lastEvent = false;
            that.searchDt();
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
        },
        columns: cl,
        language: this.dtTranslation
      };
    }, this.timeout);
  }


  goTo(url) {
    if (!this.goToLocker){
      this.router.navigateByUrl(url);
    }
  }

  getStatusesForSelect() {
      this.statusesService.getStatusesForSelect().subscribe(
        (response: []) => {
          this.statusesList = response;
          this.showProblems();
        }
      );
  }

  clearFilters() {
    let inputs = localStorage.getItem('search-inputs');
    inputs = JSON.parse(inputs);
    if (inputs == null) {
      return 0;
    }
    for (const input of inputs) {
      const o: any = input;
      const i = $('[name=' + o.name + ']');
      $(i).val('');

      if (o.name === 'status') {
        this.filters.status = 'all';
        $(i).val(0);
      }
      if (o.name === 'rule') {
        this.filters.rule = 0;
        $(i).val(0);
      }
      if (o.name === 'search-from') {
        this.filters.from = {...this.defaultDates}.from;
        $(i).val(this.filters.from);
      }
      if (o.name === 'search-to') {
        this.filters.to = {...this.defaultDates}.to;
        $(i).val(this.filters.to);
      }
    }
    this.setDates();
    this.showProblems();
  }


  selectAll() {
    this.lastChecked = !this.lastChecked;
    for (const problem of this.problems) {
      problem.selected = this.lastChecked;
    }
  }

  setSolved() {
    this.solveModel.ids = [];
    for (const problem of this.problems) {
      if (problem.selected == 1) {
        this.solveModel.ids.push(problem.id);
      }
    }
    this.problemsService.resolveMass(this.solveModel.ids).subscribe(
      (response: any) => {
        this.toastsService.showMessage('Nieprawidłowości zostały rozwiązane!', 's');
        this.showProblems();
      }
    );
  }

  getRules(){
    this.problemsService.getRules().subscribe(
      (response: any)=>{
        this.rules=response;
      }
    );
  }

  goToRules() {
    this.router.navigateByUrl('/problem-rules');
  }

  setInProgress(){
    this.solveModel.ids = [];
    for (const problem of this.problems) {
      if (problem.selected == 1) {
        this.solveModel.ids.push(problem.id);
      }
    }
    this.problemsService.progressMass(this.solveModel.ids).subscribe(
      (response: any) => {
        this.toastsService.showMessage('Statusy zostały zaktualizowane!', 's');
        this.showProblems();
      }
    );
  }

  showActionButton=null;
  filterRuleChange(){
    if (this.filters.rule!=0)
      this.problemsService.showRule(this.filters.rule).subscribe(
        (response: any)=>{
          this.showActionButton=response.code;
        }
      );
  }

  resendIds=[];

  resend(){
    this.resendIds = [];
    for (const problem of this.problems) {
      if (problem.selected == 1) {
        this.resendIds.push(problem.id);
      }
    }
    this.problemsService.resolveMass(this.resendIds).subscribe(
      (response: any)=>{
        this.toastsService.showMessage('Przesyłki poprawnie zarejestrowane w systemie DPD!', 's');
        this.showProblems();
      }
    )
  }

}

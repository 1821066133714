import { Component, OnInit } from '@angular/core';
import {ProblemsService} from "../../../services/problems/problems.service";
import {ActivatedRoute, Router} from "@angular/router";
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import {ToastsService} from "../../../services/toasts.service";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-problem',
  templateUrl: './problem.component.html',
  styleUrls: ['./problem.component.scss']
})
export class ProblemComponent implements OnInit {
  id = null;

  note='';
  detected_at= '';
  payload=null;
  problemModel={
    id: null,
    id_parcel: null,
    id_problem_rule: null,
    id_solved_by: null,
    created_at: null,
    solved_at: null,
    status: 'in_progress',
    next_status: '',
    base_status: '',
    code: 'd~0x0x0',
    internal_number: '~',
    rule: null,
    out_number: null,
    return_number: null,
    client: null,
    out_courier: null,
    default_api: 1,
    other: null,
    payload: null,
  };
  threeLastStatuses=[];
  constructor(  private problemsService: ProblemsService,
                private route: ActivatedRoute,
                private toastsService: ToastsService,
                private router: Router) { }

  ngOnInit(): void {
    try {
      const id = this.route.snapshot.paramMap.get('id');
      if (id != undefined)
        this.id = id;
    } catch (error) {
      this.id = null;
    }
    this.getItem();
  }

  getItem(){
    this.problemsService.showProblem(this.id).subscribe(
      (response: any)=>{
        this.problemModel=response.problem;
        this.threeLastStatuses=response.last_three_statuses;
        if (this.problemModel.status=='reported')
          this.setNoticed();
        this.note=response.note;
        this.payload=response.payload;
        this.detected_at=response.detected_at;
      }
    );
  }

  goToParcel(){
    window.open('/parcel/'+this.problemModel.internal_number, '_blank')
  }

  setInProgress(){
    this.problemsService.progress(this.id).subscribe(
      (response: any)=>{
        this.problemModel.status='in_progress';
      }
    );
  }

  setNoticed(){
    this.problemsService.notice(this.id).subscribe(
      (response: any)=>{
        this.problemModel.status='noticed';
      }
    );
  }

  saveNote(){
    this.problemsService.updateNote({id: this.id, note: this.note}).subscribe(
      (response: any)=>{
        this.toastsService.showMessage('Notatka została zaaktualizowana', 's');
        if (this.problemModel.status=='noticed')
          this.setInProgress();
      }
    );
  }

  setSolved(){
    this.problemsService.resolve(this.id).subscribe(
      (response: any)=>{
        this.toastsService.showMessage('Nieprawidłowośc została rozwiazana!', 's');
        this.getItem();
      }
    );
  }

  setNotSolved(){
    this.problemsService.unresolve(this.id).subscribe(
      (response: any)=>{
        this.toastsService.showMessage('Status został zmieniony!', 's');
        this.problemModel.status='in_progress';
      }
    );
  }

  delete(){
    this.problemsService.delete(this.id).subscribe(
      (response: any)=>{
        this.toastsService.showMessage('Usunięto pomyślnie!', 's');
        this.router.navigateByUrl('/problems');
      }
    );
  }

  reSend(){
    this.problemsService.resend(this.id).subscribe(
      (response: any)=>{
        this.toastsService.showMessage('Przesyłka została pomyślnie zarejestrowanana w sytemie DPD!', 's');
        this.note=null;
        this.getItem();
      }
    )
  }
}

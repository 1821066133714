
<div class="uk-grid">
    <div class="uk-width-1-1 navbar">
        <p class="nav-text">
            {{'price_table' | translate}}
        </p>
    </div>


  <div class="uk-width-1-1 inner-shadow">

    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
            <div class="mobile-overflow">

                <table class="uk-table uk-width-1-1 uk-table-hover uk-table-divider" >
                    <thead>
                    <tr>
                        <th>{{'id' | translate}}</th>
                        <th>{{'net_price' | translate}}</th>
                        <th>{{'vat_percentage' | translate}}</th>
                        <th>{{'max_insurance_value' | translate}}</th>
                        <th>{{'min_weight_value' | translate}}</th>
                        <th>{{'max_weight_value' | translate}}</th>
                    </tr>
                    </thead>
                    <tbody *ngIf="prices?.length > 0">
                    <tr *ngFor="let price of prices" [routerLink]=" '/prices/price/' + price.id ">
                        <td>{{ price.id }}</td>
                        <td>{{ price.net_price }}</td>
                        <td>{{ price.vat_percentage }}</td>
                        <td>{{ price.max_insurance_value }}</td>
                        <td>{{ price.min_weight_value }}</td>
                        <td>{{ price.max_weight_value }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>

    </div>

</div>


import { Component, OnInit } from '@angular/core';
import {CalendarDaysService} from "../../../services/calendar_days/calendar-days.service";
import {HttpClient} from "@angular/common/http";
import {ToastsService} from "../../../services/toasts.service";
import {UnknownClientAliasService} from "../../../services/unknown-client-alias/unknown-client-alias.service";
import {ClientsService} from "../../../services/clients/clients.service";

declare var UIkit: any;

@Component({
  selector: 'app-days',
  templateUrl: './unknown-client-aliases.component.html',
  styleUrls: ['./unknown-client-aliases.component.scss']
})
export class UnknownClientAliasesComponent implements OnInit {
  dtTranslation = null;
  today=null;

  unknownClientAliases: any;
  clients= []

  constructor(
    private unknownClientAliasesService: UnknownClientAliasService,
    private toastsService: ToastsService,
    private clientsService: ClientsService,
  ) {
    this.dtTranslation = toastsService.dtTranslation;
  }

  ngOnInit(): void {
    this.getAliases()
  }

  getAliases(){
    this.unknownClientAliasesService.list().subscribe(
      res=>{
        this.unknownClientAliases = res;
      }
    );
  }

  openClientAlias(id) {
    window.location.href = '/unknown-client-aliases/' + id;
  }
}

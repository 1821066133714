import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClassificationService {
  private apiurl = '';
  constructor(private http: HttpClient, private router: Router) {
    this.apiurl = environment.api;
  }

  classifyByCourier(form) {
    const url = this.apiurl + 'app/v1/parcel/classify/courier';
    return this.http.post(url, form,  {observe: 'response'});
  }
}

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParcelReportedResignationsService {
  private apiurl = '';
  constructor(private http: HttpClient, private router: Router) {
    this.apiurl = environment.api + 'app/v1/parcel/resignation';
  }

  store(model) {
    return this.http.post(this.apiurl, model);
  }

  show(id) {
    const url = this.apiurl + '/' + id;
    return this.http.get(url);
  }

  update(form) {
    return this.http.put(this.apiurl, form);
  }

  delete(id) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id
      },
    };
    return this.http.delete(this.apiurl, options);
  }
}

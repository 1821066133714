import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Route, Router} from '@angular/router';
import {AuthService} from '../../../services/auth/auth.service';

@Component({
    selector: 'app-mail-check',
    templateUrl: './mail-check.component.html',
    styleUrls: ['./mail-check.component.scss']
})
export class MailCheckComponent implements OnInit {
    validate = false;
    token = '';
    constructor(private route: ActivatedRoute, private authService: AuthService) {
    }

    ngOnInit() {
        this.token = this.route.snapshot.paramMap.get('token');
        this.authService.checkEmail(this.token).subscribe(
            (response) => {
                this.validate = true;
            }
        );
    }

}

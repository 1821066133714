import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CourierPricesService {
    private apiurl = '';

    constructor(private http: HttpClient, private router: Router) {
        this.apiurl = environment.api;
    }

    getCourierPrice(id) {
        const url = this.apiurl + `courier/1/prices/` + id;
        return this.http.get(url);
    }

    updateCourierPrice(form) {
        const url = this.apiurl + 'courier/price';
        return this.http.put(url, form);
    }

    getCourierPrices(id) {
        const url = this.apiurl + `courier/${id}/prices`;
        return this.http.get(url);
    }
}

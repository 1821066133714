import {Component, Input, OnInit} from '@angular/core';
import {CredentialsService} from '../../../../services/credentails/credentials.service';
import {ClientsService} from '../../../../services/clients/clients.service';
@Component({
  selector: 'app-client-prices',
  templateUrl: './client-prices.component.html',
  styleUrls: ['./client-prices.component.scss']
})
export class ClientPricesComponent implements OnInit {
  @Input() clientId;
  prices: [];
  constructor(
    private credentailsService: CredentialsService,
    private clientsService: ClientsService,
  ) {
  }
  ngOnInit(): void {
    this.getPrices();
  }
  getPrices(){
    this.clientsService.getPrices(this.clientId).subscribe(
      (s: any) => {
        this.prices = s;
      }
    );
  }
}

<div id="clientCredentail">
  <p class="uk-heading-divider">{{'client_additional_parameters' | translate}}</p>
  <table class="uk-table uk-width-1-1 uk-table-hover uk-table-divider">
    <thead>
      <tr>
        <td style="width: 3%">LP.</td>
        <td>Typ</td>
        <td>Wartość</td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let parameter of parameters; let key = index" (click)="open(parameter.id)">
        <td>{{key+1}}</td>
        <td>{{parameter.name}}</td>
        <td *ngIf="['USE_REAL_WEIGHT_FOR_ROMANIA_SHIPMENTS_ACCOUNTING', 'USE_REAL_WEIGHT_FOR_HUNGARY_SHIPMENTS_ACCOUNTING', 'USE_REAL_WEIGHT_FOR_BULGARIA_SHIPMENTS_ACCOUNTING', 'SENDING_SHIPMENTS_BLOCK'].includes(parameter.code)">
          {{parameter.value ? 'TAK' : 'NIE' }}
        </td>
        <td *ngIf="!['USE_REAL_WEIGHT_FOR_ROMANIA_SHIPMENTS_ACCOUNTING', 'USE_REAL_WEIGHT_FOR_HUNGARY_SHIPMENTS_ACCOUNTING', 'USE_REAL_WEIGHT_FOR_BULGARIA_SHIPMENTS_ACCOUNTING', 'SENDING_SHIPMENTS_BLOCK', 'SHIPMENT_COUNT_VOLUME'].includes(parameter.code)">
          {{parameter.value | translate}}
        </td>
        <td *ngIf="['INVOICING_VAT_RATE'].includes(parameter.code)">
          {{'vat_rate_' +parameter.value | translate}}
        </td>
        <td *ngIf="['SHIPMENT_COUNT_VOLUME'].includes(parameter.code)">
          {{'shipment_count_volume_' + parameter.value | translate}}
        </td>
      </tr>
    </tbody>
  </table>
</div>



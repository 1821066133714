import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {AuthService} from './auth/auth.service';
import {catchError} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {ToastsService} from "./toasts.service";

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

    constructor(
        public authService: AuthService,
        private toastr: ToastrService,
        private translate: TranslateService,
        private toastsService: ToastsService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            catchError( (error) => {
                if (error.status === 401 && !((error.url).indexOf('/user/login') > -1) ) {
                    this.authService.logout();
                    this.toastsService.showMessage('auto_logout', 'e');
                } else if (error.status === 419) {
                    let errors = '';
                    for (const e in error.error) {
                        if (Array.isArray(error.error[e])) {
                            for (const i of error.error[e]) {
                                errors += `<p class="uk-margin-remove">${i}</p>`;
                            }
                        } else {
                            errors += `<p class="uk-margin-remove">${error.error[e]}</p>`;
                        }
                    }
                    this.toastr.error(errors);
                }
                return throwError(error);
            })
        );
    }
}

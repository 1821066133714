import {Component, OnInit} from '@angular/core';
import {UserModel} from './models/user.model';
import {AuthService} from './services/auth/auth.service';
import {Router} from '@angular/router';
import {ParcelsService} from './services/parcels/parcels.service';
import {ToastsService} from './services/toasts.service';
import {environment} from '../environments/environment';
import {UserRole} from './enums/user-role';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router,
    private parcelsService: ParcelsService,
    private toastsService: ToastsService
  ) {

  }
  public userModel: UserModel = new UserModel();
  public bg = `url('/assets/img/bg.jpg')`;
  public apiurl = null;
  public lastChars = '';
  public lastKeyCode = null;
  public clearLastCharsInterval = null;
  public eanCheckerTimeout = null;

  allKeyCodes = '';

  protected readonly UserModel = UserModel;
  protected readonly UserRole = UserRole;

  closeMenu(): void {
    const sidebar = document.getElementById('sidebar-admin');
    if (sidebar !== null && sidebar !== undefined) {
      sidebar.classList.remove('open');
    }
  }

  ngOnInit(): void {
    this.authService.isLogged.subscribe((userModel) => {
      this.userModel = userModel;
    });
    this.whoami();
    this.keyReading();
    this.clearLastChars();
    this.apiurl = environment.api;
  }

  clearLastChars() {
    if (this.clearLastCharsInterval != null) {
      clearInterval(this.clearLastCharsInterval);
      this.clearLastCharsInterval = null;
    }
    this.clearLastCharsInterval = setInterval(
      () => {
        this.checkNumbers();
        this.lastChars = '';
        this.allKeyCodes = '';
      }, 800
    );
  }

  checkNumbers() {
    const tempChars = JSON.parse(JSON.stringify(this.lastChars));
    if (this.lastChars.length === 28) {
      if (String(this.lastChars).slice(0, 4) === '1000' || String(this.lastChars).slice(0, 4) === '0000') {
        if (String(this.lastChars).slice(0, 4) === '0000') {
          this.toastsService.showMessage('Etykieta przesyłki o numerze ' + this.lastChars + ' wymaga przeklejenia!', 'w', 10000);
        }
        const newLastChars = this.lastChars.substr(4, 11);
        this.parcelsService.getPublicParcelOutNumber(newLastChars).subscribe(
          (response: any) => {
            if (response == null) {
              return;
            }
            if (String(tempChars).slice(0, 4) === '0000') {
              this.openLabelToPrint(response.internal_number);
            }
            if (this.userModel != null) {
              this.router.navigateByUrl('/parcel/' + response.internal_number);
            } else {
              this.router.navigateByUrl('/tracking/' + response.internal_number);
            }
          }
        );
      }
    } else if (this.lastChars.length === 9 || this.lastChars.length === 10 || (this.lastChars.toUpperCase()).includes('RE_')) {
      this.parcelsService.getPublicParcelOutNumber(tempChars.replace('RE_', '')).subscribe(
        (response: any) => {
          if (response == null) {
            return;
          }
          this.toastsService.showMessage('Etykieta przesyłki o numerze ' + tempChars.replace('RE_', '')
            + ' wymaga przeklejenia!', 'w', 10000);
          this.openLabelToPrint(response.internal_number);
          if (this.userModel != null) {
            this.router.navigateByUrl('/parcel/' + response.internal_number);
          } else {
            this.router.navigateByUrl('/tracking/' + response.internal_number);
          }
        });
    } else if (this.lastChars.match(/^[0-9][A-Z]{3,5}[0-9]{4,}/) !== null) {
      this.parcelsService.getPublicParcelOutNumber(this.lastChars).subscribe(
        (response: any) => {
          if (response == null) {
            return;
          }

          if (this.userModel != null) {
            this.router.navigateByUrl('/parcel/' + response.internal_number);
          } else {
            this.router.navigateByUrl('/tracking/' + response.internal_number);
          }
        }
      );
    } else {
      if (this.eanCheckerTimeout != null) {
        clearTimeout(this.eanCheckerTimeout);
      }
      this.eanCheckerTimeout = setTimeout(
        () => {
          this.eanChecker(tempChars);
        }, 150
      );
    }
  }

  eanChecker(tempChars) {
    if (tempChars.length === 8) {
      this.toastsService.showMessage('Zeskanowano nieprawidłowy kod - wykryto EAN8', 'w', 5000);
    }
    if (tempChars.length === 13) {
      this.toastsService.showMessage('Zeskanowano nieprawidłowy kod - wykryto EAN13', 'w', 5000);
    }
    if (tempChars.length === 14) {
      this.toastsService.showMessage('Zeskanowano nieprawidłowy kod - wykryto EAN14', 'w', 5000);
    }
    if (tempChars.length > 28 && tempChars.length !== 31) {
      this.toastsService.showMessage('Zbyt szybko skanujesz przesyłki', 'w', 5000);
    }
  }
  keyReading() {
    document.addEventListener('keyup', e => {
      this.clearLastChars();
      if (
        window.location.pathname === '/tracking-manager' ||
        window.location.pathname === '/parcel-from-number' ||
        window.location.pathname === '/parcel-classification' ||
        window.location.pathname === '/parcel-search' ||
        window.location.pathname === '/external_inbound/scan' ||
        (window.location.pathname).includes('client')
      ) {
        return;
      }
      if (this.userModel == null || this.userModel.role >= 2) {
        return;
      }

      this.allKeyCodes += String.fromCharCode(e.keyCode);
      if ((e.keyCode >= 48 && e.keyCode <= 57 && this.lastKeyCode !== 16)
        || e.keyCode === 82 || e.keyCode === 69 || e.keyCode === 189 || (e.keyCode>40 && e.keyCode<91)) {
        if (e.keyCode === 189) {
          this.lastChars += '_';
        } else {
          this.lastChars += String.fromCharCode(e.keyCode) + '';
        }
      }

      this.lastKeyCode = e.keyCode;
    });
  }

  whoami(): void {
    if (this.userModel != null) {
      this.authService.whoami().subscribe((who: any) => {
        const newUserModel = this.userModel;
        newUserModel.role = who.role;
        this.authService.setUserModel(newUserModel);
      }, (e) => {
        // console.error(e);
      });
    } else {
      setTimeout(() => {
        this.whoami();
      }, 1000);
    }
  }

  openLabelToPrint(lastChars) {
    this.parcelsService.getPublicFileToken().subscribe(
      (response: any) => {
        window.open(this.apiurl + 'public/label/' + lastChars + '?_public_token=' + response.public_token);
      }
    );
  }
}

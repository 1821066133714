import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatusGroupsService {
  private apiurl = '';

  constructor(private http: HttpClient, private router: Router) {
    this.apiurl = environment.api;
  }

  list() {
    const url = this.apiurl + 'app/v1/status_type_group/list';
    return this.http.get(url);
  }

  findByIdentifier(identifier) {
    const url = this.apiurl + `app/v1/status_type_group/one/${identifier}`;
    return this.http.get(url);
  }
}

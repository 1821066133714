import { Component, OnInit } from '@angular/core';
import {CalendarDaysService} from "../../../services/calendar_days/calendar-days.service";
import {HttpClient} from "@angular/common/http";
import {ToastsService} from "../../../services/toasts.service";

declare var UIkit: any;

@Component({
  selector: 'app-days',
  templateUrl: './days.component.html',
  styleUrls: ['./days.component.scss']
})
export class DaysComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  selectedYear=null;
  years=[];
  days: any=[];
  apiurl = '';
  dtTranslation = null;
  today=null;
  dayModel={
    date: null,
    name: ''
  };
  constructor(private calendarDaysService: CalendarDaysService, private toastsService: ToastsService) {
    this.dtTranslation = toastsService.dtTranslation;
  }

  ngOnInit(): void {
    this.getYears();
  }

  getYears(){
    this.calendarDaysService.getYears().subscribe(
      (response: any)=>{
        this.years=response.list;
        this.selectedYear=response.current;
        this.today=response.today;
        this.dayModel.date=response.today;
        this.getDays();
      }
    );
  }

  getDays(){
    this.calendarDaysService.getYearDays(this.selectedYear).subscribe(
      (response: any)=>{
        this.days=response;
      }
    )
  }

  deleteDay(id){
    this.calendarDaysService.removeClient(id).subscribe(
      (response: any)=>{
        this.toastsService.showMessage('Dzień wolny został usunięty z kalendarza!', 's');
        this.getDays();
      }
    )
  }

  openModal(){
    UIkit.modal('#addDay').show();
  }

  onSubmit(){
    this.create();
  }

  create(){
    this.calendarDaysService.addDay(this.dayModel).subscribe(
      (response: any)=>{
        this.dayModel={
          date: this.today,
          name: ''
        };
        this.selectedYear=response.year;
        this.getDays();
        UIkit.modal('#addDay').hide();
        this.toastsService.showMessage('Dzień wolny został dodany do listy!', 's');
      });
  }
}

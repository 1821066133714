import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  ParcelReportedResignationsService
} from "../../../../../services/parcel_reported_resignations/parcel-reported-resignations.service";
import {ClientsService} from "../../../../../services/clients/clients.service";
import {ToastsService} from "../../../../../services/toasts.service";
import {
  MyParcelReportedResignationsService
} from "../../../../../services/client-role/my_parcel_reported_resignations/my-parcel-reported-resignations.service";

@Component({
  selector: 'app-my-parcel-reported-resignation',
  templateUrl: './my-parcel-reported-resignation.component.html',
  styleUrls: ['./my-parcel-reported-resignation.component.scss']
})
export class MyParcelReportedResignationComponent implements OnInit {
  id = null;
  clients = [];

  model = {
    id: null,
    user_id: null,
    user_name: null,
    client_id: null,
    client_name: null,
    waybill: null,
    scanned_at: null,
    resend_at: null,
    note: null,
    scanned_by_user_id: null,
    scanned_by_user_name: null
  };

  constructor(
    private router: Router,
    private toastsService: ToastsService,
    private activatedRoute: ActivatedRoute,
    private myParcelReportedResignationsService: MyParcelReportedResignationsService
  ) {
      this.id = this.activatedRoute.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    if (null !== this.id) {
      this.show();
    }
  }

  save() {
    if (null === this.id) {
      this.myParcelReportedResignationsService.store(this.model).subscribe(
        (response: any) => {
          this.toastsService.showMessage('Utworzono pomyślnie!', 's');
          this.id = response.id;
        }, e => {
          // tslint:disable-next-line:forin
          for (const error in e.error){
            this.toastsService.showMessage(e.error[error], 'e', 5000);
          }
        }
      );
    }
  }

  async delete() {
    const promised = await this.toastsService.showConfirmModal();
    if (!promised) {
      return;
    }

    this.myParcelReportedResignationsService.delete(this.id).subscribe(
      (response: any) => {
        this.toastsService.showMessage('Usunięto pomyslnie!', 's');
        this.router.navigateByUrl('/my-parcel-reported-resignations');
      }, e => {
        // tslint:disable-next-line:forin
        for (const error in e.error){
          this.toastsService.showMessage(e.error[error], 'e', 5000);
        }
      }
    );
  }

  show() {
    this.myParcelReportedResignationsService.show(this.id).subscribe(
      (response: any) => {
        this.model = response;
      }
    );
  }
}

<div class="uk-grid">
    <div class="uk-width-1-1 navbar">
        <p class="nav-text">
            Przesyłki przyjęte od kurierów
          <a class="uk-button uk-button-primary padding-horizontal-small button-opt"
             [attr.uk-tooltip]="'Resetuj filtry'"
             (click)="refreshFilters()"
          >
            <span uk-icon="refresh"></span>
          </a>
        </p>
    </div>

  <div class="uk-width-1-1 inner-shadow">
    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
            <div class="mobile-overflow">
                <div class="uk-grid uk-margin-bottom">
                  <div class="uk-width-1-6@m">
                    <label>Kurier:</label>
                    <select required class="uk-input" [(ngModel)]="criteria.inbound_courier_id">
                      <option value=""></option>
                      <option *ngFor="let courier of couriers" value="{{courier.id}}">{{courier.name}}</option>
                    </select>
                  </div>
                  <div class="uk-width-1-6@m">
                    <label>&nbsp;</label>
                    <label>Dzień przyjęcia</label>
                    <input class="uk-input" type="date" [(ngModel)]="criteria.date">
                  </div>
                  <div class="uk-width-1-6@m">
                    <label>&nbsp;</label>
                    <button class="uk-button uk-button-primary uk-width-1-1" (click)="runFilters()">{{ 'filter' | translate }}</button>
                  </div>
                </div>
                <table class="uk-table uk-width-1-1 uk-table-hover uk-table-divider" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="client-list">
                    <thead>
                    <tr>
                        <th>{{'Numer przesyłki' | translate}}</th>
                        <th>{{'Data i czas przyjęcia' | translate}}</th>
                        <th>{{'Komentarz' | translate}}</th>
                    </tr>
                    </thead>
                    <tbody *ngIf="externalInbounds?.length > 0">
                    <tr *ngFor="let externalInbound of externalInbounds" [routerLink]=" '/external_inbound/' + externalInbound.id ">
                        <td>{{ externalInbound.waybill }}</td>
                        <td>{{ externalInbound.scanned_at }}</td>
                      <td>{{ externalInbound.comment }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>

    </div>

</div>


<div class="uk-grid">
  <div class="uk-width-1-1 navbar">
    <p class="nav-text">
      <span *ngIf="id===null">Tworzenie danych dostępowych</span>
      <span *ngIf="id!==null">Edycja danych dostępowych</span>
      <button class="uk-button uk-button-primary padding-horizontal-small button-opt " [attr.uk-tooltip]="'Wróć'" [routerLink]="'/client/'+idClient+'/card/api'"><span uk-icon="sign-out"></span></button>
      <button *ngIf="!protected && id !== null" class="uk-button uk-button-danger padding-horizontal-small uk-margin-remove-right button-opt " (click)="delete()" [attr.uk-tooltip]="'Usuń dane dostępowe'"><span uk-icon="ban"></span></button>
      <button *ngIf="protected" class="uk-button uk-button-danger padding-horizontal-small uk-margin-remove-right button-opt " (click)="unpin()" [attr.uk-tooltip]="'Odepnij dane dostępowe od klienta'"><span uk-icon="ban"></span></button>
    </p>
  </div>

  <div class="uk-width-1-1 inner-shadow">

    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
      <div class="mobile-overflow">
          <div class="uk-grid">
            <div class="uk-width-1-4" *ngIf="id === null">
              <label>Kurier</label>
              <select class="uk-select" [(ngModel)]="model.id_courier" [disabled]="protected">
                <option *ngFor="let courier of couriers" value="{{courier.id}}">{{courier.name}}</option>
              </select>
            </div>
            <div class="uk-width-3-4" *ngIf="id === null">
            </div>
            <div class="uk-width-1-4">
              <label>Rodzaj</label>
              <select class="uk-select" [(ngModel)]="model.id_type" [disabled]="protected">
                <option *ngFor="let type of types" value="{{type.id}}" [hidden]="type.assign === 'dpd_ro_main'">{{type.name}}</option>
              </select>
            </div>
            <div class="uk-width-1-4">
              <label>Login</label>
              <input type="text" class="uk-input" [disabled]="protected" [(ngModel)]="model.username" name="username" (change)="setNeedCheck()">
            </div>
            <div class="uk-width-1-4">
              <label>Hasło</label>
              <input type="text" class="uk-input" [disabled]="protected" [(ngModel)]="model.password" name="password" (change)="setNeedCheck()">
            </div>
            <div class="uk-width-1-4">
              <label>Numer umowy</label>
              <input type="text" class="uk-input" [disabled]="protected" [(ngModel)]="model.contract_number" name="contract_number">
            </div>
            <div class="uk-width-1-4">
              <label>Numer usługi</label>
              <input type="text" class="uk-input" [disabled]="protected" [(ngModel)]="model.service" name="service" (change)="setNeedCheck()">
            </div>
            <div class="uk-width-3-4">
              <label>Komentarz</label>
              <textarea [(ngModel)]="model.comment" name="comment" class="uk-input"></textarea>
            </div>
          </div>

          <div class="uk-margin">
            <button *ngIf="!needCheck && checked" class="uk-button uk-button-primary" (click)="submit()">{{'save' | translate}}</button>
            <button *ngIf="needCheck || !checked" class="uk-button uk-button-primary" (click)="check()">{{'check' | translate}}</button>
          </div>
      </div>
    </div>
  </div>

  <div  id="checked-permissions-modal" class="checked-permissions-modal" uk-modal>
    <div class="uk-modal-dialog">
      <button class="uk-modal-close-default" type="button" uk-close></button>

      <div class="uk-modal-header">
        <h2 class="uk-modal-title">Dane odczytane poprzez API:</h2>
      </div>

      <div class=" uk-modal-body">
        <p>
          Numer umowy: {{checkingResponse.client_id}}
        </p>
        <p>
          Email: {{checkingResponse.email}}
        </p>
        <p>
          Numer telefonu: {{checkingResponse.phone_number}}
        </p>
      </div>
    </div>
  </div>
</div>


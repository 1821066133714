
<script src="stats.component.ts"></script>
<div class="uk-grid">
  <div class="uk-width-1-1 navbar">
    <p class="nav-text">
      {{'stats' | translate}}

      <button class="uk-button uk-button-primary padding-horizontal-small button-opt"
        [attr.uk-tooltip]="'Pobierz plik Excel'" uk-icon="download" (click)="downloadXlsx()"></button>
    </p>
  </div>

  <div class="uk-width-1-1 inner-shadow">
    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
      <div class="mobile-overflow">
        <div class="uk-grid uk-margin-bottom">
          <div class="uk-width-1-3@m">
<!--            <button class="uk-button uk-button-primary uk-width-1-1">-->
<!--                <span>Statystyki przesyłek klientów</span>-->
<!--              </button>-->
            <select [(ngModel)]="selectedStatus" (change)="getStats()" class="uk-button uk-button-primary uk-width-1-1 uk-input">
              <option value="received_by_re">Statystyki przesyłek klientów</option>
              <option value="back_in_poland">Statystyki zwrotów klientów</option>
            </select>
            </div>
          </div>

          <div class=" uk-grid uk-margin-bottom">
            <div class="uk-width-1-1@m">
              <div class="uk-grid">
              </div>
            </div>
          </div>

        <table class="uk-table uk-width-1-1 uk-table-hover uk-table-divider" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
          <thead>
          <tr>
            <th>{{'name' | translate}}</th>
            <th>{{'today' | translate}}</th>
            <th>{{'yesterday' | translate}}</th>
            <th>{{'day_before_yesterday' | translate}}</th>
            <th>{{'weekly' | translate}}</th>
            <th>{{'last_30_days' | translate}}</th>
            <th>{{'ever'| translate }}</th>
          </tr>
          </thead>
          <tbody *ngIf="clients?.length > 0">
            <tr *ngFor="let client of clients">
              <td>
                {{client.name}}
              </td>
              <td>
                {{stats[client.id]?.daily}}
              </td>
              <td>
                {{stats[client.id]?.yesterday}}
              </td>
              <td>
                {{stats[client.id]?.day_before_yesterday}}
              </td>
              <td>
                {{stats[client.id]?.weekly}}
              </td>
              <td>
                {{stats[client.id]?.monthly}}
              </td>
              <td>
                {{stats[client.id]?.ever}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { ToastsService } from '../../../services/toasts.service';
import { ClientExternalApiCredentialsService } from 'src/app/services/client-external-api-credentials/client-external-api-credentials.service';
import { HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientsService } from '../../../services/clients/clients.service';
import { ExternalApiCredentialsModel } from 'src/app/models/external-api-credentials.model';
import { ClientModel } from '../../../models/client.model';

@Component({
  selector: 'app-client-new-external-api-credentials',
  templateUrl: './client-new-external-api-credentials.component.html',
  styleUrls: ['./client-new-external-api-credentials.component.scss']
})
export class ClientNewExternalApiCredentialsComponent implements OnInit {

  @Input() clientId;
  id = null;
  credentialsForm: FormGroup;
  credentials: [];
  generatedPassword: string = '';
  client: any = new ClientModel();
  generatedSlug: string = '';
  credentialsType: string = '';

  model: ExternalApiCredentialsModel = {
    client_id: null,
    id: null,
    username: null,
    password: null,
    type: null,
    token: null,
    ip: null,
  };

  constructor(
    private clientExternalApiCredentialsService: ClientExternalApiCredentialsService,
    private clientsService: ClientsService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private toastsService: ToastsService,
  ) {
  }

  ngOnInit() {
    this.activeRoute.params.subscribe(params => {
      this.clientId = params['clientId'];
    });

    this.getClient();
  }

  onCreateCredentials(model: any) {
    this.model.client_id = this.clientId;

    this.clientExternalApiCredentialsService.store(this.clientId, this.model).subscribe(
      (response: []) => {
        this.credentials = response;
        this.router.navigateByUrl('/client/' + this.clientId + '/card/external_api_credentials').then(r => {
          this.toastsService.showMessage('Pomyślnie dodano nowe dane uwieżytelniające', 's');
        });
      }
    );
  }

  checkType() {
    if (this.model.type === 'baselinker' || this.model.type === 'api_v2') {
      this.credentialsType = this.model.type;
    } else {
      this.credentialsType = null;
    }
  }

  generatePassword(length: number): string {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%&*';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      result += charset[randomIndex];
    }
    return result;
  }

  onGeneratePassword() {
    this.model.password = this.generatePassword(16);
  }

  convertToSlug(input: string): string {
    let slug = input
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '');

      slug = slug.substring(0, 16);

      while (slug.length < 8) {
        slug += Math.floor(Math.random() * 10);
      }

      return slug;
  }

  onConvertToSlug(client: string) {
    this.generatedSlug = this.convertToSlug(client);
    this.model.username = this.generatedSlug;
  }

  getClient() {
    this.clientsService.getClient(this.clientId).subscribe(
      (s: ClientModel) => {
        this.client = s;
      }
    );
  }
}

<div class="uk-grid">
    <div class="uk-width-1-1 navbar">
        <p class="nav-text">
            Przesyłki przyjęte od kurierów
          <button class="uk-button uk-button-primary padding-horizontal-small button-opt"
                  [attr.uk-tooltip]="'Wróć'"
                  [routerLink]="'/external_inbound'"
          >
            <span uk-icon="sign-out"></span>
          </button>
        </p>
    </div>
  <div class="uk-width-1-1 inner-shadow">
    <div class="uk-card uk-card-default uk-card-body uk-margin-medium-right uk-margin-medium-top">
      <div class="mobile-overflow">
        <form #statusForm="ngForm">
          <div class="uk-grid">
            <div class=" uk-width-1-3@m" >
              <label>{{'Numer przesyłki' | translate}}</label>
              <input class="uk-input" type="text" [ngModel]="externalInbound.waybill" name="waybill" #name="ngModel" ngModel disabled>
            </div>

            <div class=" uk-width-1-3@m" >
              <label>{{'Data przyjęcia' | translate}}</label>
              <input class="uk-input" type="text" [ngModel]="externalInbound.scanned_at" name="scanned_at" #name="ngModel" ngModel disabled>
            </div>

            <div class=" uk-width-1-3@m" >
              <label>{{'Pzyjęto przez' | translate}}</label>
              <input class="uk-input" type="text" [ngModel]="externalInbound.scanned_by_user.email" name="scanned_by_user.email" #name="ngModel" ngModel disabled>
            </div>

            <div class=" uk-width-1-3@m" >
              <label>{{'Zesnakowany kod' | translate}}</label>
              <input class="uk-input" type="text" [ngModel]="externalInbound.barcode" name="barcode" #name="ngModel" ngModel disabled>
            </div>
            <div class=" uk-width-1-3@m" >
              <label>{{'Kurier' | translate}}</label>
              <select [ngModel]="externalInbound.inbound_courier_id" disabled name="inbound_courier_id" class="uk-select">
                <option *ngFor="let courier of couriers" value="{{courier.id}}">{{courier.name}}</option>
              </select>
            </div>

            <div class=" uk-width-1-3@m" >
              <label>{{'Komentarz' | translate}}</label>
              <input class="uk-input" type="text" [(ngModel)]="externalInbound.comment" name="comment">
            </div>
            <div class="uk-margin">
              <button class="uk-button uk-button-primary" [disabled]="statusForm.form.invalid" (click)="update()">{{'save' | translate}}</button>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>

</div>

